import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { 
  Box, 
  Heading, 
  Text, 
  SimpleGrid, 
  Stat, 
  StatLabel, 
  StatNumber, 
  Flex, 
  Spinner, 
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  useColorModeValue,
  Icon,
  Badge,
  HStack,
  VStack,
  Progress,
  Avatar,
  AvatarGroup
} from '@chakra-ui/react';
import { SectionLabel } from './DashboardWrapper';
import AnimatedBox from '../AnimatedBox';
import { 
  BarChart, 
  Bar, 
  PieChart, 
  Pie, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  ResponsiveContainer, 
  Cell, 
  Legend, 
  ReferenceLine, 
  AreaChart,
  Area
} from 'recharts';
import { 
  FaChartLine, 
  FaStar, 
  FaUsers, 
  FaMapMarkerAlt, 
  FaTrophy,
  FaAward,
  FaMedal
} from 'react-icons/fa';
import RankingListComponent from '../RankingListComponent';
import DataTable from 'react-data-table-component';
import BusinessReviewModal from '../BusinessReviewModal';
import { getYearData } from '../../services/yearService';
import moment from 'moment';

const TotalReviewsDashboard = ({ reviewsData, dateRange }) => {
  // Set up color mode values
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const statBg = useColorModeValue('gray.50', 'gray.700');

  const [yearData, setYearData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [combinedData, setCombinedData] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Colors for charts - using consistent color palette with more distinct colors
  const colors = useMemo(() => [
    '#2F80ED', // bright blue
    '#27AE60', // emerald green
    '#EB5757', // bright red
    '#9B51E0', // purple
    '#F2994A', // orange
    '#219EBC', // teal
    '#F264A6', // pink
    '#BB6BD9', // violet
    '#2D9CDB', // light blue
    '#219653', // dark green
    '#F2C94C', // yellow
    '#6FCF97', // light green
    '#56CCF2', // sky blue
    '#F2994A', // peach
    '#9B51E0', // lavender
    '#EB5757', // coral
    '#2F80ED', // royal blue
    '#27AE60', // forest green
    '#BB6BD9', // orchid
    '#F264A6'  // rose
  ], []);
  const [businessColors, setBusinessColors] = useState({});

  // Filter reviews based on date range
  const filteredData = useMemo(() => {
    if (!dateRange?.startDate || !dateRange?.endDate) return reviewsData;
    
    return reviewsData.filter(business => {
      const reviewDate = moment(business.timestamp);
      return reviewDate.isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]');
    });
  }, [reviewsData, dateRange]);

  // Sort businesses by review count
  const sortedByTotal = useMemo(() => {
    if (!filteredData.length) return [];
    return [...filteredData].sort((a, b) => b.rating_count - a.rating_count);
  }, [filteredData]);

  // Get top 5 businesses by review count
  const top5Total = useMemo(() => {
    if (!sortedByTotal.length) return [];
    return sortedByTotal.slice(0, 5);
  }, [sortedByTotal]);

  // Prepare data for monthly trend chart
  const monthlyTrendData = useMemo(() => {
    if (!dateRange?.startDate || !dateRange?.endDate || !combinedData.length) return [];
    
    const monthlyData = {};
    
    // Initialize months in range
    const start = moment(dateRange.startDate);
    const end = moment(dateRange.endDate);
    let current = start.clone();

    while (current.isSameOrBefore(end, 'month')) {
      monthlyData[current.format('YYYY-MM')] = {
        month: current.format('YYYY-MM'),
        'Market Average': 0,
        ...top5Total.reduce((acc, business) => ({
          ...acc,
          [business.name.split(' ').slice(0, 2).join(' ')]: 0
        }), {})
      };
      current.add(1, 'month');
    }

    // Aggregate review counts by month using combinedData
    combinedData.forEach(business => {
      if (business.historicalData?.monthlyReviewCounts) {
        Object.entries(business.historicalData.monthlyReviewCounts)
          .filter(([month]) => {
            return moment(month).isBetween(dateRange.startDate, dateRange.endDate, 'month', '[]');
          })
          .forEach(([month, count]) => {
            if (!monthlyData[month]) return;
            
            monthlyData[month]['Market Average'] += count;
            
            // Add individual business data for top 5
            const businessName = business.name.split(' ').slice(0, 2).join(' ');
            if (top5Total.some(b => b.name.split(' ').slice(0, 2).join(' ') === businessName)) {
              monthlyData[month][businessName] = count;
            }
          });
      }
    });

    // Calculate market average
    Object.values(monthlyData).forEach(data => {
      data['Market Average'] = Math.round(data['Market Average'] / combinedData.length);
    });

    // Sort by date
    return Object.values(monthlyData)
      .sort((a, b) => moment(a.month).diff(moment(b.month)));
  }, [combinedData, dateRange, top5Total]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const yearDataResponse = await getYearData();
        setYearData(yearDataResponse);
        
        // Combine data using maps_uri as unique identifier
        const combined = reviewsData.map(currentData => {
          const historicalData = yearDataResponse.find(yd => 
            yd.maps_uri === currentData.maps_uri
          );
          
          return {
            ...currentData,
            historicalData: historicalData?.data || {
              monthlyReviewCounts: {},
              monthlyRatings: {},
              sentimentBreakdown: {
                positive: 0,
                neutral: 0,
                negative: 0
              }
            }
          };
        });
        
        setCombinedData(combined);
      } catch (error) {
        console.error("Error fetching year data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (reviewsData?.length > 0) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [reviewsData, dateRange]);

  useEffect(() => {
    // Assign colors to businesses
    if (reviewsData) {
      const newBusinessColors = {};
      reviewsData.forEach((business, index) => {
        newBusinessColors[business.name] = colors[index % colors.length];
      });
      setBusinessColors(newBusinessColors);
    }
  }, [reviewsData, colors]);

  const getColor = useCallback((businessName) => {
    return businessColors[businessName] || colors[0];
  }, [businessColors, colors]);

  if (isLoading || !reviewsData || reviewsData.length === 0) {
    return (
      <Flex justify="center" align="center" minH="300px" direction="column">
        <AnimatedBox
          animation="fadeIn"
          duration={0.8}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Spinner 
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
          <AnimatedBox 
            animation="pulse" 
            delay={0.3}
            duration={1.5}
            repeat={true}
          >
            <Text mt={4} fontSize="lg" color="gray.500">Loading review data...</Text>
          </AnimatedBox>
        </AnimatedBox>
      </Flex>
    );
  }

  // Calculate market-wide metrics
  const totalMarketReviews = sortedByTotal.reduce((sum, b) => sum + b.rating_count, 0);
  const avgReviewsPerBusiness = totalMarketReviews / sortedByTotal.length;
  const medianReviews = sortedByTotal[Math.floor(sortedByTotal.length / 2)].rating_count;

  // Prepare data for pie chart
  const pieChartData = sortedByTotal.slice(0, 20).map(business => ({
    name: business.name.split(' ').slice(0, 2).join(' '),
    value: business.rating_count,
    fill: getColor(business.name)
  }));

  // Add this before the return statement, after the monthlyTrendData useMemo
  const columns = [
    {
      id: 1,
      name: 'Business Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      id: 2,
      name: 'Total Reviews',
      selector: row => row.rating_count,
      sortable: true,
      format: row => row.rating_count.toLocaleString(),
    },
    {
      id: 3,
      name: 'Average Rating',
      selector: row => row.rating_avg,
      sortable: true,
      format: row => row.rating_avg?.toFixed(1) || 'N/A',
    },
    {
      id: 4,
      name: 'Monthly Reviews',
      selector: row => {
        const monthlyReviews = row.data?.monthlyReviewCounts || {};
        const latestMonth = Object.keys(monthlyReviews).sort().pop();
        return monthlyReviews[latestMonth] || 0;
      },
      sortable: true,
      format: row => {
        const monthlyReviews = row.data?.monthlyReviewCounts || {};
        const latestMonth = Object.keys(monthlyReviews).sort().pop();
        return (monthlyReviews[latestMonth] || 0).toLocaleString();
      },
    }
  ];

  const handleBusinessClick = (business) => {
    const businessData = combinedData.find(b => b.maps_uri === business.maps_uri);
    if (businessData) {
      setSelectedBusiness(businessData);
      setIsModalOpen(true);
    }
  };

  // Function to handle row clicks in data table
  const handleRowClick = (row) => {
    handleBusinessClick(row);
  };
  
  // Business Review Card component
  const BusinessReviewCard = ({ business, index = 0 }) => {
    // Define hover background color at component level
    const hoverBg = useColorModeValue('gray.50', 'gray.700');
    
    // Get color based on rating - higher is better
    const ratingColor = business.rating_avg > 4.5 
      ? "green.500" 
      : business.rating_avg > 4.0 
        ? "blue.500" 
        : business.rating_avg > 3.5
          ? "orange.500"
          : "gray.500";
    
    // Process monthly data for mini chart if available
    const monthlyData = useMemo(() => {
      if (!business.historicalData?.monthlyReviewCounts) return [];
      
      return Object.entries(business.historicalData.monthlyReviewCounts)
        .map(([month, count]) => ({
          month: month,
          count: count
        }))
        .sort((a, b) => a.month.localeCompare(b.month))
        .slice(-6); // Get last 6 months
    }, [business.historicalData]);

    return (
      <AnimatedBox
        animation="slideUp"
        delay={0.05 * (index % 8)} // Stagger but reset after 8 cards to avoid long delays
        duration={0.4}
        bg={cardBg}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="lg"
        overflow="hidden"
        p={4}
        cursor="pointer"
        onClick={() => handleBusinessClick(business)}
        transition="all 0.2s"
        _hover={{ 
          transform: 'translateY(-4px)',
          shadow: 'lg',
          borderColor: 'blue.400',
          bg: hoverBg
        }}
        height="100%"
      >
        <VStack align="stretch" spacing={4} height="100%">
          <Box flex="1">
            <Heading
              size="md"
              mb={2}
              lineHeight="1.4"
              minHeight="2.8em"
              overflow="hidden"
              css={{
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
              title={business.name}
            >
              {business.name}
            </Heading>
            <HStack spacing={2} mb={2} justify="space-between">
              <Badge colorScheme="blue">
                {business.primary_type?.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) || 'Business'}
              </Badge>
              <HStack spacing={1}>
                <Icon as={FaStar} color="yellow.400" boxSize={4} />
                <Text fontWeight="bold">{business.rating_avg?.toFixed(1) || '0.0'}</Text>
                <Text color="gray.500">({parseInt(business.rating_count || 0).toLocaleString()})</Text>
              </HStack>
            </HStack>
          </Box>

          {/* Review Metrics */}
          <Box>
            <Text fontSize="sm" fontWeight="medium" mb={1}>Review Metrics</Text>
            
            {/* Mini Area Chart */}
            <Box h="80px" mb={2}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={monthlyData}>
                  <defs>
                    <linearGradient id={`colorReviews-${business.name.replace(/\s+/g, '')}`} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={ratingColor} stopOpacity={0.8}/>
                      <stop offset="95%" stopColor={ratingColor} stopOpacity={0.1}/>
                    </linearGradient>
                  </defs>
                  <Area 
                    type="monotone" 
                    dataKey="count" 
                    stroke={ratingColor} 
                    fillOpacity={1}
                    fill={`url(#colorReviews-${business.name.replace(/\s+/g, '')})`} 
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
            
            <SimpleGrid columns={2} spacing={2} mb={3}>
              <Stat bg={statBg} p={2} borderRadius="md">
                <StatLabel fontSize="xs">Total Reviews</StatLabel>
                <StatNumber fontSize="lg" color={ratingColor}>
                  {business.rating_count.toLocaleString()}
                </StatNumber>
              </Stat>
              <Stat bg={statBg} p={2} borderRadius="md">
                <StatLabel fontSize="xs">Rating</StatLabel>
                <StatNumber fontSize="lg" color="blue.500">
                  {business.rating_avg?.toFixed(1) || '0.0'}
                </StatNumber>
              </Stat>
            </SimpleGrid>
          </Box>

          {/* Basic Info */}
          <VStack align="start" spacing={1} mt="auto" fontSize="sm">
            <HStack spacing={2}>
              <Icon as={FaMapMarkerAlt} color="gray.500" boxSize={4} />
              <Text noOfLines={1}>{business.address || 'No address available'}</Text>
            </HStack>
            <HStack spacing={2}>
              <Icon as={FaUsers} color="gray.500" boxSize={4} />
              <Text>{business.rating_count} total reviews</Text>
            </HStack>
          </VStack>
        </VStack>
      </AnimatedBox>
    );
  };
  
  // Stat Card component
  const StatCard = ({ icon, title, value, description, colorScheme = "blue", index = 0 }) => (
    <AnimatedBox
      animation="slideUp"
      delay={0.1 * index} // Stagger the animations
      duration={0.4}
      bg={cardBg}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      p={5}
      shadow="md"
      transition="all 0.3s"
      _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
    >
      <VStack spacing={3} align="flex-start">
        <AnimatedBox 
          animation="scale" 
          delay={0.2 + (0.1 * index)}
          duration={0.3}
        >
          <Icon 
            as={icon} 
            boxSize={6} 
            color={`${colorScheme}.500`} 
          />
        </AnimatedBox>
        <Text color="gray.500" fontSize="md">{title}</Text>
        <AnimatedBox
          animation="fadeIn"
          delay={0.3 + (0.1 * index)}
          duration={0.5}
        >
          <Heading size="xl" color={`${colorScheme}.600`}>
            {value}
          </Heading>
        </AnimatedBox>
        <Text color="gray.600" fontSize="sm">
          {description}
        </Text>
      </VStack>
    </AnimatedBox>
  );

  return (
    <Box>

      {/* Market Metrics */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} mb={8}>
        <StatCard
          icon={FaUsers}
          title="Total Market Reviews"
          value={totalMarketReviews.toLocaleString()}
          description={`Across ${sortedByTotal.length} businesses`}
          colorScheme="purple"
          index={0}
        />
        <StatCard
          icon={FaChartLine}
          title="Average Reviews"
          value={avgReviewsPerBusiness.toFixed(1)}
          description="Reviews per business"
          colorScheme="blue"
          index={1}
        />
        <StatCard
          icon={FaStar}
          title="Median Reviews"
          value={medianReviews.toLocaleString()}
          description="Market middle point"
          colorScheme="teal"
          index={2}
        />
      </SimpleGrid>
      
      {/* Section Label */}
      <SectionLabel description="Monitor review volume and distribution across your competitors to benchmark your market position.">Detailed Performance Analytics</SectionLabel>
      
      {/* Top 3 Podium - Only show on desktop and tablet */}
      <Box 
        display={{ base: "none", md: "block" }}
        bg={cardBg}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="lg"
        p={6}
        mb={8}
        shadow="md"
      >
        <Heading size="md" mb={6} color="blue.600">Review Champions</Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
          {/* Silver - Second Place */}
          <Box 
            bg={statBg} p={5} borderRadius="lg" boxShadow="sm" textAlign="center" 
            position="relative" top="20px"
            cursor="pointer"
            onClick={() => top5Total[1] && handleBusinessClick(top5Total[1])}
            transition="all 0.2s"
            _hover={{ transform: 'translateY(-5px)', shadow: 'md' }}
          >
            <Icon as={FaMedal} color="gray.400" boxSize={8} mb={2} />
            <AvatarGroup size="md" max={1} mb={3} justifyContent="center">
              <Avatar name={top5Total[1]?.name} src="" bg="gray.300" />
            </AvatarGroup>
            <Heading size="md" mb={2} noOfLines={1}>{top5Total[1]?.name}</Heading>
            <Text fontWeight="bold" fontSize="2xl" color="gray.600">
              {top5Total[1]?.rating_count.toLocaleString()}
            </Text>
            <Text fontSize="sm" color="gray.500">reviews</Text>
            <Progress 
              value={(top5Total[1]?.rating_count / (top5Total[0]?.rating_count || 1)) * 100} 
              colorScheme="gray" 
              size="sm" 
              borderRadius="full" 
              mt={3}
            />
          </Box>

          {/* Gold - First Place */}
          <Box 
            bg={statBg} p={6} borderRadius="lg" boxShadow="lg" textAlign="center"
            transform="scale(1.05)" zIndex={1}
            cursor="pointer"
            onClick={() => top5Total[0] && handleBusinessClick(top5Total[0])}
            transition="all 0.2s"
            _hover={{ transform: 'scale(1.08)', shadow: 'lg' }}
          >
            <Icon as={FaTrophy} color="yellow.400" boxSize={10} mb={2} />
            <AvatarGroup size="lg" max={1} mb={3} justifyContent="center">
              <Avatar name={top5Total[0]?.name} src="" bg="yellow.100" />
            </AvatarGroup>
            <Heading size="md" mb={2} noOfLines={1}>{top5Total[0]?.name}</Heading>
            <Text fontWeight="bold" fontSize="3xl" color="blue.600">
              {top5Total[0]?.rating_count.toLocaleString()}
            </Text>
            <Text fontSize="sm" color="gray.500">reviews</Text>
            <Progress 
              value={100} 
              colorScheme="yellow" 
              size="sm" 
              borderRadius="full" 
              mt={3}
            />
          </Box>

          {/* Bronze - Third Place */}
          <Box 
            bg={statBg} p={5} borderRadius="lg" boxShadow="sm" textAlign="center"
            position="relative" top="20px"
            cursor="pointer"
            onClick={() => top5Total[2] && handleBusinessClick(top5Total[2])}
            transition="all 0.2s"
            _hover={{ transform: 'translateY(-5px)', shadow: 'md' }}
          >
            <Icon as={FaAward} color="orange.300" boxSize={8} mb={2} />
            <AvatarGroup size="md" max={1} mb={3} justifyContent="center">
              <Avatar name={top5Total[2]?.name} src="" bg="orange.100" />
            </AvatarGroup>
            <Heading size="md" mb={2} noOfLines={1}>{top5Total[2]?.name}</Heading>
            <Text fontWeight="bold" fontSize="2xl" color="gray.600">
              {top5Total[2]?.rating_count.toLocaleString()}
            </Text>
            <Text fontSize="sm" color="gray.500">reviews</Text>
            <Progress 
              value={(top5Total[2]?.rating_count / (top5Total[0]?.rating_count || 1)) * 100} 
              colorScheme="orange" 
              size="sm" 
              borderRadius="full" 
              mt={3}
            />
          </Box>
        </SimpleGrid>
      </Box>

      {/* Main Content Area with Tabs */}
      <Box 
        bg={cardBg} 
        borderRadius="lg" 
        shadow="md" 
        borderWidth="1px"
        borderColor={borderColor}
        mb={8} 
        overflow="hidden"
      >
        <Tabs>
          <TabList px={4} pt={4}>
            <Tab fontWeight="medium">Overview</Tab>
            <Tab fontWeight="medium">Businesses</Tab>
            <Tab fontWeight="medium">Trends</Tab>
            <Tab fontWeight="medium">Rankings</Tab>
            <Tab fontWeight="medium">Details</Tab>
          </TabList>

          <TabPanels>
            {/* Overview Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                {/* Distribution Bar Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4} color="gray.700">Review Distribution</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart 
                      data={sortedByTotal.slice(0, 20).map(business => ({
                        name: business.name.split(' ').slice(0, 2).join(' '),
                        reviews: business.rating_count
                      }))}
                      margin={{ top: 5, right: 30, left: 20, bottom: 60 }}
                      animationDuration={1000}
                      animationBegin={0}
                      animationEasing="ease-out"
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
                      <XAxis 
                        dataKey="name" 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fill: '#4A5568', fontSize: 12 }}
                      />
                      <YAxis tick={{ fill: '#4A5568', fontSize: 12 }} />
                      <RechartsTooltip 
                        formatter={(value) => value.toLocaleString()}
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            return (
                              <Box 
                                bg="white" 
                                p={3} 
                                boxShadow="lg" 
                                border="1px" 
                                borderColor="gray.200" 
                                borderRadius="md"
                              >
                                <Text fontWeight="bold">{payload[0].payload.name}</Text>
                                <Text>
                                  {payload[0].value.toLocaleString()} reviews
                                </Text>
                                <Text color="orange.500">
                                  Market Average: {avgReviewsPerBusiness.toLocaleString()}
                                </Text>
                              </Box>
                            );
                          }
                          return null;
                        }}
                      />
                      <Bar 
                        dataKey="reviews" 
                        fill={colors[0]}
                        cursor="pointer"
                        onClick={(data) => {
                          const business = sortedByTotal.find(b => 
                            b.name.split(' ').slice(0, 2).join(' ') === data.name
                          );
                          if (business) handleBusinessClick(business);
                        }}
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={300}
                        animationEasing="ease-out"
                      >
                        {sortedByTotal.slice(0, 20).map((business, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={index < 5 ? getColor(business.name) : '#CBD5E0'} 
                          />
                        ))}
                      </Bar>
                      <ReferenceLine 
                        y={avgReviewsPerBusiness} 
                        stroke="#ED8936" 
                        strokeDasharray="5 5"
                        strokeWidth={2}
                        label={{ 
                          value: 'Market Average', 
                          position: 'right',
                          fill: '#ED8936'
                        }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>

                {/* Market Share Pie Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4} color="gray.700">Market Share</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={120}
                        paddingAngle={2}
                        dataKey="value"
                        label={({ name, value, percent }) => 
                          `${name} (${(percent * 100).toFixed(1)}%)`
                        }
                        labelLine={{ stroke: '#4A5568', strokeWidth: 1 }}
                        isAnimationActive={true}
                        animationBegin={300}
                        animationDuration={800}
                        animationEasing="ease-out"
                        cursor="pointer"
                        onClick={(data) => {
                          const business = sortedByTotal.find(b => 
                            b.name.split(' ').slice(0, 2).join(' ') === data.name
                          );
                          if (business) handleBusinessClick(business);
                        }}
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                      </Pie>
                      <RechartsTooltip 
                        formatter={(value) => value.toLocaleString()}
                        contentStyle={{
                          backgroundColor: 'white',
                          border: '1px solid #E2E8F0',
                          borderRadius: '4px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Businesses Panel */}
            <TabPanel>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
                spacing={6}
                alignItems="stretch"
              >
                {sortedByTotal.map((business, index) => (
                  <BusinessReviewCard 
                    key={business.maps_uri} 
                    business={business} 
                    index={index}
                  />
                ))}
              </SimpleGrid>
            </TabPanel>

            {/* Trends Panel */}
            <TabPanel>
              <Box height="500px">
                <Heading size="md" mb={4} color="gray.700">Monthly Review Trends</Heading>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={monthlyTrendData}>
                    <defs>
                      {top5Total.map((business, index) => (
                        business && (
                          <linearGradient 
                            key={business.name} 
                            id={`color${index}`} 
                            x1="0" 
                            y1="0" 
                            x2="0" 
                            y2="1"
                          >
                            <stop 
                              offset="5%" 
                              stopColor={getColor(business.name)} 
                              stopOpacity={0.8}
                            />
                            <stop 
                              offset="95%" 
                              stopColor={getColor(business.name)} 
                              stopOpacity={0.1}
                            />
                          </linearGradient>
                        )
                      ))}
                      <linearGradient id="colorMarket" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#ED8936" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#ED8936" stopOpacity={0.1}/>
                      </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
                    <XAxis 
                      dataKey="month" 
                      tickFormatter={(value) => value.split('-')[1]}
                      tick={{ fill: '#4A5568', fontSize: 12 }}
                    />
                    <YAxis 
                      tick={{ fill: '#4A5568', fontSize: 12 }}
                    />
                    <RechartsTooltip 
                      labelFormatter={(value) => new Date(value + '-01').toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
                      formatter={(value) => value.toLocaleString()}
                      contentStyle={{
                        backgroundColor: 'white',
                        border: '1px solid #E2E8F0',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                      }}
                    />
                    <Legend />
                    <Area
                      type="monotone"
                      dataKey="Market Average"
                      stroke="#ED8936"
                      strokeWidth={2}
                      fillOpacity={1}
                      fill="url(#colorMarket)"
                      strokeDasharray="5 5"
                    />
                    {top5Total.map((business, index) => (
                      business && (
                        <Area
                          key={business.name}
                          type="monotone"
                          dataKey={business.name.split(' ').slice(0, 2).join(' ')}
                          stroke={getColor(business.name)}
                          strokeWidth={2}
                          fillOpacity={1}
                          fill={`url(#color${index})`}
                        />
                      )
                    ))}
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            </TabPanel>

            {/* Rankings Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                <Box>
                  <RankingListComponent 
                    side="top" 
                    title="Top Review Rankings" 
                    data={top5Total} 
                    valueKey="rating_count" 
                    unit=" reviews" 
                  />
                </Box>
                <Box>
                  <RankingListComponent 
                    side="bottom" 
                    title="Lowest Review Rankings" 
                    data={sortedByTotal.slice(sortedByTotal.length - 5).reverse()} 
                    valueKey="rating_count" 
                    unit=" reviews" 
                  />
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Details Panel */}
            <TabPanel>
              <DataTable
                columns={columns}
                data={sortedByTotal}
                pagination
                paginationPerPage={10}
                sortable
                striped
                responsive
                highlightOnHover
                pointerOnHover
                onRowClicked={handleRowClick}
                defaultSortFieldId={2}
                defaultSortAsc={false}
                theme="default"
                customStyles={{
                  rows: {
                    style: {
                      minHeight: '52px',
                      cursor: 'pointer',
                    },
                  },
                  headCells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      fontWeight: 'bold',
                    },
                  },
                  cells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                    },
                  },
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {/* Add the Modal */}
      <BusinessReviewModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedBusiness(null);
        }}
        business={selectedBusiness}
        yearData={yearData}
      />
    </Box>
  );
};

export default TotalReviewsDashboard; 