import React, { useState, useEffect, useMemo } from 'react';
import { SectionLabel } from './DashboardWrapper';
import { 
  Box, 
  Heading, 
  Text, 
  VStack, 
  HStack, 
  Badge, 
  Icon, 
  SimpleGrid, 
  Stat, 
  StatLabel, 
  StatNumber, 
  StatHelpText, 
  Divider, 
  Portal, 
  Tag, 
  TagLabel, 
  TagCloseButton, 
  Wrap, 
  WrapItem, 
  List, 
  UnorderedList, 
  ListItem, 
  Button,
  Flex,
  useColorModeValue,
  Avatar,
  AvatarGroup,
  Tooltip,
  Progress,
  Input,
  InputGroup,
  InputLeftElement,
  Grid,
  Stack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Select,
  Link,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { 
  FaStar, 
  FaUsers, 
  FaSmile, 
  FaMeh,
  FaFrown,
  FaCheck, 
  FaTimes, 
  FaMapMarkerAlt, 
  FaPhone, 
  FaGlobeAmericas, 
  FaChevronDown, 
  FaSearch,
  FaChartLine,
  FaComments,
  FaThumbsUp,
} from 'react-icons/fa';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  Cell,
  ReferenceLine,
  PieChart,
  Pie,
} from 'recharts';
import RankingListComponent from '../RankingListComponent';
import DataTable from 'react-data-table-component';
import BusinessReviewModal from '../BusinessReviewModal';
import { getYearData } from '../../services/yearService';

const SentimentDashboard = ({ reviewsData, dateRange }) => {
  // Move all useColorModeValue hooks to the top level for consistency
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const statBg = useColorModeValue('gray.50', 'gray.700');
  const searchBg = useColorModeValue('white', 'gray.700');
  const searchBorder = useColorModeValue('gray.200', 'gray.600');

  const [yearData, setYearData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Colors for sentiment - using explicit hex values instead of theme variables
  const SENTIMENT_COLORS = {
    positive: useColorModeValue('#48BB78', '#68D391'), // green.500, green.400
    neutral: useColorModeValue('#ECC94B', '#F6E05E'), // yellow.500, yellow.400
    negative: useColorModeValue('#E53E3E', '#FC8181')  // red.500, red.400
  };

  // Colors for charts - using consistent color palette with more distinct colors
  const colors = [
    '#2F80ED', // bright blue
    '#27AE60', // emerald green
    '#EB5757', // bright red
    '#9B51E0', // purple
    '#F2994A', // orange
    '#219EBC', // teal
    '#F264A6', // pink
    '#BB6BD9', // violet
    '#2D9CDB', // light blue
    '#219653'  // dark green
  ];
  const [businessColors, setBusinessColors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const yearDataResponse = await getYearData();
        setYearData(yearDataResponse);
        
        // Combine data using maps_uri as unique identifier
        const combined = reviewsData.map(currentData => {
          const historicalData = yearDataResponse.find(yd => 
            yd.maps_uri === currentData.maps_uri
          );
          
          return {
            ...currentData,
            historicalData: historicalData?.data || null
          };
        });
        
        setCombinedData(combined);
      } catch (error) {
        console.error("Error fetching year data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (reviewsData?.length > 0) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [reviewsData, dateRange]); // Add dateRange dependency to re-process when date changes

  useEffect(() => {
    // Assign colors to businesses
    const newBusinessColors = {};
    reviewsData.forEach((business, index) => {
      newBusinessColors[business.name] = colors[index % colors.length];
    });
    setBusinessColors(newBusinessColors);
  }, [reviewsData]);

  const getColor = (businessName) => {
    return businessColors[businessName] || colors[0];
  };

  const calculateSentimentScore = (sentimentData) => {
    if (!sentimentData) return 0;
    const total = sentimentData.positive + sentimentData.neutral + sentimentData.negative;
    return total > 0 ? (sentimentData.positive / total * 100) : 0;
  };

  // Calculate sentiment metrics for each business
  const businessSentimentData = useMemo(() => {
    return combinedData.map(business => {
      const sentimentBreakdown = business.historicalData?.sentimentBreakdown || {
        positive: 0,
        neutral: 0,
        negative: 0
      };
      
      const total = sentimentBreakdown.positive + sentimentBreakdown.neutral + sentimentBreakdown.negative;
      const sentimentScore = total > 0 
        ? ((sentimentBreakdown.positive + (sentimentBreakdown.neutral * 0.5)) / total * 100)
        : 0;
      
      const positivePercentage = total > 0 
        ? (sentimentBreakdown.positive / total * 100)
        : 0;
      
      return {
        ...business,
        sentimentScore,
        positivePercentage,
        ...sentimentBreakdown,
        total
      };
    });
  }, [combinedData]);

  // Filter businesses based on name and type
  const filteredBusinesses = useMemo(() => {
    if (!businessSentimentData.length) return [];
    return businessSentimentData;
  }, [businessSentimentData]);

  // Sort businesses based on sentiment score
  const sortedBySentiment = useMemo(() => {
    if (!filteredBusinesses.length) return [];
    
    return [...filteredBusinesses].sort((a, b) => {
      return b.sentimentScore - a.sentimentScore;
    });
  }, [filteredBusinesses]);

  // Select top businesses for display
  const top5Sentiment = useMemo(() => sortedBySentiment.slice(0, 5), [sortedBySentiment]);
  const bottom5Sentiment = useMemo(() => sortedBySentiment.slice(-5).reverse(), [sortedBySentiment]);

  // Calculate market-wide metrics
  const marketMetrics = useMemo(() => {
    const totalPositive = sortedBySentiment.reduce((sum, b) => sum + b.positive, 0);
    const totalNeutral = sortedBySentiment.reduce((sum, b) => sum + b.neutral, 0);
    const totalNegative = sortedBySentiment.reduce((sum, b) => sum + b.negative, 0);
    const totalReviews = totalPositive + totalNeutral + totalNegative;
    
    const marketSentimentScore = totalReviews > 0
      ? ((totalPositive + (totalNeutral * 0.5)) / totalReviews * 100)
      : 0;
    
    const avgSentimentScore = sortedBySentiment.length > 0
      ? sortedBySentiment.reduce((sum, b) => sum + b.sentimentScore, 0) / sortedBySentiment.length
      : 0;

    return {
      totalPositive,
      totalNeutral,
      totalNegative,
      totalReviews,
      marketSentimentScore,
      avgSentimentScore,
      positivePercentage: totalReviews > 0 ? (totalPositive / totalReviews) * 100 : 0,
      neutralPercentage: totalReviews > 0 ? (totalNeutral / totalReviews) * 100 : 0,
      negativePercentage: totalReviews > 0 ? (totalNegative / totalReviews) * 100 : 0
    };
  }, [sortedBySentiment]);

  // Prepare data for bar chart
  const barChartData = useMemo(() => {
    return top5Sentiment.map((business) => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      score: business.sentimentScore,
      positive: business.positive,
      neutral: business.neutral,
      negative: business.negative,
      fill: getColor(business.name)
    }));
  }, [top5Sentiment]);

  // Prepare data for pie chart
  const pieChartData = useMemo(() => {
    return [
      { name: 'Positive 😊', value: marketMetrics.totalPositive, fill: '#48BB78' /* Explicit green.500 */ },
      { name: 'Neutral 😐', value: marketMetrics.totalNeutral, fill: '#ECC94B' /* Explicit yellow.500 */ },
      { name: 'Negative 😞', value: marketMetrics.totalNegative, fill: '#E53E3E' /* Explicit red.500 */ }
    ];
  }, [marketMetrics]);

  // Prepare data table columns
  const columns = [
    {
      name: 'Business Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Sentiment Score',
      selector: row => row.sentimentScore,
      sortable: true,
      right: true,
      format: row => `${row.sentimentScore.toFixed(1)}%`,
    },
    {
      name: 'Positive Reviews',
      selector: row => row.positive,
      sortable: true,
      right: true,
      cell: row => (
        <Flex align="center">
          <Text mr={2}>{row.positive}</Text>
          <Icon as={FaSmile} color={SENTIMENT_COLORS.positive} />
        </Flex>
      ),
    },
    {
      name: 'Neutral Reviews',
      selector: row => row.neutral,
      sortable: true,
      right: true,
      cell: row => (
        <Flex align="center">
          <Text mr={2}>{row.neutral}</Text>
          <Icon as={FaMeh} color={SENTIMENT_COLORS.neutral} />
        </Flex>
      ),
    },
    {
      name: 'Negative Reviews',
      selector: row => row.negative,
      sortable: true,
      right: true,
      cell: row => (
        <Flex align="center">
          <Text mr={2}>{row.negative}</Text>
          <Icon as={FaFrown} color={SENTIMENT_COLORS.negative} />
        </Flex>
      ),
    }
  ];

  const handleBusinessClick = (business) => {
    setSelectedBusiness(business);
    setIsModalOpen(true);
  };

  if (isLoading) {
    return (
      <Flex justify="center" align="center" minH="200px">
        <Spinner 
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    );
  }

  // Business card component
  const BusinessSentimentCard = ({ business }) => {
    const sentimentIcon = business.sentimentScore >= 70 
      ? FaSmile 
      : business.sentimentScore >= 50 
        ? FaMeh 
        : FaFrown;
    
    const sentimentColor = business.sentimentScore >= 70 
      ? SENTIMENT_COLORS.positive
      : business.sentimentScore >= 50 
        ? SENTIMENT_COLORS.neutral
        : SENTIMENT_COLORS.negative;
    
    // Define hover background color at component level
    const hoverBg = useColorModeValue('gray.50', 'gray.700');

    return (
      <Box
        bg={cardBg}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="lg"
        overflow="hidden"
        p={4}
        cursor="pointer"
        onClick={() => handleBusinessClick(business)}
        transition="all 0.2s"
        _hover={{ 
          transform: 'translateY(-4px)',
          shadow: 'lg',
          borderColor: 'blue.400',
          bg: hoverBg
        }}
        height="100%"
      >
        <VStack align="stretch" spacing={4} height="100%">
          <Box flex="1">
            <Heading
              size="md"
              mb={2}
              lineHeight="1.4"
              minHeight="2.8em"
              overflow="hidden"
              css={{
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
              title={business.name}
            >
              {business.name}
            </Heading>
            <HStack spacing={2} mb={2} justify="space-between">
              <Badge colorScheme="blue">
                {business.primary_type?.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) || 'Business'}
              </Badge>
              <HStack spacing={1}>
                <Icon as={sentimentIcon} color={sentimentColor} boxSize={5} />
                <Text fontWeight="bold">{business.sentimentScore.toFixed(1)}%</Text>
              </HStack>
            </HStack>
          </Box>

          {/* Sentiment Distribution */}
          <Box>
            <Text fontSize="sm" fontWeight="medium" mb={1}>Sentiment Breakdown</Text>
            <SimpleGrid columns={3} spacing={2} mb={3}>
              <VStack>
                <Icon as={FaSmile} color={SENTIMENT_COLORS.positive} boxSize={4} />
                <Text fontWeight="bold">{business.positive}</Text>
              </VStack>
              <VStack>
                <Icon as={FaMeh} color={SENTIMENT_COLORS.neutral} boxSize={4} />
                <Text fontWeight="bold">{business.neutral}</Text>
              </VStack>
              <VStack>
                <Icon as={FaFrown} color={SENTIMENT_COLORS.negative} boxSize={4} />
                <Text fontWeight="bold">{business.negative}</Text>
              </VStack>
            </SimpleGrid>
            
            {/* Progress bars */}
            <VStack spacing={1} align="stretch">
              <Progress 
                value={business.positivePercentage} 
                colorScheme="green" 
                size="sm" 
                borderRadius="full" 
                mb={1}
              />
              <Progress 
                value={business.neutral / business.total * 100} 
                colorScheme="yellow" 
                size="sm" 
                borderRadius="full" 
                mb={1}
              />
              <Progress 
                value={business.negative / business.total * 100} 
                colorScheme="red" 
                size="sm" 
                borderRadius="full"
              />
            </VStack>
          </Box>

          {/* Basic Info */}
          <VStack align="start" spacing={1} mt="auto" fontSize="sm">
            <HStack spacing={2}>
              <Icon as={FaMapMarkerAlt} color="gray.500" boxSize={4} />
              <Text noOfLines={1}>{business.address || 'No address available'}</Text>
            </HStack>
            <HStack spacing={2}>
              <Icon as={FaComments} color="gray.500" boxSize={4} />
              <Text>{business.total} reviews</Text>
            </HStack>
          </VStack>
        </VStack>
      </Box>
    );
  };

  return (
    <Box>

      {/* Market Metrics */}
      <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={6} mb={8}>
        <Box 
          bg={cardBg} 
          p={6} 
          borderRadius="lg" 
          shadow="md" 
          borderWidth="1px"
          borderColor={borderColor}
        >
          <VStack align="start" spacing={4}>
            <HStack>
              <Icon as={FaSmile} boxSize={6} color={SENTIMENT_COLORS.positive} />
              <Heading size="md">Overall Sentiment</Heading>
            </HStack>
            <Stat>
              <StatLabel>Market Sentiment Score</StatLabel>
              <StatNumber fontSize="4xl" color={marketMetrics.marketSentimentScore >= 70 ? 'green.500' : marketMetrics.marketSentimentScore >= 50 ? 'yellow.500' : 'red.500'}>
                {marketMetrics.marketSentimentScore.toFixed(1)}%
              </StatNumber>
              <StatHelpText>Based on {marketMetrics.totalReviews.toLocaleString()} reviews</StatHelpText>
            </Stat>

            <Box w="100%">
              <Text mb={2}>Sentiment Distribution</Text>
              <Box 
                w="100%" 
                h="16px" 
                bg="gray.100" 
                borderRadius="full" 
                position="relative"
                overflow="hidden"
              >
                <Box 
                  position="absolute" 
                  left={0} 
                  top={0} 
                  h="100%" 
                  w={`${marketMetrics.positivePercentage}%`} 
                  bg={SENTIMENT_COLORS.positive} 
                />
                <Box 
                  position="absolute" 
                  left={`${marketMetrics.positivePercentage}%`} 
                  top={0} 
                  h="100%" 
                  w={`${marketMetrics.neutralPercentage}%`} 
                  bg={SENTIMENT_COLORS.neutral} 
                />
                <Box 
                  position="absolute" 
                  left={`${marketMetrics.positivePercentage + marketMetrics.neutralPercentage}%`} 
                  top={0} 
                  h="100%" 
                  w={`${marketMetrics.negativePercentage}%`} 
                  bg={SENTIMENT_COLORS.negative} 
                />
              </Box>
              <HStack mt={2} justify="space-between" fontSize="sm">
                <HStack>
                  <Icon as={FaSmile} color={SENTIMENT_COLORS.positive} />
                  <Text>{marketMetrics.positivePercentage.toFixed(1)}%</Text>
                </HStack>
                <HStack>
                  <Icon as={FaMeh} color={SENTIMENT_COLORS.neutral} />
                  <Text>{marketMetrics.neutralPercentage.toFixed(1)}%</Text>
                </HStack>
                <HStack>
                  <Icon as={FaFrown} color={SENTIMENT_COLORS.negative} />
                  <Text>{marketMetrics.negativePercentage.toFixed(1)}%</Text>
                </HStack>
              </HStack>
            </Box>
          </VStack>
        </Box>

        <Box 
          bg={cardBg} 
          p={6} 
          borderRadius="lg" 
          shadow="md" 
          borderWidth="1px"
          borderColor={borderColor}
          gridColumn={{ base: "auto", lg: "span 2" }}
        >
          <VStack align="start" spacing={4} h="100%">
            <HStack>
              <Icon as={FaThumbsUp} boxSize={6} color="blue.500" />
              <Heading size="md">Sentiment Leaders</Heading>
            </HStack>

            {/* Top 3 Podium */}
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} w="full" flex="1">
              {top5Sentiment.slice(0, 3).map((business, index) => (
                <Box 
                  key={business.maps_uri}
                  bg={statBg} 
                  p={4} 
                  borderRadius="lg" 
                  textAlign="center"
                  position="relative"
                  transform={index === 0 ? "scale(1.05)" : "none"}
                  zIndex={index === 0 ? 1 : "auto"}
                  top={index !== 0 ? { base: 0, md: "10px" } : 0}
                  boxShadow={index === 0 ? "md" : "sm"}
                  cursor="pointer"
                  onClick={() => handleBusinessClick(business)}
                  _hover={{ transform: "translateY(-2px)", shadow: "md" }}
                  transition="all 0.2s"
                >
                  <Text fontSize={index === 0 ? "2xl" : "xl"} mb={2}>
                    {index === 0 ? "🏆" : index === 1 ? "🥈" : "🥉"}
                  </Text>
                  <Heading size="sm" mb={2} noOfLines={1}>{business.name}</Heading>
                  <Text 
                    fontWeight="bold" 
                    fontSize={index === 0 ? "2xl" : "xl"} 
                    color={business.sentimentScore >= 70 ? SENTIMENT_COLORS.positive : business.sentimentScore >= 50 ? SENTIMENT_COLORS.neutral : SENTIMENT_COLORS.negative}
                  >
                    {business.sentimentScore.toFixed(1)}%
                  </Text>
                  <Text fontSize="xs" mt={1}>sentiment score</Text>
                  <HStack justify="center" spacing={4} mt={2} fontSize="sm">
                    <VStack spacing={0}>
                      <Icon as={FaSmile} color={SENTIMENT_COLORS.positive} />
                      <Text>{business.positive}</Text>
                    </VStack>
                    <VStack spacing={0}>
                      <Icon as={FaMeh} color={SENTIMENT_COLORS.neutral} />
                      <Text>{business.neutral}</Text>
                    </VStack>
                    <VStack spacing={0}>
                      <Icon as={FaFrown} color={SENTIMENT_COLORS.negative} />
                      <Text>{business.negative}</Text>
                    </VStack>
                  </HStack>
                </Box>
              ))}
            </SimpleGrid>
          </VStack>
        </Box>
      </SimpleGrid>

      {/* Section Label */}
      <SectionLabel description="Analyze positive and negative sentiment patterns in customer reviews to identify improvement opportunities.">Sentiment Breakdown and Patterns</SectionLabel>

      {/* Main Content */}
      <Box bg={cardBg} borderRadius="lg" shadow="md" borderWidth="1px" borderColor={borderColor} mb={8} overflow="hidden">
        <Tabs>
          <TabList px={4} pt={4}>
            <Tab fontWeight="medium">Overview</Tab>
            <Tab fontWeight="medium">Businesses</Tab>
            <Tab fontWeight="medium">Rankings</Tab>
            <Tab fontWeight="medium">Details</Tab>
          </TabList>

          <TabPanels>
            {/* Overview Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                {/* Sentiment Distribution Bar Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4}>Business Sentiment Comparison</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart 
                      data={sortedBySentiment.slice(0, 20).map(business => ({
                        name: business.name.split(' ').slice(0, 2).join(' '),
                        sentiment: business.sentimentScore,
                        positive: business.positive,
                        neutral: business.neutral,
                        negative: business.negative
                      }))}
                      margin={{ top: 5, right: 30, left: 20, bottom: 60 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="name"
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis unit="%" />
                      <RechartsTooltip 
                        formatter={(value) => `${value.toFixed(1)}%`}
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            return (
                              <Box bg="white" p={2} border="1px" borderColor="gray.200" borderRadius="md">
                                <Text fontWeight="bold">{payload[0].payload.name}</Text>
                                <Text>Score: {payload[0].value.toFixed(1)}% 🎯</Text>
                                <Text>Positive: {payload[0].payload.positive} 😊</Text>
                                <Text>Neutral: {payload[0].payload.neutral} 😐</Text>
                                <Text>Negative: {payload[0].payload.negative} 😞</Text>
                              </Box>
                            );
                          }
                          return null;
                        }}
                      />
                      <Bar 
                        dataKey="sentiment" 
                        fill="#48BB78" /* Explicitly setting a visible green color */
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={0}
                      >
                        {sortedBySentiment.slice(0, 20).map((_, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={index < 5 ? '#48BB78' /* Using explicit green.500 */ : '#CBD5E0'} 
                          />
                        ))}
                      </Bar>
                      <ReferenceLine 
                        y={marketMetrics.avgSentimentScore} 
                        stroke="#ED8936" 
                        strokeDasharray="5 5"
                        strokeWidth={2}
                        label={{ 
                          value: 'Market Average', 
                          position: 'right', 
                          fill: '#ED8936',
                          fontSize: 12 
                        }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>

                {/* Market Sentiment Pie Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4}>Market Sentiment Distribution</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={120}
                        paddingAngle={2}
                        dataKey="value"
                        label={({ name, value, percent }) => 
                          `${name} (${(percent * 100).toFixed(1)}%)`
                        }
                        isAnimationActive={true}
                        animationBegin={0}
                        animationDuration={800}
                        // Explicitly set colors
                        fill="#8884d8"
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                      </Pie>
                      <RechartsTooltip formatter={(value) => value.toLocaleString()} />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Businesses Panel */}
            <TabPanel>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
                spacing={6}
                alignItems="stretch"
              >
                {sortedBySentiment.map(business => (
                  <BusinessSentimentCard 
                    key={business.maps_uri} 
                    business={business} 
                  />
                ))}
              </SimpleGrid>
            </TabPanel>

            {/* Rankings Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                <Box>
                  <RankingListComponent
                    title="Top Sentiment Rankings 😊"
                    data={top5Sentiment}
                    valueKey="sentimentScore"
                    unit="%"
                    side="top"
                  />
                </Box>
                <Box>
                  <RankingListComponent
                    title="Lowest Sentiment Rankings 💔"
                    data={bottom5Sentiment}
                    valueKey="sentimentScore"
                    unit="%"
                    side="bottom"
                  />
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Details Panel */}
            <TabPanel>
              <DataTable
                columns={columns}
                data={sortedBySentiment}
                pagination
                paginationPerPage={10}
                sortable
                striped
                responsive
                highlightOnHover
                pointerOnHover
                onRowClicked={handleBusinessClick}
                defaultSortFieldId={2}
                defaultSortAsc={false}
                theme="default"
                customStyles={{
                  rows: {
                    style: {
                      minHeight: '52px',
                      cursor: 'pointer',
                    },
                  },
                  headCells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      fontWeight: 'bold',
                    },
                  },
                  cells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                    },
                  },
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {/* Add the Modal */}
      <BusinessReviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        business={selectedBusiness}
        yearData={yearData}
      />
    </Box>
  );
};

export default SentimentDashboard;