import { Box, Button, Flex, FormControl, FormLabel, Input, VStack, Heading, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home');
    } catch (error) {
      setError(
        error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password'
          ? 'Invalid email or password'
          : 'An error occurred. Please try again.'
      );
    }
  };

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center" bg="gray.50">
      <Box p={8} maxW="md" borderWidth={1} borderRadius="lg" bg="white" boxShadow="lg">
        <VStack spacing={6}>
          <Heading size="lg">Beta Member Login</Heading>
          <form onSubmit={handleLogin} style={{ width: '100%' }}>
            <VStack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input 
                  type="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input 
                  type="password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                />
              </FormControl>
              {error && (
                <Text color="red.500" fontSize="sm">
                  {error}
                </Text>
              )}
              <Button type="submit" colorScheme="blue" width="full">
                Login
              </Button>
              <Button 
                as={RouterLink} 
                to="/landing" 
                variant="ghost" 
                width="full"
              >
                Back to Landing Page
              </Button>
            </VStack>
          </form>
        </VStack>
      </Box>
    </Flex>
  );
}

export default Login;