import { logger } from './logger';

export const getBaseURL = () => {
  // Check if we're on Firebase hosting or production domain
  if (window.location.hostname.includes('web.app') || 
      window.location.hostname.includes('firebaseapp.com') ||
      window.location.hostname.includes('localytics.co')) {
    
    // If URL contains staging, use staging API
    if (window.location.hostname.includes('staging')) {
      return 'https://localytics-platform-staging-e12f3ee1f38b.herokuapp.com';
    }
    // If we're on production domain or production Firebase, use production API
    if (window.location.hostname.includes('production') || 
        window.location.hostname.includes('localytics.co')) {
      return 'https://localytics-platform-server-816445c97ee2.herokuapp.com';
    }
    // Default to staging for other Firebase environments
    return 'https://localytics-platform-staging-e12f3ee1f38b.herokuapp.com';
  }

  // Fallback to environment-based URLs
  switch(process.env.NODE_ENV) {
    case 'development':
      return 'http://localhost:8000';
    case 'staging':
      return 'https://localytics-platform-staging-e12f3ee1f38b.herokuapp.com';
    case 'production':
      return 'https://localytics-platform-server-816445c97ee2.herokuapp.com';
    default:
      // Default to staging if environment is not specified
      return 'https://localytics-platform-staging-e12f3ee1f38b.herokuapp.com';
  }
};

// Create a base axios config that can be imported by services
// force a dep.. remove
export const baseAxiosConfig = {
  baseURL: getBaseURL(),
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
}; 