import React, { useState, useRef } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Input,
  Button,
  SimpleGrid,
  Icon,
  HStack,
  useColorModeValue,
  Badge,
  OrderedList,
  ListItem,
} from '@chakra-ui/react';
import { FaChartLine, FaLightbulb, FaArrowRight, FaStar, FaUsers, FaCheckCircle, FaEnvelope } from 'react-icons/fa';
import { submitLandingForm } from '../services/landingService';
import { Link as RouterLink } from 'react-router-dom';
import { getRateLimitService } from '../services/rateLimitService';
import AnimatedBox from './AnimatedBox';

const BUSINESS_TYPES = [
  {
    title: "Local Restaurant - East Village",
    icon: "🍽️",
    metrics: {
      reviews: "342 reviews analyzed",
      growth: "22% YoY growth",
      insight: "High demand for delivery options"
    }
  },
  {
    title: "Auto Repair Shop - Nashville",
    icon: "🔧",
    metrics: {
      reviews: "198 reviews analyzed",
      growth: "12% YoY growth",
      insight: "Growing demand for EV services"
    }
  },
  {
    title: "HVAC Services - Atlanta",
    icon: "❄️",
    metrics: {
      reviews: "127 reviews analyzed",
      growth: "15% YoY growth",
      insight: "Seasonal peak predictions"
    }
  }
];

const rateLimitService = getRateLimitService();

const ConsumerLanding = () => {
  const [email, setEmail] = useState('');
  const [niche, setNiche] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const headingColor = useColorModeValue('gray.800', 'white');
  const accentColor = useColorModeValue('blue.500', 'blue.300');
  const inputBg = useColorModeValue('white', 'gray.700');
  const inputBorder = useColorModeValue('gray.200', 'gray.600');
  const nicheBg = useColorModeValue('gray.50', 'gray.700');
  const hoverBg = useColorModeValue('gray.100', 'gray.600');

  const businessInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (rateLimitService.isRateLimited('landingSubmit')) {
      setError('Please wait a moment before trying again');
      return;
    }

    if (!email || !niche) return;
    
    setIsLoading(true);
    try {
      await submitLandingForm(email, niche);
      setShowSuccess(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExampleClick = (title) => {
    setNiche(title);
    if (businessInputRef.current) {
      businessInputRef.current.focus();
    }
  };

  return (
    <Box minH="100vh" bg={bgColor} py={{ base: 8, md: 20 }}>
      <Container maxW="container.xl" px={{ base: 4, md: 8 }}>
        <VStack spacing={16}>
          {/* Add Beta Member Login Link */}
          <Box position={{ base: 'relative', md: 'absolute' }} top={4} right={4} mb={{ base: 4, md: 0 }}>
            <Button
              as={RouterLink}
              to="/login"
              variant="ghost"
              size="sm"
              colorScheme="blue"
              leftIcon={<FaUsers />}
              w={{ base: 'full', md: 'auto' }}
            >
              Beta Member Login
            </Button>
          </Box>

          {/* Hero Section */}
          <VStack spacing={6} textAlign="center" maxW="800px">
            <AnimatedBox animation="slideUp" delay={0.2}>
              <Badge colorScheme="blue" fontSize="md" px={4} py={2} borderRadius="full">
                Beta Access Now Open
              </Badge>
            </AnimatedBox>
            <AnimatedBox animation="fadeIn" delay={0.5}>
              <Heading 
                size="2xl" 
                color={headingColor}
                lineHeight={1.2}
                fontWeight="extrabold"
              >
                Understand Your Local Market Like Never Before
              </Heading>
            </AnimatedBox>
            <AnimatedBox animation="fadeIn" delay={0.7}>
              <Text fontSize="xl" color={textColor}>
                Get AI-powered insights from local business data, customer reviews, and market trends
              </Text>
            </AnimatedBox>
          </VStack>

          {/* Main Card */}
          <AnimatedBox 
            animation="scale" 
            delay={1.0}
            bg={cardBg} 
            borderRadius="2xl" 
            boxShadow="xl"
            p={8}
            w="full"
            maxW="900px"
            mx="auto"
          >
            <VStack spacing={8} align="stretch">
              {showSuccess ? (
                <VStack spacing={6} align="center" py={4}>
                  <AnimatedBox animation="pulse" repeat={true} duration={1.5}>
                    <Icon as={FaCheckCircle} w={16} h={16} color="green.400" />
                  </AnimatedBox>
                  <AnimatedBox animation="fadeIn" delay={0.3}>
                    <Heading size="lg" textAlign="center" color={headingColor}>
                      Application Submitted! 🎉
                    </Heading>
                  </AnimatedBox>
                  <AnimatedBox animation="fadeIn" delay={0.5}>
                    <Text fontSize="md" textAlign="center" color={textColor} maxW="600px">
                      Thank you for your interest in Localytics Beta. Your application has been received and is being reviewed by our team.
                    </Text>
                  </AnimatedBox>
                  <AnimatedBox animation="slideUp" delay={0.7}>
                    <Box bg={nicheBg} p={4} borderRadius="xl" w="full" maxW="600px">
                      <VStack spacing={3} align="start">
                        <Text fontWeight="bold" color={headingColor}>What happens next?</Text>
                        <OrderedList spacing={2} pl={4}>
                          <ListItem>Our team will review your application within 24-48 hours</ListItem>
                          <ListItem>You may receive an email with your beta access credentials</ListItem>
                          <ListItem>You can immediately start exploring your local market insights</ListItem>
                        </OrderedList>
                      </VStack>
                    </Box>
                  </AnimatedBox>
                  <AnimatedBox animation="fadeIn" delay={1.0}>
                    <HStack spacing={3} pt={3}>
                      <Button
                        leftIcon={<FaEnvelope />}
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => window.location.href = `mailto:${email}`}
                        size="md"
                        fontSize="sm"
                        _hover={{ transform: "translateY(-2px)", boxShadow: "md" }}
                        transition="all 0.2s"
                      >
                        Open Email
                      </Button>
                      <Button
                        colorScheme="blue"
                        onClick={() => {
                          setShowSuccess(false);
                          setEmail('');
                          setNiche('');
                          setStep(1);
                        }}
                        size="md"
                        fontSize="sm"
                        _hover={{ transform: "translateY(-2px)", boxShadow: "md" }}
                        transition="all 0.2s"
                      >
                        Submit Another
                      </Button>
                    </HStack>
                  </AnimatedBox>
                </VStack>
              ) : (
                <>
                  {step === 1 ? (
                    <VStack spacing={4}>
                      <AnimatedBox animation="fadeIn" delay={0.3}>
                        <Heading size="md" color={headingColor}>Start Your Free Market Analysis</Heading>
                      </AnimatedBox>
                      <form onSubmit={(e) => {
                        e.preventDefault();
                        if (!email || !email.includes('@')) {
                          setError('Invalid email format');
                          return;
                        }
                        setStep(2);
                      }} style={{ width: '100%' }}>
                        <VStack w="full" spacing={4}>
                          <AnimatedBox animation="slideUp" delay={0.5} w="full">
                            <Input
                              size="lg"
                              placeholder="Enter your business email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setError('');
                              }}
                              bg={inputBg}
                              borderColor={error ? "red.500" : inputBorder}
                              _hover={{ borderColor: error ? "red.500" : accentColor, transform: "translateY(-2px)", boxShadow: "sm" }}
                              _focus={{ borderColor: error ? "red.500" : accentColor, boxShadow: 'none' }}
                              h="50px"
                              w="full"
                              transition="all 0.2s"
                            />
                          </AnimatedBox>
                          <AnimatedBox animation="fadeIn" delay={0.7} w="full">
                            <Button
                              type="submit"
                              size="lg"
                              colorScheme="blue"
                              rightIcon={<FaArrowRight />}
                              h="50px"
                              w="full"
                              _hover={{ transform: "translateY(-2px)", boxShadow: "md" }}
                              transition="all 0.2s"
                            >
                              Continue
                            </Button>
                          </AnimatedBox>
                        </VStack>
                      </form>
                      {error && (
                        <Text color="red.500" fontSize="sm" alignSelf="flex-start">
                          {error}
                        </Text>
                      )}
                    </VStack>
                  ) : (
                    <VStack spacing={4}>
                      <AnimatedBox animation="fadeIn" delay={0.3}>
                        <Heading size="md" color={headingColor}>What type of business do you run?</Heading>
                      </AnimatedBox>
                      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <VStack spacing={6} w="full">
                          <AnimatedBox animation="slideUp" delay={0.5} w="full">
                            <Input
                              ref={businessInputRef}
                              size="lg"
                              placeholder="Enter your business type and location (e.g., Local Restaurant - East Village)"
                              value={niche}
                              onChange={(e) => {
                                setNiche(e.target.value);
                                setError('');
                              }}
                              bg={inputBg}
                              borderColor={error ? "red.500" : inputBorder}
                              _hover={{ borderColor: error ? "red.500" : accentColor, transform: "translateY(-2px)", boxShadow: "sm" }}
                              _focus={{ borderColor: error ? "red.500" : accentColor, boxShadow: 'none' }}
                              h="60px"
                              w="full"
                              transition="all 0.2s"
                            />
                          </AnimatedBox>

                          {error && (
                            <Text color="red.500" fontSize="sm" w="full">
                              {error}
                            </Text>
                          )}

                          {/* Example Business Types */}
                          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} w="full">
                            {BUSINESS_TYPES.map((type, index) => (
                              <AnimatedBox
                                key={index}
                                animation="slideUp"
                                delay={0.2 + index * 0.15}
                                p={4}
                                bg={nicheBg}
                                borderRadius="lg"
                                cursor="pointer"
                                onClick={() => handleExampleClick(type.title)}
                                _hover={{ transform: "translateY(-5px)", boxShadow: "md" }}
                                transition="all 0.3s ease"
                              >
                                <VStack align="start" spacing={2}>
                                  <HStack>
                                    <Text fontSize="xl">{type.icon}</Text>
                                    <Text fontWeight="semibold">{type.title}</Text>
                                  </HStack>
                                  <VStack align="start" spacing={1}>
                                    <HStack>
                                      <Icon as={FaStar} color="yellow.400" />
                                      <Text fontSize="sm" color={textColor}>{type.metrics.reviews}</Text>
                                    </HStack>
                                    <HStack>
                                      <Icon as={FaChartLine} color="green.500" />
                                      <Text fontSize="sm" color="green.500">{type.metrics.growth}</Text>
                                    </HStack>
                                    <HStack>
                                      <Icon as={FaLightbulb} color={textColor} />
                                      <Text fontSize="sm" color={textColor}>{type.metrics.insight}</Text>
                                    </HStack>
                                  </VStack>
                                </VStack>
                              </AnimatedBox>
                            ))}
                          </SimpleGrid>

                          {/* Buttons inside the form */}
                          <AnimatedBox animation="fadeIn" delay={0.7} w="full">
                            <HStack spacing={4} w="full" justify="space-between">
                              <Button 
                                onClick={(e) => {
                                  e.preventDefault();
                                  setStep(1);
                                }}
                                size="lg"
                                variant="ghost"
                                h="50px"
                                w="30%"
                                type="button"
                                _hover={{ transform: "translateY(-2px)", bg: hoverBg }}
                                transition="all 0.2s"
                              >
                                Back
                              </Button>
                              <Button
                                type="submit"
                                colorScheme="blue"
                                rightIcon={<FaArrowRight />}
                                isDisabled={!niche}
                                isLoading={isLoading}
                                size="lg"
                                h="50px"
                                w="65%"
                                fontSize={{ base: "sm", md: "md" }}
                                px={{ base: 2, md: 4 }}
                                _hover={{ transform: "translateY(-2px)", boxShadow: "md" }}
                                transition="all 0.2s"
                              >
                                Request Beta Access
                              </Button>
                            </HStack>
                          </AnimatedBox>
                        </VStack>
                      </form>
                    </VStack>
                  )}
                </>
              )}
            </VStack>
          </AnimatedBox>

          {/* Trust Indicators */}
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} maxW="800px">
            <AnimatedBox animation="slideUp" delay={1.2}>
              <VStack>
                <Icon as={FaUsers} w={8} h={8} color={accentColor} />
                <Text fontWeight="bold" color={headingColor}>1000+ Businesses</Text>
                <Text color={textColor} fontSize="sm">Trust our insights</Text>
              </VStack>
            </AnimatedBox>
            <AnimatedBox animation="slideUp" delay={1.4}>
              <VStack>
                <Icon as={FaStar} w={8} h={8} color={accentColor} />
                <Text fontWeight="bold" color={headingColor}>50K+ Reviews</Text>
                <Text color={textColor} fontSize="sm">Analyzed monthly</Text>
              </VStack>
            </AnimatedBox>
            <AnimatedBox animation="slideUp" delay={1.6}>
              <VStack>
                <Icon as={FaChartLine} w={8} h={8} color={accentColor} />
                <Text fontWeight="bold" color={headingColor}>98% Accuracy</Text>
                <Text color={textColor} fontSize="sm">In market predictions</Text>
              </VStack>
            </AnimatedBox>
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};

export default ConsumerLanding; 