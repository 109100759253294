import React, { useState, useEffect, useMemo } from 'react';
import AnimatedBox from './AnimatedBox';
import {
  Box,
  SimpleGrid,
  Heading,
  Text,
  useColorModeValue,
  VStack,
  HStack,
  Button,
  Icon,
  Flex,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
  Avatar,
  Progress,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Area,
  AreaChart,
} from 'recharts';
import { 
  FaChartLine, 
  FaChartBar, 
  FaChartPie, 
  FaSmile, 
  FaSearch, 
  FaLightbulb, 
  FaTrophy, 
  FaArrowUp, 
  FaArrowDown, 
  FaBuilding,
  FaCrown,
  FaStoreAlt,
  FaRegStar,
} from 'react-icons/fa';
import getYearData from '../services/yearService';
import { getReviews } from '../services/reviewService';
import { logger } from '../utils/logger';

const HomePage = () => {
  const [reviewsData, setReviewsData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filteredDashboards, setFilteredDashboards] = useState([]);
  const navigate = useNavigate();
  
  // Theme colors
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const cardHoverBg = useColorModeValue('gray.50', 'gray.700');
  const searchBg = useColorModeValue('white', 'gray.700');
  const searchBorder = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const subtitleColor = useColorModeValue('gray.600', 'gray.400');
  
  // Colors for charts and visualizations - using useMemo to avoid recreation on every render
  const COLORS = useMemo(() => ({
    primary: '#0066ff',
    secondary: '#4299E1',
    success: '#48BB78',
    warning: '#ECC94B',
    danger: '#F56565',
    info: '#9F7AEA',
    charts: [
      '#2F80ED', // bright blue
      '#27AE60', // emerald green
      '#9B51E0', // purple
      '#F2994A', // orange
      '#EB5757', // bright red
      '#219EBC', // teal
      '#F264A6', // pink
      '#BB6BD9', // violet
    ]
  }), []);
  
  // Dashboard data for searching - using useMemo to avoid recreation on every render
  const dashboardData = useMemo(() => [
    {
      id: 'business-insights',
      title: 'Business Insights',
      description: 'Complete overview of business metrics and performance insights',
      link: '/dashboards/business-insights',
      icon: FaLightbulb,
      color: COLORS.primary,
      keywords: ['business', 'overview', 'performance', 'metrics', 'insights']
    },
    {
      id: 'total-reviews',
      title: 'Review Performance',
      description: 'Track review volume and analyze rating trends over time',
      link: '/dashboards/total-reviews',
      icon: FaChartBar,
      color: COLORS.secondary,
      keywords: ['reviews', 'ratings', 'volume', 'trends', 'performance']
    },
    {
      id: 'gross-growth',
      title: 'Growth Acceleration',
      description: 'Monitor review growth rates and identify emerging opportunities',
      link: '/dashboards/gross-growth',
      icon: FaChartLine,
      color: COLORS.success,
      keywords: ['growth', 'acceleration', 'opportunities', 'rates', 'monitor']
    },
    {
      id: 'relative-growth',
      title: 'Comparative Metrics',
      description: 'Compare relative growth percentages between businesses',
      link: '/dashboards/relative-growth',
      icon: FaChartPie,
      color: COLORS.info,
      keywords: ['comparative', 'comparison', 'relative', 'growth', 'percentages']
    },
    {
      id: 'sentiment',
      title: 'Sentiment Analysis',
      description: 'Analyze customer sentiment and feedback trends',
      link: '/dashboards/sentiment',
      icon: FaSmile,
      color: COLORS.primary,
      keywords: ['sentiment', 'feedback', 'analysis', 'customer', 'trends']
    },
    {
      id: 'web-traffic',
      title: 'Digital Traffic',
      description: 'Measure online visibility and web traffic performance',
      link: '/dashboards/web-traffic',
      icon: FaBuilding,
      color: COLORS.secondary,
      keywords: ['digital', 'traffic', 'online', 'visibility', 'web', 'performance']
    }
  ], [COLORS]);

  // Initialize filteredDashboards with all dashboards
  useEffect(() => {
    setFilteredDashboards(dashboardData);
  }, [dashboardData]);
  
  // Filter dashboards based on search query
  useEffect(() => {
    if (!searchQuery.trim()) {
      // If search is empty, show all dashboards
      setFilteredDashboards(dashboardData);
      return;
    }
    
    const query = searchQuery.toLowerCase().trim();
    
    // Filter dashboards based on title, description, and keywords
    const filtered = dashboardData.filter(dashboard => {
      return (
        dashboard.title.toLowerCase().includes(query) ||
        dashboard.description.toLowerCase().includes(query) ||
        dashboard.keywords.some(keyword => keyword.toLowerCase().includes(query))
      );
    });
    
    setFilteredDashboards(filtered);
  }, [searchQuery, dashboardData]);

  // Load data
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        logger.log('Loading dashboard data...✨');
        const [reviewsResponse, yearResponse] = await Promise.all([
          getReviews(),
          getYearData()
        ]);
        setReviewsData(reviewsResponse);
        setYearData(yearResponse);
      } catch (error) {
        logger.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  // Calculate key metrics
  const calculateMetrics = () => {
    if (!reviewsData.length) return null;

    // Sort businesses by total reviews
    const sortedByTotal = [...reviewsData].sort((a, b) => b.rating_count - a.rating_count);
    const totalReviews = sortedByTotal.reduce((sum, b) => sum + b.rating_count, 0);
    const avgRating = sortedByTotal.reduce((sum, b) => sum + b.rating_avg, 0) / sortedByTotal.length;
    
    // Calculate market activity
    const allMonthlyData = {};
    
    // Process business data to collect monthly review activity
    reviewsData.forEach(business => {
      const yearInfo = yearData.find(yd => yd.maps_uri === business.maps_uri);
      const monthlyData = yearInfo?.data?.monthlyReviewCounts || {};
      const months = Object.keys(monthlyData).sort();
      
      months.forEach(month => {
        allMonthlyData[month] = (allMonthlyData[month] || 0) + (monthlyData[month] || 0);
      });
    });

    // Market activity trends
    const monthlyTrends = Object.entries(allMonthlyData)
      .sort((a, b) => new Date(a[0]) - new Date(b[0]))
      .map(([month, count]) => ({
        month: new Date(month).toLocaleDateString('en-US', { month: 'short' }),
        count,
        date: month
      }));

    // Calculate market momentum (last 3 months vs previous 3 months)
    const last6Months = monthlyTrends.slice(-6);
    const last3MonthsActivity = last6Months.slice(-3).reduce((sum, m) => sum + m.count, 0);
    const previous3MonthsActivity = last6Months.slice(0, 3).reduce((sum, m) => sum + m.count, 0);
    const marketMomentum = previous3MonthsActivity > 0 ? 
      ((last3MonthsActivity - previous3MonthsActivity) / previous3MonthsActivity * 100) : 0;

    // Calculate market competition (standard deviation of review counts)
    const avgReviews = totalReviews / reviewsData.length;
    const variance = reviewsData.reduce((sum, b) => sum + Math.pow(b.rating_count - avgReviews, 2), 0) / reviewsData.length;
    const competitionScore = Math.sqrt(variance) / avgReviews * 100;

    // Sentiment analysis
    const sentimentData = yearData.map(business => {
      const sentimentBreakdown = business.data?.sentimentBreakdown || {
        positive: 0,
        neutral: 0,
        negative: 0
      };
      const total = sentimentBreakdown.positive + sentimentBreakdown.neutral + sentimentBreakdown.negative;
      return {
        name: business.name,
        score: total > 0 ? ((sentimentBreakdown.positive + (sentimentBreakdown.neutral * 0.5)) / total * 100) : 0,
        total,
        positive: sentimentBreakdown.positive,
        neutral: sentimentBreakdown.neutral,
        negative: sentimentBreakdown.negative
      };
    }).sort((a, b) => b.score - a.score);

    const avgSentiment = sentimentData.reduce((sum, business) => sum + business.score, 0) / sentimentData.length;

    // Market leaders
    const marketLeaders = sortedByTotal.slice(0, 10).map(business => ({
      name: business.name,
      reviews: business.rating_count,
      rating: business.rating_avg,
      share: (business.rating_count / totalReviews * 100).toFixed(1)
    }));

    // Calculate type distribution
    const allTypes = new Set();
    const typeCount = {};
    reviewsData.forEach(business => {
      (business.types || []).forEach(type => {
        if (!type.includes('point_of_interest') && !type.includes('establishment') && !type.includes('food')) {
          allTypes.add(type);
          typeCount[type] = (typeCount[type] || 0) + 1;
        }
      });
    });
    
    const typeDistribution = Object.entries(typeCount)
      .map(([type, count]) => ({
        name: type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        value: (count / reviewsData.length * 100).toFixed(1),
        count
      }))
      .sort((a, b) => b.count - a.count);

    // Calculate primary type distribution
    const primaryTypes = {};
    reviewsData.forEach(business => {
      const type = business.primary_type || 'other';
      primaryTypes[type] = (primaryTypes[type] || 0) + 1;
    });
    
    const primaryTypeDistribution = Object.entries(primaryTypes)
      .map(([type, count]) => ({
        name: type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        value: (count / reviewsData.length * 100).toFixed(1),
        count
      }))
      .sort((a, b) => parseFloat(b.value) - parseFloat(a.value));

    // Prepare visualization data
    const activityTrendData = monthlyTrends.slice(-6);
    const competitorData = sortedByTotal.map(business => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      reviews: business.rating_count,
      rating: business.rating_avg.toFixed(1)
    }));

    const sentimentOverview = {
      positive: sentimentData.reduce((sum, b) => sum + b.positive, 0),
      neutral: sentimentData.reduce((sum, b) => sum + b.neutral, 0),
      negative: sentimentData.reduce((sum, b) => sum + b.negative, 0)
    };
    const totalSentiment = sentimentOverview.positive + sentimentOverview.neutral + sentimentOverview.negative;
    const sentimentDistribution = {
      positive: ((sentimentOverview.positive / totalSentiment) * 100).toFixed(1),
      neutral: ((sentimentOverview.neutral / totalSentiment) * 100).toFixed(1),
      negative: ((sentimentOverview.negative / totalSentiment) * 100).toFixed(1)
    };

    // Calculate recent trends (last month vs previous month)
    const recentTrends = reviewsData.map(business => {
      const yearInfo = yearData.find(yd => yd.maps_uri === business.maps_uri);
      const monthlyData = yearInfo?.data?.monthlyReviewCounts || {};
      const months = Object.keys(monthlyData).sort();
      const lastMonth = monthlyData[months[months.length - 1]] || 0;
      const previousMonth = monthlyData[months[months.length - 2]] || 0;
      const trend = previousMonth > 0 ? ((lastMonth - previousMonth) / previousMonth * 100) : 0;
      
      return {
        name: business.name,
        trend,
        lastMonth,
        previousMonth
      };
    }).sort((a, b) => b.trend - a.trend);

    return {
      totalReviews,
      avgRating,
      marketMomentum,
      competitionScore,
      avgSentiment,
      marketLeaders,
      activityTrendData,
      competitorData,
      sentimentDistribution,
      businessCount: reviewsData.length,
      typeDistribution: typeDistribution.slice(0, 10),
      primaryTypeDistribution,
      recentTrends: recentTrends.slice(0, 8),
      sentimentData: sentimentData.slice(0, 10)
    };
  };

  const metrics = calculateMetrics();

  if (isLoading || !metrics) {
    return (
      <AnimatedBox
        animation="fadeIn"
        duration={0.5}
        as={Flex}
        justify="center" 
        align="center" 
        h="400px"
      >
        <VStack>
          <Heading>Loading dashboard...</Heading>
          <Text>Preparing insights for your business market</Text>
        </VStack>
      </AnimatedBox>
    );
  }

  // Dashboard card component
  const DashboardCard = ({ title, icon, description, link, color, bgImage, index = 0 }) => (
    <AnimatedBox
      animation="slideUp"
      delay={0.1 + (index * 0.05)}
      duration={0.4}
      as={Card}
      bg={bgColor}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      overflow="hidden"
      boxShadow="sm"
      _hover={{
        transform: 'translateY(-4px)',
        shadow: 'md',
        borderColor: 'blue.400',
        bg: cardHoverBg
      }}
      transition="all 0.2s"
      cursor="pointer"
      onClick={() => navigate(link)}
      position="relative"
      h="100%"
    >
      {bgImage && (
        <Box
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          bgImage={bgImage}
          bgSize="cover"
          bgPosition="center"
          opacity={0.07}
          pointerEvents="none"
        />
      )}
      <CardBody p={6}>
        <VStack align="start" spacing={4}>
          <AnimatedBox
            animation="scale"
            delay={0.2 + (index * 0.05)}
            duration={0.3}
          >
            <Icon as={icon} boxSize={8} color={color} />
          </AnimatedBox>
          <AnimatedBox
            animation="fadeIn"
            delay={0.3 + (index * 0.05)}
            duration={0.4}
          >
            <Heading size="md">{title}</Heading>
          </AnimatedBox>
          <Text color={subtitleColor} fontSize="sm" noOfLines={2}>{description}</Text>
        </VStack>
      </CardBody>
      <CardFooter pt={0} pb={4} px={6}>
        <AnimatedBox
          animation="fadeIn"
          delay={0.4 + (index * 0.05)}
          duration={0.5}
        >
          <Button 
            variant="ghost" 
            colorScheme="blue" 
            size="sm" 
            rightIcon={<Icon as={FaChartBar} />}
            _hover={{ transform: 'translateX(3px)' }}
            transition="all 0.2s"
          >
            View Dashboard
          </Button>
        </AnimatedBox>
      </CardFooter>
    </AnimatedBox>
  );

  // Stat card component
  const StatCard = ({ title, value, change, icon, description, color, index = 0 }) => (
    <AnimatedBox
      animation="fadeIn"
      delay={0.1 + (index * 0.07)}
      duration={0.5}
      as={Card}
      bg={bgColor} 
      borderWidth="1px" 
      borderColor={borderColor} 
      borderRadius="lg" 
      boxShadow="sm"
      overflow="hidden" 
      h="100%"
      transition="all 0.2s"
      _hover={{
        borderColor: 'blue.200',
        shadow: 'sm',
        transform: 'translateY(-2px)'
      }}
    >
      <CardBody p={5}>
        <HStack spacing={4} align="flex-start">
          <Flex
            w="40px"
            h="40px"
            bg={`${color}.100`}
            color={`${color}.500`}
            borderRadius="lg"
            justify="center"
            align="center"
            flexShrink={0}
          >
            <Icon as={icon} boxSize={5} />
          </Flex>
          <VStack align="start" spacing={0} flex="1">
            <Text fontSize="sm" color={subtitleColor}>{title}</Text>
            <HStack spacing={2} align="baseline">
              <Text fontSize="2xl" fontWeight="bold" lineHeight="1.2">{value}</Text>
              {change && (
                <Badge
                  colorScheme={change.value >= 0 ? 'green' : 'red'}
                  fontSize="xs"
                  borderRadius="full"
                  px={2}
                  py={0.5}
                  display="flex"
                  alignItems="center"
                >
                  <Box as="span" display="inline-flex" alignItems="center">
                    <Icon as={change.value >= 0 ? FaArrowUp : FaArrowDown} boxSize={2} />
                    <Box as="span" ml="2px">{Math.abs(change.value).toFixed(1)}%</Box>
                  </Box>
                </Badge>
              )}
            </HStack>
            {description && (
              <Text fontSize="xs" color={subtitleColor} mt={1}>
                {description}
              </Text>
            )}
          </VStack>
        </HStack>
      </CardBody>
    </AnimatedBox>
  );

  // Leader card component
  const LeaderCard = ({ rank, business, bgColor, borderColor }) => (
    <Card
      bg={bgColor}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      boxShadow="sm"
      overflow="hidden"
      transition="all 0.2s"
      _hover={{
        transform: 'translateY(-2px)',
        shadow: 'md',
        borderColor: 'blue.400'
      }}
      cursor="pointer"
      mb={2}
    >
      <CardBody py={3} px={4}>
        <Flex justify="space-between" align="center">
          <HStack spacing={3}>
            {rank <= 3 ? (
              <Avatar
                bg={rank === 1 ? 'yellow.400' : rank === 2 ? 'gray.300' : 'orange.300'}
                icon={<Icon as={FaCrown} fontSize="1.2rem" color="white" />}
                size="sm"
              />
            ) : (
              <Avatar
                bg="blue.100"
                size="sm"
                name={`#${rank}`}
                color="blue.600"
              />
            )}
            <Box>
              <Text fontWeight="medium" noOfLines={1}>{business.name}</Text>
              <HStack spacing={2} fontSize="xs" color={subtitleColor}>
                <Text>{business.reviews.toLocaleString()} reviews</Text>
                <Text>•</Text>
                <Box as="span" display="inline-flex" alignItems="center">
                  <Icon as={FaRegStar} color="yellow.400" />
                  <Box as="span" ml="2px">{business.rating}</Box>
                </Box>
              </HStack>
            </Box>
          </HStack>
          <Badge colorScheme="blue" px={2} py={1}>
            {business.share}%
          </Badge>
        </Flex>
      </CardBody>
    </Card>
  );

  // Render the homepage
  return (
    <Box maxW="1400px" mx="auto" py={4} px={2}>
      {/* Header Section */}
      <Box mb={8}>
        <Flex 
          direction={{ base: "column", md: "row" }} 
          justify="space-between" 
          align={{ base: "stretch", md: "flex-start" }} 
          wrap="wrap" 
          gap={4} 
          mb={6}
        >
          <AnimatedBox
            animation="slideUp"
            duration={0.6}
            flex="1"
            minW={{ base: "100%", md: "auto" }}
          >
            <Heading as="h1" size="xl" mb={1} color={textColor}>
              Market Intelligence Dashboard
            </Heading>
            <Text color={subtitleColor} fontSize="lg">
              Analyzing {metrics.businessCount} businesses in your local market
            </Text>
          </AnimatedBox>
          <AnimatedBox
            animation="fadeIn"
            delay={0.3}
            duration={0.5}
            w={{ base: "100%", md: "350px", lg: "420px" }}
            alignSelf={{ base: "flex-start", md: "center" }}
            mt={{ base: 2, md: 0 }}
          >
            <InputGroup size="md" w="100%">
              <InputLeftElement pointerEvents="none">
                <Icon as={FaSearch} color="gray.400" />
              </InputLeftElement>
              <Input
                placeholder="Search dashboards and insights..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                bg={searchBg}
                borderColor={searchBorder}
                _hover={{ borderColor: 'blue.400' }}
                _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)' }}
              />
            </InputGroup>
          </AnimatedBox>
        </Flex>
      </Box>

      {/* Data Visualization Section - MOVED TO TOP */}
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} mb={8}>
        {/* Market Activity Chart */}
        <AnimatedBox
          animation="fadeIn"
          delay={0.2}
          duration={0.7}
          as={Card}
          bg={bgColor} 
          borderWidth="1px" 
          borderColor={borderColor} 
          borderRadius="lg" 
          overflow="hidden" 
          boxShadow="sm"
          _hover={{
            shadow: 'md',
            borderColor: 'blue.200'
          }}
          transition="all 0.3s"
        >
          <CardHeader pb={0}>
            <Flex justify="space-between" align="center">
              <Heading size="md">Market Activity Trend</Heading>
              <Button
                size="sm"
                colorScheme="blue"
                variant="ghost"
                onClick={() => navigate('/dashboards/total-reviews')}
              >
                View Details
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <Box h="300px">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={metrics.activityTrendData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 5 }}
                >
                  <defs>
                    <linearGradient id="colorActivity" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#0066ff" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#0066ff" stopOpacity={0.1} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#eee" />
                  <XAxis 
                    dataKey="month" 
                    axisLine={false}
                    tickLine={false}
                  />
                  <YAxis 
                    axisLine={false}
                    tickLine={false}
                    tickCount={5}
                  />
                  <RechartsTooltip
                    formatter={(value) => [`${value.toLocaleString()} reviews`, 'Volume']}
                    labelFormatter={(label) => `${label}`}
                    contentStyle={{
                      backgroundColor: bgColor,
                      borderRadius: '8px',
                      border: `1px solid ${borderColor}`,
                      boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey="count"
                    stroke="#0066ff"
                    strokeWidth={2}
                    fillOpacity={1}
                    fill="url(#colorActivity)"
                    activeDot={{ r: 6 }}
                    isAnimationActive={true}
                    animationDuration={1000}
                    animationBegin={0}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
            <Text fontSize="sm" color={subtitleColor} mt={2}>
              {metrics.marketMomentum >= 0 ? "Growing" : "Declining"} market with {
                Math.abs(metrics.marketMomentum).toFixed(1)
              }% {metrics.marketMomentum >= 0 ? "increase" : "decrease"} in activity
            </Text>
          </CardBody>
        </AnimatedBox>

        {/* Sentiment Distribution */}
        <AnimatedBox
          animation="fadeIn"
          delay={0.4}
          duration={0.7}
          as={Card}
          bg={bgColor} 
          borderWidth="1px" 
          borderColor={borderColor} 
          borderRadius="lg" 
          overflow="hidden" 
          boxShadow="sm"
          _hover={{
            shadow: 'md',
            borderColor: 'blue.200'
          }}
          transition="all 0.3s"
        >
          <CardHeader pb={0}>
            <Flex justify="space-between" align="center">
              <Heading size="md">Customer Sentiment</Heading>
              <Button
                size="sm"
                colorScheme="blue"
                variant="ghost"
                onClick={() => navigate('/dashboards/sentiment')}
              >
                View Details
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex direction={{ base: "column", md: "row" }} h="300px">
              <Box w={{ base: "100%", md: "60%" }} h="100%" position="relative">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={[
                        { name: 'Positive 😊', value: parseFloat(metrics.sentimentDistribution.positive) },
                        { name: 'Neutral 😐', value: parseFloat(metrics.sentimentDistribution.neutral) },
                        { name: 'Negative 😞', value: parseFloat(metrics.sentimentDistribution.negative) }
                      ]}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={100}
                      fill="#8884d8"
                      paddingAngle={3}
                      dataKey="value"
                      label={false}
                      animationDuration={800}
                    >
                      <Cell fill="#68D391" />
                      <Cell fill="#F6E05E" />
                      <Cell fill="#FC8181" />
                    </Pie>
                    <RechartsTooltip 
                      formatter={(value) => `${value.toFixed(1)}%`}
                      labelFormatter={(name) => name}
                      contentStyle={{
                        backgroundColor: bgColor,
                        borderRadius: '8px',
                        border: `1px solid ${borderColor}`,
                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
                {/* Add center label as Chakra UI component */}
                <Box 
                  position="absolute" 
                  top="50%" 
                  left="50%" 
                  transform="translate(-50%, -50%)"
                  textAlign="center"
                  pointerEvents="none"
                >
                  <Text fontWeight="bold" fontSize="15px">Sentiment</Text>
                  <Text color={subtitleColor} fontSize="13px">{metrics.sentimentDistribution.positive}% Positive</Text>
                </Box>
              </Box>
              <VStack align="stretch" spacing={6} p={4} flex="1">
                <Text fontWeight="medium">Sentiment Breakdown</Text>
                <Box>
                  <HStack justify="space-between" mb={1}>
                    <Text fontSize="sm">Positive 😊</Text>
                    <Text fontSize="sm" fontWeight="bold" color="green.500">
                      {metrics.sentimentDistribution.positive}%
                    </Text>
                  </HStack>
                  <Progress value={parseFloat(metrics.sentimentDistribution.positive)} colorScheme="green" size="sm" mb={3} />
                  
                  <HStack justify="space-between" mb={1}>
                    <Text fontSize="sm">Neutral 😐</Text>
                    <Text fontSize="sm" fontWeight="bold" color="yellow.500">
                      {metrics.sentimentDistribution.neutral}%
                    </Text>
                  </HStack>
                  <Progress value={parseFloat(metrics.sentimentDistribution.neutral)} colorScheme="yellow" size="sm" mb={3} />
                  
                  <HStack justify="space-between" mb={1}>
                    <Text fontSize="sm">Negative 😞</Text>
                    <Text fontSize="sm" fontWeight="bold" color="red.500">
                      {metrics.sentimentDistribution.negative}%
                    </Text>
                  </HStack>
                  <Progress value={parseFloat(metrics.sentimentDistribution.negative)} colorScheme="red" size="sm" />
                </Box>
              </VStack>
            </Flex>
          </CardBody>
        </AnimatedBox>
      </SimpleGrid>

      {/* Key Metrics Section */}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4} mb={8}>
        <StatCard 
          title="Total Reviews"
          value={metrics.totalReviews.toLocaleString()}
          icon={FaChartBar}
          description={`Across ${metrics.businessCount} businesses`}
          color="blue"
          index={0}
        />
        <StatCard 
          title="Market Sentiment"
          value={`${metrics.sentimentDistribution.positive}%`}
          icon={FaSmile}
          description="Positive customer experiences"
          color="green"
          change={{ value: parseFloat(metrics.sentimentDistribution.positive) - 50 }}
          index={1}
        />
        <StatCard 
          title="Market Growth"
          value={`${metrics.marketMomentum >= 0 ? '+' : ''}${metrics.marketMomentum.toFixed(1)}%`}
          icon={FaChartLine}
          description="Quarter over quarter change"
          color="purple"
          change={{ value: metrics.marketMomentum }}
          index={2}
        />
        <StatCard 
          title="Average Rating"
          value={metrics.avgRating.toFixed(1)}
          icon={FaRegStar}
          description="Market satisfaction score"
          color="yellow"
          index={3}
        />
      </SimpleGrid>

      {/* Dashboard Cards */}
      {filteredDashboards.length > 0 ? (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6} mb={8}>
          {filteredDashboards.map((dashboard, index) => (
            <DashboardCard
              key={dashboard.id}
              title={dashboard.title}
              icon={dashboard.icon}
              description={dashboard.description}
              link={dashboard.link}
              color={dashboard.color}
              bgImage={dashboard.id === 'business-insights' ? "url('/output.png')" : undefined}
              index={index}
            />
          ))}
        </SimpleGrid>
      ) : (
        <AnimatedBox
          animation="fadeIn"
          duration={0.5}
          as={Box}
          textAlign="center" 
          py={12} 
          px={6}
        >
          <AnimatedBox
            animation="pulse"
            delay={0.2}
            duration={1.2}
            repeat={true}
          >
            <Icon as={FaSearch} boxSize={12} color="blue.500" mb={4} />
          </AnimatedBox>
          <AnimatedBox
            animation="slideUp"
            delay={0.3}
            duration={0.5}
          >
            <Heading as="h2" size="xl" mb={2}>
              No results found
            </Heading>
          </AnimatedBox>
          <AnimatedBox
            animation="fadeIn"
            delay={0.5}
            duration={0.6}
          >
            <Text color={subtitleColor}>
              Try different keywords or browse all dashboards by clearing your search.
            </Text>
          </AnimatedBox>
        </AnimatedBox>
      )}

      {/* Bottom Section */}
      <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={6}>
        {/* Market Leaders */}
        <AnimatedBox
          animation="fadeIn"
          delay={0.2}
          duration={0.6}
          as={Card}
          bg={bgColor} 
          borderWidth="1px" 
          borderColor={borderColor} 
          borderRadius="lg" 
          overflow="hidden" 
          boxShadow="sm"
          _hover={{
            shadow: 'md',
            borderColor: 'blue.200'
          }}
          transition="all 0.3s"
        >
          <CardHeader pb={0}>
            <Flex justify="space-between" align="center">
              <Box as="span" display="inline-flex" alignItems="center">
                <Icon as={FaTrophy} color="yellow.400" mr={2} />
                <Heading size="md">Market Leaders</Heading>
              </Box>
              <Button
                size="sm"
                colorScheme="blue"
                variant="ghost"
                onClick={() => navigate('/dashboards/total-reviews')}
              >
                Rankings
              </Button>
            </Flex>
          </CardHeader>
          <CardBody pt={4}>
            <VStack spacing={1} align="stretch">
              {metrics.marketLeaders.slice(0, 5).map((leader, index) => (
                <LeaderCard 
                  key={index} 
                  rank={index + 1} 
                  business={leader} 
                  bgColor={bgColor}
                  borderColor={borderColor}
                />
              ))}
            </VStack>
          </CardBody>
        </AnimatedBox>

        {/* Recent Trends */}
        <AnimatedBox
          animation="fadeIn"
          delay={0.3}
          duration={0.6}
          as={Card}
          bg={bgColor} 
          borderWidth="1px" 
          borderColor={borderColor} 
          borderRadius="lg" 
          overflow="hidden" 
          boxShadow="sm"
          _hover={{
            shadow: 'md',
            borderColor: 'blue.200'
          }}
          transition="all 0.3s"
        >
          <CardHeader pb={0}>
            <Flex justify="space-between" align="center">
              <Box as="span" display="inline-flex" alignItems="center">
                <Icon as={FaChartLine} color="purple.500" mr={2} />
                <Heading size="md">Growth Trends</Heading>
              </Box>
              <Button
                size="sm"
                colorScheme="blue"
                variant="ghost"
                onClick={() => navigate('/dashboards/gross-growth')}
              >
                Details
              </Button>
            </Flex>
          </CardHeader>
          <CardBody pt={4}>
            <VStack spacing={3} align="stretch">
              {metrics.recentTrends.slice(0, 5).map((trend, index) => (
                <Box key={index} borderBottomWidth={index < 4 ? "1px" : "0"} borderColor={borderColor} pb={2}>
                  <Flex justify="space-between" align="center">
                    <Text fontSize="sm" fontWeight="medium" noOfLines={1}>{trend.name}</Text>
                    <Badge
                      colorScheme={trend.trend >= 0 ? 'green' : 'red'}
                      variant="subtle"
                      px={2}
                      py={0.5}
                      borderRadius="full"
                      display="flex"
                      alignItems="center"
                    >
                      <Box as="span" display="inline-flex" alignItems="center">
                        <Icon as={trend.trend >= 0 ? FaArrowUp : FaArrowDown} boxSize={2.5} />
                        <Box as="span" ml="2px">{Math.abs(trend.trend).toFixed(1)}%</Box>
                      </Box>
                    </Badge>
                  </Flex>
                  <Text fontSize="xs" color={subtitleColor}>
                    {trend.lastMonth} reviews last month (vs {trend.previousMonth})
                  </Text>
                </Box>
              ))}
            </VStack>
          </CardBody>
        </AnimatedBox>

        {/* Business Categories */}
        <AnimatedBox
          animation="fadeIn"
          delay={0.4}
          duration={0.6}
          as={Card}
          bg={bgColor} 
          borderWidth="1px" 
          borderColor={borderColor} 
          borderRadius="lg" 
          overflow="hidden" 
          boxShadow="sm"
          _hover={{
            shadow: 'md',
            borderColor: 'blue.200'
          }}
          transition="all 0.3s"
        >
          <CardHeader pb={0}>
            <Flex justify="space-between" align="center">
              <Box as="span" display="inline-flex" alignItems="center">
                <Icon as={FaStoreAlt} color="blue.500" mr={2} />
                <Heading size="md">Business Types</Heading>
              </Box>
              <Button
                size="sm"
                colorScheme="blue"
                variant="ghost"
                onClick={() => navigate('/dashboards/business-insights')}
              >
                Explore
              </Button>
            </Flex>
          </CardHeader>
          <CardBody pt={4}>
            <VStack spacing={3} align="stretch">
              {metrics.primaryTypeDistribution.slice(0, 5).map((category, index) => (
                <Box key={index}>
                  <Flex justify="space-between" mb={1}>
                    <Text fontSize="sm" fontWeight="medium">{category.name}</Text>
                    <Text fontSize="sm" fontWeight="bold">{category.value}%</Text>
                  </Flex>
                  <Box
                    w="100%"
                    h="8px"
                    bg="gray.100"
                    borderRadius="full"
                    overflow="hidden"
                  >
                    <Box
                      w={`${category.value}%`}
                      h="100%"
                      bg={COLORS.charts[index % COLORS.charts.length]}
                      borderRadius="full"
                    />
                  </Box>
                </Box>
              ))}
            </VStack>
          </CardBody>
        </AnimatedBox>
      </SimpleGrid>
    </Box>
  );
};

export default HomePage;