import axios from 'axios';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { baseAxiosConfig } from '../utils/apiConfig';

const weekAxios = axios.create(baseAxiosConfig);

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export async function getWeekData() {
  try {
    const currentUser = await getCurrentUser();
    if (!currentUser) {
      throw new Error('No authenticated user');
    }

    const response = await weekAxios.get('/week-in-review', {
      params: {
        user: currentUser.email
      }
    });

    // console.log('getReviews response', response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
}

export default getWeekData;