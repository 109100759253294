import React from 'react';
import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Image,
  List,
  ListItem,
  ListIcon,
  Divider,
  Icon,
  Flex,
  Badge,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  FaChartBar,
  FaSearch,
  FaTable,
  FaMapMarkerAlt,
  FaChartLine,
  FaChartPie,
  FaSmile,
  FaGlobe,
  FaExclamationCircle,
  FaLightbulb,
  FaInfoCircle,
  FaTools,
  FaRegCalendarAlt,
  FaBalanceScale,
  FaChevronRight,
  FaRoad,
  FaCheck,
  FaChartArea,
} from 'react-icons/fa';

// Dashboard guide content
export const dashboardGuide = {
  title: 'Dashboard Navigation Guide',
  description: 'Learn how to navigate and use the various dashboards in Localytics.',
  sections: [
    {
      title: 'Business Insights Dashboard',
      icon: FaTools,
      content: 'The Business Insights Dashboard provides a complete overview of all businesses in your market. Here you can:',
      points: [
        'Search for specific businesses by name',
        'Filter businesses by type or category',
        'Sort businesses by various metrics (reviews, growth, rating)',
        'View detailed performance metrics for each business',
        'Compare business performance at a glance'
      ]
    },
    {
      title: 'Review Performance Tracker',
      icon: FaChartArea,
      content: 'Monitor review volume and analyze rating trends over time:',
      points: [
        'Track total review volume for all businesses',
        'Compare review growth trends over selected periods',
        'Identify businesses with increasing or decreasing reviews',
        'Analyze review distribution across different businesses'
      ]
    },
    {
      title: 'Growth Acceleration Monitor',
      icon: FaChartLine,
      content: 'Track review growth rates and identify emerging opportunities:',
      points: [
        'Visualize review growth rates as percentages and absolute numbers',
        'Identify businesses with accelerating or decelerating growth',
        'Compare growth patterns across time periods',
        'Spot emerging market trends and opportunities'
      ]
    },
    {
      title: 'Comparative Growth Metrics',
      icon: FaChartPie,
      content: 'Compare relative performance between businesses:',
      points: [
        'View side-by-side growth percentages for different businesses',
        'Analyze market share shifts over time',
        'Identify competitive positioning dynamics',
        'Understand relative performance within market segments'
      ]
    },
    {
      title: 'Sentiment Analysis',
      icon: FaSmile,
      content: 'Analyze customer sentiment and feedback patterns:',
      points: [
        'Track positive, neutral, and negative sentiment ratios',
        'Identify businesses with improving or declining sentiment',
        'Analyze sentiment trends over time',
        'Compare sentiment patterns across different businesses'
      ]
    },
    {
      title: 'Digital Traffic Analytics',
      icon: FaGlobe,
      content: 'Monitor online visibility and web performance:',
      points: [
        'Track website traffic and search visibility metrics',
        'Analyze digital presence indicators',
        'Compare online versus offline performance',
        'Identify digital marketing opportunities'
      ]
    }
  ]
};

// Analysis tools guide
export const analysisGuide = {
  title: 'Analysis Tools Guide',
  description: 'Learn how to use the powerful analysis tools to gain deeper insights.',
  sections: [
    {
      title: 'Year in Review Tool',
      icon: FaRegCalendarAlt,
      content: 'The Year in Review tool provides annual performance summaries with key metrics:',
      points: [
        'View a complete yearly performance summary',
        'Compare year-over-year growth metrics',
        'Analyze annual sentiment and feedback trends',
        'Identify seasonal patterns and long-term trends',
        'Generate annual performance reports'
      ]
    },
    {
      title: 'Compare Tool',
      icon: FaBalanceScale,
      content: 'The Compare tool allows for direct side-by-side business comparison:',
      points: [
        'Select multiple businesses for direct comparison',
        'Compare key metrics across different time periods',
        'Identify competitive strengths and weaknesses',
        'Analyze relative performance in specific metrics',
        'Export comparison data for presentations or reports'
      ]
    },
    {
      title: 'Search Functionality',
      icon: FaSearch,
      content: 'Use the search functionality to quickly find what you need:',
      points: [
        'Search for businesses by name or type',
        'Filter search results by various criteria',
        'Quickly access specific business data',
        'Search within tables and dashboards',
        'Save favorite searches for quick access'
      ]
    },
    {
      title: 'Data Export Options',
      icon: FaTable,
      content: 'Export data for external analysis or reporting:',
      points: [
        'Export data to CSV format for spreadsheet analysis',
        'Download charts and visualizations',
        'Generate PDF reports of dashboard views',
        'Schedule regular data exports',
        'Custom format exports for specific needs'
      ]
    }
  ]
};

// Data filtering guide
export const filteringGuide = {
  title: 'Data Filtering Guide',
  description: 'Learn how to filter and customize your data views.',
  sections: [
    {
      title: 'Date Range Selection',
      icon: FaRegCalendarAlt,
      content: 'Select specific time periods for focused analysis:',
      points: [
        'Use preset ranges (Last 7 days, Last 30 days, etc.)',
        'Create custom date ranges for specific periods',
        'Compare different time periods side by side',
        'Analyze seasonal trends with date filtering'
      ]
    },
    {
      title: 'Business Filtering',
      icon: FaMapMarkerAlt,
      content: 'Filter businesses by various criteria:',
      points: [
        'Filter by business name or keywords',
        'Filter by business type or category',
        'Filter by performance metrics (ratings, review count)',
        'Create custom filter combinations for specific analysis',
        'Save filter settings for future use'
      ]
    },
    {
      title: 'Sorting Options',
      icon: FaChartLine,
      content: 'Sort data to focus on what matters most:',
      points: [
        'Sort businesses by performance metrics',
        'Sort by growth or decline rates',
        'Sort by review volume or sentiment scores',
        'Use ascending or descending sorting',
        'Combine sorting with filters for powerful analysis'
      ]
    },
    {
      title: 'View Customization',
      icon: FaTools,
      content: 'Customize your dashboard views:',
      points: [
        'Show or hide specific metrics',
        'Adjust chart visualization settings',
        'Set default filtering and sorting preferences',
        'Create custom dashboard layouts',
        'Save view settings for quick access'
      ]
    }
  ]
};

// Chart guide
export const chartGuide = {
  title: 'Understanding Charts Guide',
  description: 'Learn how to interpret and interact with the various charts and visualizations.',
  sections: [
    {
      title: 'Line Charts',
      icon: FaChartLine,
      content: 'Line charts display trends over time:',
      points: [
        'Hover over lines to see specific data points',
        'Click on legend items to show/hide specific lines',
        'Identify trends, patterns, and anomalies',
        'Compare multiple metrics over time',
        'Analyze seasonal patterns and business cycles'
      ]
    },
    {
      title: 'Bar Charts',
      icon: FaChartBar,
      content: 'Bar charts compare values across different categories:',
      points: [
        'Compare values between different businesses',
        'Analyze distribution of metrics across categories',
        'Identify top and bottom performers',
        'View side-by-side comparisons of multiple metrics',
        'Sort bars for ranking or pattern identification'
      ]
    },
    {
      title: 'Pie Charts',
      icon: FaChartPie,
      content: 'Pie charts show proportion and distribution:',
      points: [
        'Understand market share distribution',
        'View sentiment breakdown proportions',
        'Analyze category distribution',
        'Identify dominant segments in your data',
        'Compare relative sizes of different segments'
      ]
    },
    {
      title: 'Data Tables',
      icon: FaTable,
      content: 'Data tables provide detailed information in a structured format:',
      points: [
        'Sort columns to organize data',
        'Filter data rows to focus on specific subsets',
        'Export table data for further analysis',
        'View detailed metrics for each business',
        'Compare multiple metrics across businesses'
      ]
    }
  ]
};

// Roadmap guide
export const roadmapGuide = {
  title: 'Platform Roadmap Guide',
  description: 'Learn about upcoming features and how to provide feedback.',
  sections: [
    {
      title: 'Feature Timeline',
      icon: FaRoad,
      content: 'Explore upcoming features and development timeline:',
      points: [
        'View features currently under development',
        'See upcoming features planned for near-future release',
        'Explore long-term vision and development direction',
        'Track development progress on specific features',
        'Understand release schedules and priorities'
      ]
    },
    {
      title: 'Feedback System',
      icon: FaLightbulb,
      content: 'Provide feedback and feature requests:',
      points: [
        'Submit ideas for new features',
        'Report issues or suggest improvements',
        'Vote on feature priorities',
        'Provide detailed feedback on existing features',
        'Participate in shaping the platform direction'
      ]
    },
    {
      title: 'Platform Updates',
      icon: FaInfoCircle,
      content: 'Stay informed about platform improvements:',
      points: [
        'View recent platform updates and changes',
        'Read about new feature releases',
        'Learn about bug fixes and performance improvements',
        'Understand how updates impact your workflow',
        'Access documentation for new features'
      ]
    }
  ]
};

// Helper component for section rendering
export const GuideSection = ({ section, colorScheme = 'blue' }) => {
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const subtitleColor = useColorModeValue('gray.600', 'gray.400');
  const sectionBg = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Box 
      mb={8} 
      p={5} 
      borderRadius="lg" 
      borderWidth="1px" 
      borderColor={borderColor}
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow="sm"
      transition="all 0.2s"
      _hover={{ transform: 'translateY(-2px)', boxShadow: 'md' }}
    >
      <Flex align="center" mb={4}>
        <Flex
          w="40px"
          h="40px"
          bg={`${colorScheme}.50`}
          color={`${colorScheme}.500`}
          borderRadius="lg"
          justify="center"
          align="center"
          mr={3}
          flexShrink={0}
        >
          <Icon as={section.icon} boxSize={5} />
        </Flex>
        <Heading size="md" color={textColor}>{section.title}</Heading>
      </Flex>
      
      <Text color={subtitleColor} mb={4}>{section.content}</Text>
      
      <List spacing={2}>
        {section.points.map((point, index) => (
          <ListItem key={index} display="flex" alignItems="flex-start">
            <ListIcon as={FaCheck} color={`${colorScheme}.500`} mt={1} />
            <Text color={textColor}>{point}</Text>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default function GuideContent({ guideType }) {
  // Select the appropriate guide based on the passed type
  let guide;
  let colorScheme = 'blue';
  
  switch(guideType) {
    case 'dashboards':
      guide = dashboardGuide;
      colorScheme = 'blue';
      break;
    case 'analysis':
      guide = analysisGuide;
      colorScheme = 'purple';
      break;
    case 'filtering':
      guide = filteringGuide;
      colorScheme = 'teal';
      break;
    case 'charts':
      guide = chartGuide;
      colorScheme = 'orange';
      break;
    case 'roadmap':
      guide = roadmapGuide;
      colorScheme = 'green';
      break;
    default:
      guide = dashboardGuide;
  }
  
  return (
    <Box p={5}>
      <Heading size="lg" mb={2} color={`${colorScheme}.500`}>{guide.title}</Heading>
      <Text mb={6}>{guide.description}</Text>
      
      <Divider mb={6} />
      
      {guide.sections.map((section, index) => (
        <GuideSection key={index} section={section} colorScheme={colorScheme} />
      ))}
    </Box>
  );
}