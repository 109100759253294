import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Flex, Grid, Heading, Text, Badge, Icon, Spinner, VStack, ButtonGroup, Button } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, LineChart, Line, Cell } from 'recharts';
import { StarIcon } from '@chakra-ui/icons';
import getWeekData from '../services/weekService.js';
import getYearData from '../services/yearService.js';
import { useNavigate, useLocation } from 'react-router-dom';

const BusinessComparisonDashboard = ({ initialView = 'weeks' }) => {
  const [allWeekData, setAllWeekData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDateRanges, setSelectedDateRanges] = useState([]);
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [businessColors, setBusinessColors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const currentView = location.pathname === '/compare-year' ? 'years' : 'weeks';

  const colors = ['#4299E1', '#48BB78', '#ED8936', '#9F7AEA', '#F56565', '#4FD1C5', '#ED64A6'];

  const handleToggleView = (view) => {
    if (view === 'years') {
      navigate('/compare-year');
    } else {
      navigate('/compare-week');
    }
  };

  const fetchData = useCallback(async () => {
    if (isLoading) {
      try {
        const data = currentView === 'years' ? await getYearData() : await getWeekData();
        setAllWeekData(data);
        
        if (currentView === 'years') {
          // Process year data
          const sortedData = data.map(business => ({
            ...business,
            data: {
              ...business.data,
              dailyRatings: [], // Empty array since we don't have daily data for year view
              totalReviews: business.data.totalReviews,
              averageRating: business.data.averageRating,
              // Get the most recent month's reviews as comparison
              comparisonToPrevious: Object.entries(business.data.monthlyReviewCounts || {})
                .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                [0]?.[1] || 0,
              monthlyReviewCounts: business.data.monthlyReviewCounts || {},
            }
          })).sort((a, b) => b.data.totalReviews - a.data.totalReviews);

          setBusinessData(sortedData);
          
          // Assign colors to businesses
          const allBusinesses = new Set(sortedData.map(business => business.name));
          const newBusinessColors = {};
          Array.from(allBusinesses).forEach((business, index) => {
            newBusinessColors[business] = colors[index % colors.length];
          });
          setBusinessColors(newBusinessColors);

          setSelectedBusinesses(sortedData.slice(0, 3).map(business => business.name));
          setSelectedDateRanges([]); // Clear date ranges for year view
          setAvailableWeeks([]); // Clear available weeks for year view
        } else {
          // Process week data
          const sortedWeeks = data.sort((a, b) => new Date(b.sunday.replace(/-/g, "/")) - new Date(a.sunday.replace(/-/g, "/")));
          
          const dateRangeOptions = sortedWeeks.map((week, index) => ({
            value: index,
            label: `${new Date(week.sunday.replace(/-/g, "/")).toLocaleDateString()} - ${new Date(week.sunday.replace(/-/g, "/")).toLocaleDateString()}`,
            weeks: [week]
          }));

          setAvailableWeeks(dateRangeOptions);
          const defaultSelection = [dateRangeOptions[0]];
          setSelectedDateRanges(defaultSelection);
          
          const sortedData = aggregateBusinessData(defaultSelection[0].weeks);
          setBusinessData(sortedData);
          
          const allBusinesses = new Set(sortedData.map(business => business.name));
          const newBusinessColors = {};
          Array.from(allBusinesses).forEach((business, index) => {
            newBusinessColors[business] = colors[index % colors.length];
          });
          setBusinessColors(newBusinessColors);
          
          setSelectedBusinesses(sortedData.slice(0, 3).map(business => business.name));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentView, colors, isLoading]);

  // Single effect to handle data fetching
  useEffect(() => {
    setIsLoading(true);
  }, [currentView]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const aggregateBusinessData = useCallback((weeks) => {
    const aggregatedData = {};
    weeks.forEach(week => {
      week.data.forEach(business => {
        if (!aggregatedData[business.name]) {
          aggregatedData[business.name] = { ...business, data: { ...business.data } };
        } else {
          const existing = aggregatedData[business.name].data;
          const current = business.data;
          existing.totalReviews += current.totalReviews;
          existing.averageRating = (existing.averageRating * existing.totalReviews + current.averageRating * current.totalReviews) / (existing.totalReviews + current.totalReviews);
          existing.comparisonToPrevious += current.comparisonToPrevious;
          existing.sentimentBreakdown.positive += current.sentimentBreakdown.positive;
          existing.sentimentBreakdown.neutral += current.sentimentBreakdown.neutral;
          existing.sentimentBreakdown.negative += current.sentimentBreakdown.negative;
          existing.commonKeywords = [...new Set([...existing.commonKeywords, ...current.commonKeywords])];
          existing.dailyRatings = [...existing.dailyRatings, ...current.dailyRatings];
        }
      });
    });
    return Object.values(aggregatedData).sort((a, b) => b.data.totalReviews - a.data.totalReviews);
  }, []);

  useEffect(() => {
    if (selectedDateRanges && selectedDateRanges.length > 0 && selectedDateRanges[0]?.weeks) {
      const allSelectedWeeks = selectedDateRanges.flatMap(range => range?.weeks || []);
      if (allSelectedWeeks.length > 0) {
        const sortedData = aggregateBusinessData(allSelectedWeeks);
        setBusinessData(sortedData);
        setSelectedBusinesses(prevSelected => {
          const existingBusinesses = prevSelected.filter(name => 
            sortedData.some(business => business.name === name)
          );
          return existingBusinesses.length > 0 ? existingBusinesses : sortedData.slice(0, 3).map(business => business.name);
        });
      }
    }
  }, [selectedDateRanges, aggregateBusinessData]);

  const selectOptions = useMemo(() => {
    // Create a map to deduplicate and keep the business with the most reviews for each name
    const businessMap = new Map();

    businessData.forEach(business => {
      const name = business.name;
      if (!businessMap.has(name) || business.data.totalReviews > businessMap.get(name).data.totalReviews) {
        businessMap.set(name, business);
      }
    });

    // Convert map to array and sort by reviews
    return Array.from(businessMap.values())
      .sort((a, b) => b.data.totalReviews - a.data.totalReviews)
      .map(business => ({
        value: business.name,
        label: `${business.name} (${business.data.totalReviews || 0} reviews)`
      }));
  }, [businessData]);

  const handleBusinessSelection = (selectedOptions) => {
    setSelectedBusinesses(selectedOptions.map(option => option.value));
  };

  const handleDateRangeChange = (selectedOptions) => {
    setSelectedDateRanges(selectedOptions);
  };

  const selectedBusinessesData = useMemo(() => 
    businessData.filter(business => selectedBusinesses.includes(business.name)),
  [businessData, selectedBusinesses]);

  const prepareBarData = useCallback((key) => {
    return selectedBusinessesData.map(business => ({
      name: business.name,
      value: business.data[key]
    }));
  }, [selectedBusinessesData]);

  const compareDailyData = useMemo(() => {
    if (currentView === 'years') {
      // Get all months from all businesses
      const allMonths = new Set();
      selectedBusinessesData.forEach(business => {
        Object.keys(business.data.monthlyReviewCounts || {}).forEach(month => {
          allMonths.add(month);
        });
      });

      // Sort months chronologically
      const sortedMonths = Array.from(allMonths).sort((a, b) => new Date(a) - new Date(b));

      // Create data points for each month
      return sortedMonths.map(month => {
        const dataPoint = { date: month };
        selectedBusinessesData.forEach(business => {
          dataPoint[business.name] = business.data.monthlyReviewCounts?.[month] || 0;
        });
        return dataPoint;
      });
    } else {
      // Existing daily data logic for week view
      const allDates = new Set();
      selectedBusinessesData.forEach(business => {
        business.data.dailyRatings.forEach(day => {
          allDates.add(day.date);
        });
      });

      const sortedDates = Array.from(allDates).sort((a, b) => new Date(a.replace(/-/g, "/")) - new Date(b.replace(/-/g, "/")));

      return sortedDates.map(date => {
        const dataPoint = { date };
        selectedBusinessesData.forEach(business => {
          const dayData = business.data.dailyRatings.find(d => d.date === date);
          dataPoint[business.name] = dayData ? dayData.negative + dayData.positive + dayData.neutral : 0;
        });
        return dataPoint;
      });
    }
  }, [selectedBusinessesData, currentView]);

  const formatXAxis = (dateString) => {
    if (currentView === 'years') {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    }
    const date = new Date(dateString.replace(/-/g, "/"));
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const getColor = (businessName) => {
    return businessColors[businessName] || colors[0];
  };

  const renderBarChart = (data, yAxisDomain = [0, 'auto']) => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} layout="vertical">
        <XAxis type="number" domain={yAxisDomain} />
        <YAxis type="category" dataKey="name" width={150} />
        <Tooltip />
        <Bar dataKey="value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getColor(entry.name)} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );

  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <VStack spacing={4}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          <Text fontSize="xl" fontWeight="bold">Loading Comparison Data...</Text>
        </VStack>
      </Flex>
    );
  }

  // Add check for no data but still render the basic dashboard
  if (!businessData.length) {
    return (
      <Box maxWidth="6xl" margin="auto" padding={4}>
        <Flex justifyContent="space-between" alignItems="center" marginBottom={4}>
          <Heading as="h1" size="xl">Compare Reviews</Heading>
          <ButtonGroup isAttached variant="outline">
            <Button onClick={() => handleToggleView('weeks')}>Week</Button>
            <Button isActive colorScheme="blue">Year</Button>
          </ButtonGroup>
        </Flex>
        <Text>No weekly comparison data available</Text>
      </Box>
    );
  }

  return (
    <Box maxWidth="6xl" margin="auto" padding={4}>
      <Flex justifyContent="space-between" alignItems="center" marginBottom={4}>
        <Heading as="h1" size="xl">Compare Reviews</Heading>
        <ButtonGroup isAttached variant="outline">
          <Button 
            onClick={() => handleToggleView('years')}
            isActive={currentView === 'years'}
            colorScheme={currentView === 'years' ? "blue" : undefined}
          >
            Year
          </Button>
          <Button 
            onClick={() => handleToggleView('weeks')}
            isActive={currentView === 'weeks'}
            colorScheme={currentView === 'weeks' ? "blue" : undefined}
          >
            Week
          </Button>
        </ButtonGroup>
      </Flex>

      {currentView === 'weeks' && (
        <Box width="300px" mb={4}>
          <Select
            isMulti
            options={availableWeeks}
            onChange={handleDateRangeChange}
            value={selectedDateRanges}
            placeholder="Select date range..."
            isSearchable
            chakraStyles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                background: 'transparent',
                border: 'none',
                p: 0,
              }),
            }}
          />
        </Box>
      )}

      <Box marginBottom={4}>
        <Select
          isMulti
          name="businesses"
          options={selectOptions}
          placeholder="Select businesses to compare..."
          closeMenuOnSelect={false}
          onChange={handleBusinessSelection}
          value={selectOptions.filter(option => selectedBusinesses.includes(option.value))}
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              width: '100%',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              background: 'transparent',
              border: 'none',
              p: 0,
            }),
          }}
        />
      </Box>

      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4} marginY={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Average Rating</Text>
          {renderBarChart(prepareBarData('averageRating'), [0, 5])}
        </Box>

        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Total Reviews</Text>
          {renderBarChart(prepareBarData('totalReviews'))}
        </Box>

        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Comparison to Previous Period</Text>
          {renderBarChart(prepareBarData('comparisonToPrevious'))}
        </Box>
      </Grid>

      <Box borderWidth={1} borderRadius="lg" padding={4} marginY={4}>
        <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>
          {currentView === 'years' ? 'Reviews per Month' : 'Reviews per Day'}
        </Text>
        <Box height="300px">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={compareDailyData}>
              <XAxis 
                dataKey="date" 
                tickFormatter={formatXAxis}
                interval="preserveStartEnd"
                minTickGap={50}
              />
              <YAxis />
              <Tooltip 
                labelFormatter={formatXAxis}
                formatter={(value) => Math.round(value)}
              />
              <Legend />
              {selectedBusinessesData.map((business) => (
                <Line 
                  key={business.name}
                  type="monotone"
                  dataKey={business.name}
                  stroke={getColor(business.name)}
                  strokeWidth={2}
                  dot={currentView === 'years'}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>

      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4} marginY={4}>
        {selectedBusinessesData.map((business) => (
          <Box key={business.name} borderWidth={1} borderRadius="lg" padding={4}>
            <Heading as="h3" size="md" marginBottom={2}>{business.name}</Heading>
            <Text fontWeight="semibold" marginBottom={2}>Sentiment Breakdown</Text>
            <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Text>Positive</Text>
              <Badge colorScheme="green">{business.data.sentimentBreakdown.positive}</Badge>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Text>Neutral</Text>
              <Badge colorScheme="gray">{business.data.sentimentBreakdown.neutral}</Badge>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Text>Negative</Text>
              <Badge colorScheme="red">{business.data.sentimentBreakdown.negative}</Badge>
            </Flex>
            <Text fontWeight="semibold" marginTop={4} marginBottom={2}>Common Keywords</Text>
            <Flex flexWrap="wrap" gap={2}>
              {business.data.commonKeywords.map((keyword, index) => (
                <Badge key={index} variant="outline">{keyword}</Badge>
              ))}
            </Flex>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default BusinessComparisonDashboard;