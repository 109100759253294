import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CloseButton,
  Collapse,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
  Divider,
  Badge,
  useBreakpointValue,
  HStack,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import GuideContent from './GuideContent';
import {
  FaLightbulb,
  FaChartBar,
  FaChartLine,
  FaChartPie,
  FaSmile,
  FaGlobe,
  FaTools,
  FaRegCalendarAlt,
  FaBalanceScale,
  FaRoad,
  FaTimes,
  FaSearch,
  FaChevronRight,
  FaInfoCircle,
} from 'react-icons/fa';

// Define tips data for different categories
const tips = [
  {
    id: 1,
    title: 'Dashboard Navigation',
    icon: FaChartBar,
    color: 'blue',
    content: 'Use the sidebar to navigate between different dashboards. Each dashboard provides unique insights into your business data.',
    details: [
      'Business Insights: Complete overview of business metrics',
      'Review Performance: Analyze review volume and ratings',
      'Growth Acceleration: Track review volume growth over time',
      'Comparative Metrics: Compare relative growth between businesses',
      'Sentiment Analysis: Customer opinion and feedback analysis',
      'Digital Traffic: Web traffic and online visibility'
    ]
  },
  {
    id: 2,
    title: 'Analysis Tools',
    icon: FaTools,
    color: 'purple',
    content: 'Our analysis tools help you gain deeper insights into your business performance.',
    details: [
      'Year in Review: Annual performance summary with key metrics',
      'Week in Review: Weekly performance tracking and analysis',
      'Compare: Side-by-side business comparison for competitive analysis',
      'Search: Find specific businesses or review data quickly'
    ]
  },
  {
    id: 3,
    title: 'Data Filtering',
    icon: FaSearch,
    color: 'teal',
    content: 'Filter your data to focus on what matters most to your analysis.',
    details: [
      'Date Range: Select specific time periods for analysis',
      'Search: Find particular businesses in lists and tables',
      'Sorting: Arrange data by various metrics like rating or growth',
      'Categories: Filter businesses by their primary type'
    ]
  },
  {
    id: 4,
    title: 'Understanding Charts',
    icon: FaChartLine,
    color: 'orange',
    content: 'Interactive charts help visualize your business data for better understanding.',
    details: [
      'Hover over chart elements to see detailed information',
      'Click on legends to show/hide specific data series',
      'Use filters and date ranges to adjust chart data',
      'Export charts and data for presentations or reports'
    ]
  },
  {
    id: 5,
    title: 'Platform Roadmap',
    icon: FaRoad,
    color: 'green',
    content: 'Check out our roadmap to see upcoming features and provide feedback.',
    details: [
      'View upcoming feature releases and development timeline',
      'Provide feedback and feature requests',
      'Stay informed about platform improvements',
      'Participate in shaping the platform\'s future'
    ]
  }
];

// Main TipNotification component
const TipNotification = () => {
  const [activeTip, setActiveTip] = useState(null);
  const [showTip, setShowTip] = useState(false);
  const [dismissed, setDismissed] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  // Theme colors
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const subtleColor = useColorModeValue('gray.600', 'gray.400');
  const tabListBg = useColorModeValue('gray.50', 'gray.700');
  
  // Responsive size adjustment
  const tipWidth = useBreakpointValue({ base: '300px', md: '340px' });
  
  // Select a random tip based on user settings
  useEffect(() => {
    // Check if tips are enabled in user settings
    const tipsEnabled = localStorage.getItem('tipsEnabled') !== 'false';
    if (dismissed || !tipsEnabled) return;
    
    // Get tip frequency from user settings
    const tipFrequency = localStorage.getItem('tipFrequency') || 'medium';
    let intervalTime;
    
    switch (tipFrequency) {
      case 'low':
        intervalTime = 900000; // 15 minutes
        break;
      case 'high':
        intervalTime = 120000; // 2 minutes
        break;
      case 'medium':
      default:
        intervalTime = 300000; // 5 minutes
    }
    
    // Get random tip that's different from the current one
    const getRandomTip = () => {
      const availableTips = tips.filter(tip => tip !== activeTip);
      const randomIndex = Math.floor(Math.random() * availableTips.length);
      return availableTips[randomIndex];
    };
    
    // Show a tip periodically
    const showRandomTip = () => {
      setActiveTip(getRandomTip());
      setShowTip(true);
      
      // Auto-hide after 10 seconds if not interacted with
      const hideTimer = setTimeout(() => {
        if (showTip) setShowTip(false);
      }, 10000);
      
      return () => clearTimeout(hideTimer);
    };
    
    // Set initial tip after a delay
    const initialDelay = setTimeout(() => {
      showRandomTip();
    }, 30000); // Show first tip after 30 seconds
    
    // Set interval for subsequent tips based on user preference
    const interval = setInterval(showRandomTip, intervalTime);
    
    return () => {
      clearTimeout(initialDelay);
      clearInterval(interval);
    };
  }, [dismissed, activeTip]);
  
  // Handle dismissal
  const handleDismiss = () => {
    setShowTip(false);
    // Don't set dismissed to true here so tips can continue to show periodically
  };
  
  // Handle opening the detailed modal
  const handleOpenDetails = () => {
    onOpen();
    setShowTip(false);
  };
  
  // Get localStorage item to check if user has seen the welcome message
  useEffect(() => {
    const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');
    if (!hasSeenWelcome) {
      // Show welcome message on first visit
      setActiveTip({
        id: 0,
        title: 'Welcome to Localytics!',
        icon: FaLightbulb,
        color: 'blue',
        content: 'Click for a quick tour of the platform and to learn how to get the most out of your data.',
        details: [
          'Explore dashboards and analytics tools',
          'Learn how to filter and analyze your data',
          'Understand the different charts and visualizations',
          'Discover new features on our roadmap'
        ]
      });
      setShowTip(true);
      localStorage.setItem('hasSeenWelcome', 'true');
    }
  }, []);
  
  if (!activeTip) return null;
  
  return (
    <>
      <Portal>
        <Collapse in={showTip} animateOpacity>
          <Box
            position="fixed"
            bottom="20px"
            right="20px"
            width={tipWidth}
            bg={bgColor}
            borderRadius="lg"
            borderWidth="1px"
            borderColor={borderColor}
            boxShadow="lg"
            zIndex={1000}
            overflow="hidden"
            transition="all 0.3s ease-in-out"
            _hover={{ transform: 'translateY(-5px)', boxShadow: 'xl' }}
          >
            <Flex justify="space-between" align="center" bg={`${activeTip.color}.500`} p={3}>
              <HStack spacing={2}>
                <Icon as={activeTip.icon} color="white" boxSize={5} />
                <Text fontWeight="bold" color="white">{activeTip.title}</Text>
              </HStack>
              <CloseButton color="white" onClick={handleDismiss} size="sm" />
            </Flex>
            <Box p={4}>
              <Text color={textColor} mb={3}>{activeTip.content}</Text>
              <Button 
                rightIcon={<FaChevronRight />} 
                colorScheme={activeTip.color} 
                variant="outline" 
                size="sm" 
                onClick={handleOpenDetails}
                width="full"
              >
                Learn More
              </Button>
            </Box>
          </Box>
        </Collapse>
      </Portal>
      
      <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent maxH="90vh">
          <ModalHeader bg={`${activeTip.color}.500`} color="white">
            <HStack>
              <Icon as={activeTip.icon} mr={2} />
              <Text>{activeTip.title}</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton color="white" />
          
          {activeTip.id === 0 ? (
            // Welcome guide with tabs for each section
            <ModalBody p={0}>
              <Tabs colorScheme="blue" size="md" variant="enclosed">
                <TabList p={2} bg={tabListBg}>
                  <Tab>Overview</Tab>
                  <Tab>Dashboards</Tab>
                  <Tab>Analysis</Tab>
                  <Tab>Filtering</Tab>
                  <Tab>Charts</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Box p={4}>
                      <Heading size="md" mb={3} color={`${activeTip.color}.500`}>
                        Welcome to Localytics Platform
                      </Heading>
                      
                      <Text fontSize="md" mb={4}>
                        This platform helps you analyze business data, track reviews, and gain insights into market trends.
                        Explore our comprehensive dashboards and powerful analysis tools to make data-driven decisions.
                      </Text>
                      
                      <VStack align="stretch" spacing={6}>
                        <Box>
                          <Heading size="sm" mb={3}>Key Features</Heading>
                          <List spacing={3}>
                            {activeTip.details.map((detail, index) => (
                              <ListItem key={index} display="flex">
                                <ListIcon as={FaInfoCircle} color={`${activeTip.color}.500`} mt={1} />
                                <Text>{detail}</Text>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                        
                        <Box p={4} bg="blue.50" borderRadius="md">
                          <Heading size="sm" mb={2}>Pro Tip</Heading>
                          <Text>These tips will appear periodically to help you use the platform effectively. You can dismiss them or click for more information. Use the tabs above to explore different aspects of the platform.</Text>
                        </Box>
                      </VStack>
                    </Box>
                  </TabPanel>
                  <TabPanel p={0}>
                    <GuideContent guideType="dashboards" />
                  </TabPanel>
                  <TabPanel p={0}>
                    <GuideContent guideType="analysis" />
                  </TabPanel>
                  <TabPanel p={0}>
                    <GuideContent guideType="filtering" />
                  </TabPanel>
                  <TabPanel p={0}>
                    <GuideContent guideType="charts" />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>
          ) : (
            // Regular guide content for specific tips
            <ModalBody p={0}>
              <Box p={4}>
                <Text fontSize="lg" mb={4}>{activeTip.content}</Text>
                
                <Divider my={4} />
                
                <VStack align="stretch" spacing={6}>
                  <Box>
                    <Heading size="md" mb={3} color={`${activeTip.color}.500`}>
                      Key Points
                    </Heading>
                    <List spacing={3}>
                      {activeTip.details.map((detail, index) => (
                        <ListItem key={index} display="flex">
                          <ListIcon as={FaInfoCircle} color={`${activeTip.color}.500`} mt={1} />
                          <Text>{detail}</Text>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </VStack>
              </Box>
              
              {/* Show full guide content based on tip type */}
              {activeTip.id === 1 && <GuideContent guideType="dashboards" />}
              {activeTip.id === 2 && <GuideContent guideType="analysis" />}
              {activeTip.id === 3 && <GuideContent guideType="filtering" />}
              {activeTip.id === 4 && <GuideContent guideType="charts" />}
              {activeTip.id === 5 && <GuideContent guideType="roadmap" />}
            </ModalBody>
          )}
          
          <ModalFooter>
            <Button colorScheme={activeTip.color} mr={3} onClick={onClose}>
              Got it
            </Button>
            {activeTip.id !== 0 && (
              <Button variant="ghost" onClick={() => setDismissed(true)}>
                Don't show tips
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TipNotification;