import axios from 'axios';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { baseAxiosConfig } from '../utils/apiConfig';
import moment from 'moment';
import { logger } from '../utils/logger';

const yearAxios = axios.create(baseAxiosConfig);

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export async function getYearData(startDate = null, endDate = null) {
  try {
    const currentUser = await getCurrentUser();
    if (!currentUser) {
      throw new Error('No authenticated user');
    }

    const params = {
      user: currentUser.email
    };

    // If we have date params, extract month and year
    if (startDate && endDate) {
      // For simplicity, we'll use the first day of the month from startDate
      // and the last day of the month from endDate
      const startMoment = moment(startDate);
      const endMoment = moment(endDate);
      
      // Add month and year parameters for filtering
      params.month = startMoment.month() + 1; // moment months are 0-indexed
      params.year = startMoment.year();
      
      logger.log(`Filtering year-in-review data for month=${params.month}, year=${params.year}`);
    }

    const response = await yearAxios.get('/year-in-review', {
      params
    });

    const data = response.data;
    
    // If date filtering is requested, process the data
    if (startDate && endDate) {
      // Filter the monthly data to only include dates within the range
      data.forEach(business => {
        if (business.data && business.data.monthlyReviewCounts) {
          // Filter monthly counts and ratings to match date range
          const filteredMonthlyReviewCounts = {};
          const filteredMonthlyRatings = {};
          
          Object.entries(business.data.monthlyReviewCounts).forEach(([month, count]) => {
            const monthDate = moment(month);
            if (monthDate.isBetween(startDate, endDate, 'day', '[]')) {
              filteredMonthlyReviewCounts[month] = count;
              if (business.data.monthlyRatings && business.data.monthlyRatings[month]) {
                filteredMonthlyRatings[month] = business.data.monthlyRatings[month];
              }
            }
          });
          
          // Update the business data with filtered results
          business.data.monthlyReviewCounts = filteredMonthlyReviewCounts;
          business.data.monthlyRatings = filteredMonthlyRatings;
          
          // Recalculate totals
          const totalReviews = Object.values(filteredMonthlyReviewCounts).reduce((sum, count) => sum + count, 0);
          business.data.totalReviews = totalReviews;
          
          // Recalculate sentiment breakdown
          if (business.data.monthlyRatings) {
            const sentimentBreakdown = {
              positive: 0,
              neutral: 0,
              negative: 0
            };
            
            Object.values(filteredMonthlyRatings).forEach(rating => {
              sentimentBreakdown.positive += rating.positive || 0;
              sentimentBreakdown.neutral += rating.neutral || 0;
              sentimentBreakdown.negative += rating.negative || 0;
            });
            
            business.data.sentimentBreakdown = sentimentBreakdown;
          }
        }
      });
    }

    // Deduplicate businesses by name before returning
    const uniqueData = [];
    const businessMap = new Map();
    
    // First pass: group businesses by name
    data.forEach(business => {
      const name = business.name;
      if (!businessMap.has(name)) {
        businessMap.set(name, []);
      }
      businessMap.get(name).push(business);
    });
    
    // Second pass: for each business name, keep the one with the most reviews
    businessMap.forEach((businesses, name) => {
      if (businesses.length === 1) {
        // Only one business with this name, add it directly
        uniqueData.push(businesses[0]);
      } else {
        // Multiple businesses with the same name, keep the one with the most reviews
        const bestBusiness = businesses.reduce((best, current) => {
          const bestReviews = best.data?.totalReviews || 0;
          const currentReviews = current.data?.totalReviews || 0;
          return currentReviews > bestReviews ? current : best;
        }, businesses[0]);
        
        uniqueData.push(bestBusiness);
      }
    });
    
    return uniqueData;
  } catch (error) {
    logger.error("Error fetching year data: ", error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
}

export default getYearData;