import React, { useState, useEffect, useCallback } from 'react';
import { Box, Flex, Grid, Heading, Text, Badge, Icon, Spinner, VStack, ButtonGroup, Button, HStack, useColorModeValue } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, LineChart, Line } from 'recharts';
import { StarIcon } from '@chakra-ui/icons';
import { TrendingUpIcon, TrendingDownIcon } from './icons';
import { combineWeekData, processWeekData } from './dataUtils';
import getWeekData from '../services/weekService.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

const WeeklyReviewDashboard = ({ selectedBusiness, isModal }) => {
  const [allWeekData, setAllWeekData] = useState([]);
  const [weekData, setWeekData] = useState([]);
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const hoverBgColor = useColorModeValue('gray.100', 'gray.700');

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getWeekData();
      setAllWeekData(data);
      
      // Sort weeks by date (assuming sunday is in 'YYYY-MM-DD' format)
      const sortedWeeks = data.sort((a, b) => new Date(b.sunday) - new Date(a.sunday));
      
      // Create date range options
      const dateRangeOptions = sortedWeeks.map((week, index) => {
        const endDate = new Date(week.sunday);
        const startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 6);
        return {
          value: index,
          label: `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`,
          weeks: [week]
        };
      });

      setAvailableWeeks(dateRangeOptions);
      
      // Set default selection to the most recent week
      const defaultSelection = dateRangeOptions[dateRangeOptions.findIndex(option => typeof option.value === 'number')];
      setSelectedDateRange(defaultSelection);
      
      const processedData = processWeekData(defaultSelection.weeks);
      setWeekData(processedData);
      
      // Set default to either the selected business or the first business
      if (selectedBusiness) {
        const selectedData = processedData.find(business => business.name === selectedBusiness.name);
        setSelectedDataPoint(selectedData || processedData[0]);
      } else {
        // Check URL for business name
        const params = new URLSearchParams(location.search);
        const businessFromUrl = params.get('business');
        if (businessFromUrl) {
          const selectedData = processedData.find(business => business.name === businessFromUrl);
          setSelectedDataPoint(selectedData || processedData[0]);
        } else {
          setSelectedDataPoint(processedData[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedBusiness, location.search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (selectedDateRange && allWeekData.length > 0) {
      const processedData = processWeekData(selectedDateRange.weeks);
      setWeekData(processedData);
      
      // Maintain the selected business when changing weeks
      const currentBusinessName = selectedBusiness ? selectedBusiness.name : 
                                selectedDataPoint ? selectedDataPoint.name : null;
      const updatedBusiness = currentBusinessName ? 
        processedData.find(business => business.name === currentBusinessName) : 
        processedData[0];
      setSelectedDataPoint(updatedBusiness);
    }
  }, [selectedDateRange, allWeekData, selectedBusiness]);

  const handleDateRangeChange = (selectedOption) => {
    setSelectedDateRange(selectedOption);
  };

  const handleSelectChange = (selectedOption) => {
    const selected = weekData.find(datapoint => datapoint.name === selectedOption.value);
    setSelectedDataPoint(selected);
    // Update URL with selected business
    const params = new URLSearchParams(location.search);
    params.set('business', selected.name);
    navigate({ search: params.toString() });
  };

  const handleToggleView = (view) => {
    if (view === 'year') {
      // Pass the selected business to year view
      const params = new URLSearchParams();
      if (selectedDataPoint) {
        params.set('business', selectedDataPoint.name);
      }
      navigate(`/year-in-review?${params.toString()}`);
    }
  };

  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <VStack spacing={4}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
          <Text fontSize="xl" fontWeight="bold">Loading Dashboard...</Text>
        </VStack>
      </Flex>
    );
  }

  if (!selectedDataPoint || !selectedDataPoint.data) {
    return (
      <Box maxWidth="4xl" margin="auto" padding={4}>
        {!isModal && (
          <Flex justifyContent="space-between" alignItems="center" marginBottom={4}>
            <Heading as="h1" size="xl">In Review</Heading>
            <ButtonGroup isAttached variant="outline">
              <Button onClick={() => navigate('/year-in-review')}>Year</Button>
              <Button isActive colorScheme="blue">Week</Button>
            </ButtonGroup>
          </Flex>
        )}
        <Text>No weekly data available</Text>
      </Box>
    );
  }

  const { data } = selectedDataPoint;

  // Create a unique list of businesses
  const uniqueBusinesses = Array.from(new Set(weekData.map(datapoint => datapoint.name)));
  const selectOptions = uniqueBusinesses.map(name => {
    const datapoint = weekData.find(d => d.name === name);
    return {
      value: name,
      label: `${name} (${datapoint.data?.totalReviews || 0} reviews)`,
    };
  });

  const sentimentBreakdown = data.sentimentBreakdown || { positive: 0, neutral: 0, negative: 0 };

  return (
    <Box maxWidth="4xl" margin="auto" padding={4}>
      {!isModal && (
        <>
          <HStack mb={4} spacing={2}>
            <Button
              size="sm"
              leftIcon={<Icon as={FaHome} />}
              variant="ghost"
              onClick={() => navigate('/home')}
              _hover={{ bg: hoverBgColor }}
            >
              Home
            </Button>
            <Text color="gray.500">/</Text>
            <Text>Week in Review</Text>
          </HStack>
          <Flex justifyContent="space-between" alignItems="center" marginBottom={4}>
            <Heading as="h1" size="xl">In Review</Heading>
            <ButtonGroup isAttached variant="outline">
              <Button onClick={() => navigate('/year-in-review')}>Year</Button>
              <Button isActive colorScheme="blue">Week</Button>
            </ButtonGroup>
          </Flex>
          
          <Box marginBottom={4}>
            <Select
              options={selectOptions}
              onChange={handleSelectChange}
              value={{ 
                value: selectedDataPoint.name, 
                label: `${selectedDataPoint.name} (${data.totalReviews || 0} reviews this period)`
              }}
              placeholder="Select a business..."
              isSearchable
              chakraStyles={{
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  background: 'transparent',
                  border: 'none',
                  p: 0,
                }),
              }}
            />
          </Box>
        </>
      )}
      
      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4} marginBottom={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg">Average Rating</Text>
          <Flex alignItems="center" fontSize="3xl" fontWeight="bold">
            {(data.averageRating || 0).toFixed(1)}
            <Icon as={StarIcon} color="yellow.400" marginLeft={2} />
          </Flex>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg">Total Reviews</Text>
          <Text fontSize="3xl" fontWeight="bold">{data.totalReviews || 0}</Text>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg">Comparison to Previous Period</Text>
          <Flex alignItems="center" fontSize="3xl" fontWeight="bold">
            {(data.comparisonToPrevious || 0) > 0 ? (
              <>
                <Icon as={TrendingUpIcon} color="green.500" marginRight={2} />
                +{(data.comparisonToPrevious || 0).toFixed(1)}
              </>
            ) : (
              <>
                <Icon as={TrendingDownIcon} color="red.500" marginRight={2} />
                {(data.comparisonToPrevious || 0).toFixed(1)}
              </>
            )}
          </Flex>
        </Box>
      </Grid>
      
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4} marginBottom={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Daily Ratings Breakdown</Text>
          <Box height="250px">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data.dailyRatings || []}>
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="positive" stackId="a" fill="#48BB78" />
                <Bar dataKey="neutral" stackId="a" fill="#ECC94B" />
                <Bar dataKey="negative" stackId="a" fill="#F56565" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Reviews per Day</Text>
          <Box height="250px">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data.dailyReviewCounts || []}>
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="count" stroke="#805AD5" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Grid>
      
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4} marginBottom={4}>
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Sentiment Breakdown</Text>
          <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
            <Text>Positive</Text>
            <Badge colorScheme="green">{sentimentBreakdown.positive || 0}</Badge>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" marginBottom={2}>
            <Text>Neutral</Text>
            <Badge colorScheme="gray">{sentimentBreakdown.neutral || 0}</Badge>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text>Negative</Text>
            <Badge colorScheme="red">{sentimentBreakdown.negative || 0}</Badge>
          </Flex>
        </Box>
        
        <Box borderWidth={1} borderRadius="lg" padding={4}>
          <Text fontWeight="semibold" fontSize="lg" marginBottom={2}>Common Keywords</Text>
          <Flex flexWrap="wrap" gap={2}>
            {Array.isArray(data.commonKeywords) && data.commonKeywords.length > 0 ? (
              data.commonKeywords.map((keyword, index) => (
                <Badge key={index} variant="outline">{keyword}</Badge>
              ))
            ) : (
              <Text>No keywords available</Text>
            )}
          </Flex>
        </Box>
      </Grid>
    </Box>
  );
};

export default WeeklyReviewDashboard;