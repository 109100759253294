import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
  useColorModeValue,
  useToast,
  VStack,
  Box,
} from '@chakra-ui/react';
import { FaCog, FaLightbulb, FaRegClock, FaDesktop, FaInfoCircle, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

const UserSettingsModal = ({ isOpen, onClose }) => {
  const [tipsEnabled, setTipsEnabled] = useState(true);
  const [tipFrequency, setTipFrequency] = useState('medium');
  const [welcomeMessageReset, setWelcomeMessageReset] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  // Theme colors
  const headerBg = useColorModeValue('gray.50', 'gray.700');
  
  // Load settings from localStorage on component mount
  useEffect(() => {
    const storedTipsEnabled = localStorage.getItem('tipsEnabled');
    if (storedTipsEnabled !== null) {
      setTipsEnabled(storedTipsEnabled === 'true');
    }
    
    const storedTipFrequency = localStorage.getItem('tipFrequency');
    if (storedTipFrequency) {
      setTipFrequency(storedTipFrequency);
    }
  }, [isOpen]); // Reload settings each time modal opens
  
  // Handle settings save
  const handleSave = () => {
    // Save settings to localStorage
    localStorage.setItem('tipsEnabled', tipsEnabled.toString());
    localStorage.setItem('tipFrequency', tipFrequency);
    
    // Handle welcome message reset if needed
    if (welcomeMessageReset) {
      localStorage.removeItem('hasSeenWelcome');
      setWelcomeMessageReset(false);
    }
    
    // Show toast notification
    toast({
      title: 'Settings saved',
      description: 'Your preferences have been updated.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    
    onClose();
  };
  
  // Handle logout
  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      
      // Show toast notification
      toast({
        title: 'Logged out',
        description: 'You have been successfully logged out.',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      
      onClose();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      
      toast({
        title: 'Logout failed',
        description: 'There was an error logging out. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg={headerBg} borderTopRadius="md">
          <HStack>
            <Icon as={FaCog} mr={2} />
            <Text>User Settings</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        
        <ModalBody p={6}>
          <VStack spacing={6} align="stretch">
            {/* Tip Settings Section */}
            <Box>
              <Heading size="sm" mb={4} display="flex" alignItems="center">
                <Icon as={FaLightbulb} color="yellow.400" mr={2} />
                Platform Tips
              </Heading>
              
              <VStack spacing={4} pl={2} align="stretch">
                <FormControl display="flex" alignItems="center" justifyContent="space-between">
                  <FormLabel htmlFor="tips-toggle" mb={0} cursor="pointer">
                    <HStack>
                      <Text>Show helpful tips</Text>
                      <Icon as={FaInfoCircle} color="gray.400" />
                    </HStack>
                  </FormLabel>
                  <Switch 
                    id="tips-toggle" 
                    isChecked={tipsEnabled}
                    onChange={() => setTipsEnabled(!tipsEnabled)}
                    colorScheme="blue"
                  />
                </FormControl>
                
                <FormControl>
                  <FormLabel htmlFor="tip-frequency" display="flex" alignItems="center">
                    <Icon as={FaRegClock} mr={2} color="gray.500" />
                    <Text>Tip frequency</Text>
                  </FormLabel>
                  <Select 
                    id="tip-frequency"
                    value={tipFrequency}
                    onChange={(e) => setTipFrequency(e.target.value)}
                    isDisabled={!tipsEnabled}
                  >
                    <option value="low">Low (15 minutes)</option>
                    <option value="medium">Medium (5 minutes)</option>
                    <option value="high">High (2 minutes)</option>
                  </Select>
                </FormControl>
                
                <FormControl display="flex" alignItems="center" justifyContent="space-between">
                  <FormLabel htmlFor="welcome-reset" mb={0} cursor="pointer">
                    <Text>Reset welcome guide</Text>
                  </FormLabel>
                  <Checkbox 
                    id="welcome-reset"
                    isChecked={welcomeMessageReset}
                    onChange={() => setWelcomeMessageReset(!welcomeMessageReset)}
                    colorScheme="blue"
                  />
                </FormControl>
              </VStack>
            </Box>
            
            <Divider />
            
            {/* Display Settings Section */}
            <Box>
              <Heading size="sm" mb={4} display="flex" alignItems="center">
                <Icon as={FaDesktop} color="blue.400" mr={2} />
                Display Settings
              </Heading>
              
              <Text fontSize="sm" color="gray.500" mb={4}>
                Additional display settings will be available in future updates.
              </Text>
            </Box>
            
            <Divider />
            
            {/* Account Section */}
            <Box>
              <Heading size="sm" mb={4} display="flex" alignItems="center">
                <Icon as={FaSignOutAlt} color="red.400" mr={2} />
                Account
              </Heading>
              
              <Button 
                colorScheme="red" 
                variant="outline" 
                leftIcon={<Icon as={FaSignOutAlt} />}
                size="md"
                width="full"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Box>
          </VStack>
        </ModalBody>
        
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save Changes
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserSettingsModal;