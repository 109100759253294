import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ChakraProvider, Box, Spinner, Flex, extendTheme } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import WeekReviewParentComponentV2 from './components/WeekReviewParentComponentV2';
import YearReviewParentComponent from './components/YearReviewParentComponent';
import CompareWeekV3 from './components/CompareWeekV3';
import DashboardWrapper from './components/dashboards/DashboardWrapper';
import HomePage from './components/HomePage';
import Login from './components/Login';
import ConsumerLanding from './components/ConsumerLanding';
import DeveloperRoadmap from './components/DeveloperRoadmap';
import TipNotification from './components/TipNotification';

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD9FZAwhdtUBMS7e2HLli92L59-9KFRIK0",
  authDomain: "sub40-411602.firebaseapp.com",
  projectId: "sub40-411602",
  storageBucket: "sub40-411602.appspot.com",
  messagingSenderId: "390182094470",
  appId: "1:390182094470:web:894433eb7c550d22423307"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const theme = extendTheme({
  colors: {
    brand: {
      50: '#e6f2ff',
      100: '#cce0ff',
      200: '#99c1ff',
      300: '#66a3ff',
      400: '#3385ff',
      500: '#0066ff', // Primary brand color
      600: '#0052cc',
      700: '#003d99',
      800: '#002966',
      900: '#001433',
    },
    insight: {
      positive: '#48BB78', // Green for positive trends
      neutral: '#ECC94B',  // Yellow for neutral
      negative: '#F56565', // Red for negative trends
      info: '#4299E1',     // Blue for informational
    }
  },
  // Animation configurations for consistent micro-animations
  transition: {
    standard: "all 0.3s ease",
    fast: "all 0.15s ease",
    slow: "all 0.5s ease",
    bounce: "all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
    elastic: "all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
  },
  animations: {
    fadeIn: {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    slideUp: {
      from: { opacity: 0, transform: "translateY(20px)" },
      to: { opacity: 1, transform: "translateY(0)" },
    },
    pulse: {
      "0%": { transform: "scale(1)" },
      "50%": { transform: "scale(1.05)" },
      "100%": { transform: "scale(1)" },
    },
    spin: {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(360deg)" },
    },
  },
  fonts: {
    heading: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",
    body: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "semibold",
        borderRadius: "md",
        transition: "all 0.2s",
      },
      variants: {
        solid: (props) => ({
          bg: props.colorScheme === "blue" ? "brand.500" : undefined,
          _hover: {
            bg: props.colorScheme === "blue" ? "brand.600" : undefined,
            transform: "translateY(-2px)",
            boxShadow: "md",
          },
        }),
        outline: (props) => ({
          borderColor: props.colorScheme === "blue" ? "brand.500" : undefined,
          color: props.colorScheme === "blue" ? "brand.500" : undefined,
          _hover: {
            bg: props.colorScheme === "blue" ? "brand.50" : undefined,
            transform: "translateY(-2px)",
            boxShadow: "sm",
          },
        }),
        ghost: (props) => ({
          _hover: {
            bg: props.colorScheme === "blue" ? "brand.50" : undefined,
            transform: "translateY(-1px)",
          },
        }),
      },
    },
    Card: {
      baseStyle: {
        container: {
          borderRadius: "lg",
          overflow: "hidden",
          boxShadow: "sm",
          transition: "all 0.2s",
          _hover: {
            boxShadow: "md",
          },
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          borderRadius: "md",
          _focus: {
            boxShadow: "0 0 0 1px var(--chakra-colors-blue-500)",
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: "gray.200",
            _hover: {
              borderColor: "blue.400",
            },
          },
        },
      },
    },
    Badge: {
      baseStyle: {
        borderRadius: "full",
        px: 2,
        py: 1,
        fontWeight: "medium",
      },
    },
    Progress: {
      baseStyle: {
        track: {
          borderRadius: "full",
        },
        filledTrack: {
          borderRadius: "full",
        },
      },
    },
    Tooltip: {
      baseStyle: {
        borderRadius: "md",
        bg: "gray.700",
        color: "white",
        px: 3,
        py: 2,
        fontWeight: "medium",
        boxShadow: "md",
        fontSize: "sm",
      },
    },
    // Customize tables
    Table: {
      variants: {
        simple: {
          th: {
            borderColor: "gray.200",
            fontSize: "sm",
            fontWeight: "semibold",
            letterSpacing: "wider",
          },
          td: {
            borderColor: "gray.100",
          },
        },
      },
    },
    // Custom component styles for enhanced UI
    Select: {
      baseStyle: {
        field: {
          borderRadius: "md",
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: "gray.200",
            _hover: {
              borderColor: "blue.400",
            },
            _focus: {
              borderColor: "blue.500",
              boxShadow: "0 0 0 1px var(--chakra-colors-blue-500)",
            },
          },
        },
      },
    },
  },
  styles: {
    global: {
      'html, body': {
        minHeight: '100vh',
        margin: 0,
        padding: 0,
        WebkitTapHighlightColor: 'transparent',
        backgroundColor: 'gray.50',
      },
      '*:focus': {
        outline: 'none',
      },
      // Add smooth transitions for hover effects
      'a, button, input, select': {
        transition: 'all 0.2s ease-in-out',
      },
      // Improve touch targets on mobile
      'button, a': {
        minHeight: '44px', // iOS minimum touch target size
        minWidth: '44px',
      },
      // Prevent text size adjustment on orientation change
      body: {
        WebkitTextSizeAdjust: '100%',
      }
    }
  }
});

function PrivateRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
}

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return null; // or a loading spinner
  }

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Box minH="100vh" bg="gray.50">
          <Routes>
            <Route 
              path="/" 
              element={
                user ? <Navigate to="/home" /> : <Navigate to="/landing" />
              } 
            />
            <Route path="/landing" element={<ConsumerLanding />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/*"
              element={
                user ? (
                  <PrivateRoute>
                    <Box>
                      <Navbar />
                      <Box 
                        as="main" 
                        ml={{ base: "72px", lg: "72px" }}
                        transition="margin-left 0.2s"
                        p={4}
                      >
                        <Routes>
                          <Route path="/home" element={<HomePage />} />
                          <Route 
                            path="/dashboards/total-reviews" 
                            element={<DashboardWrapper type="total-reviews" />} 
                          />
                          <Route 
                            path="/dashboards/gross-growth" 
                            element={<DashboardWrapper type="gross-growth" />} 
                          />
                          <Route 
                            path="/dashboards/relative-growth" 
                            element={<DashboardWrapper type="relative-growth" />} 
                          />
                          <Route 
                            path="/dashboards/sentiment" 
                            element={<DashboardWrapper type="sentiment" />} 
                          />
                          <Route 
                            path="/dashboards/web-traffic" 
                            element={<DashboardWrapper type="web-traffic" />} 
                          />
                          <Route 
                            path="/dashboards/business-insights" 
                            element={<DashboardWrapper type="business-insights" />} 
                          />
                          <Route 
                            path="/week-in-review" 
                            element={<WeekReviewParentComponentV2 />} 
                          />
                          <Route 
                            path="/year-in-review" 
                            element={<YearReviewParentComponent />} 
                          />
                          <Route 
                            path="/compare-week" 
                            element={<CompareWeekV3 initialView="weeks" />} 
                          />
                          <Route 
                            path="/compare-year" 
                            element={<CompareWeekV3 initialView="years" />} 
                          />
                          <Route 
                            path="/roadmap" 
                            element={<DeveloperRoadmap />} 
                          />
                        </Routes>
                      </Box>
                      <TipNotification />
                    </Box>
                  </PrivateRoute>
                ) : (
                  <Navigate to="/landing" />
                )
              }
            />
          </Routes>
        </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;