import { getAuth } from 'firebase/auth';

const RATE_LIMIT_WINDOW = 60 * 1000; // 1 minute
const MAX_REQUESTS = 5; // 5 attempts per minute
const STORAGE_PREFIX = 'rateLimit_';

// Check if localStorage is available
const isLocalStorageAvailable = () => {
  try {
    const test = 'test';
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch(e) {
    return false;
  }
};

class RateLimitService {
  constructor() {
    this.isAvailable = isLocalStorageAvailable();
    if (this.isAvailable) {
      try {
        this.cleanupOldEntries();
      } catch (error) {
        console.warn('Rate limit service initialization failed:', error);
        this.isAvailable = false;
      }
    }
  }

  getKey(action) {
    const ip = 'client'; // In a real app, you'd get this from your server
    return `${STORAGE_PREFIX}${action}_${ip}`;
  }

  cleanupOldEntries() {
    if (!this.isAvailable) return;
    try {
      const now = Date.now();
      const keys = Object.keys(localStorage || {});
      
      keys.forEach(key => {
        try {
          if (key.startsWith(STORAGE_PREFIX)) {
            let data;
            try {
              data = JSON.parse(localStorage.getItem(key) || '[]');
            } catch {
              data = [];
            }

            const filtered = Array.isArray(data) ? data.filter(
              timestamp => now - timestamp < RATE_LIMIT_WINDOW
            ) : [];
            
            if (filtered.length === 0) {
              localStorage.removeItem(key);
            } else {
              localStorage.setItem(key, JSON.stringify(filtered));
            }
          }
        } catch (error) {
          // If any single key fails, remove it and continue
          try {
            localStorage.removeItem(key);
          } catch {}
        }
      });
    } catch (error) {
      // If localStorage is completely unavailable
      console.warn('Rate limiting disabled:', error);
    }
  }

  isRateLimited(action) {
    if (!this.isAvailable) return false;
    try {
      const key = this.getKey(action);
      const now = Date.now();
      let timestamps = [];
      
      try {
        timestamps = JSON.parse(localStorage.getItem(key) || '[]');
      } catch {
        timestamps = [];
      }

      if (!Array.isArray(timestamps)) timestamps = [];
      
      const validTimestamps = timestamps.filter(
        time => now - time < RATE_LIMIT_WINDOW
      );
      
      if (validTimestamps.length >= MAX_REQUESTS) {
        return true;
      }
      
      validTimestamps.push(now);
      localStorage.setItem(key, JSON.stringify(validTimestamps));
      return false;
    } catch {
      // If rate limiting fails, allow the action
      return false;
    }
  }

  getRemainingAttempts(action) {
    if (!this.isAvailable) return MAX_REQUESTS;
    try {
      const key = this.getKey(action);
      let timestamps = [];
      
      try {
        timestamps = JSON.parse(localStorage.getItem(key) || '[]');
      } catch {
        timestamps = [];
      }

      if (!Array.isArray(timestamps)) timestamps = [];

      const now = Date.now();
      const validTimestamps = timestamps.filter(
        time => now - time < RATE_LIMIT_WINDOW
      );
      
      return Math.max(0, MAX_REQUESTS - validTimestamps.length);
    } catch {
      // If checking fails, return max attempts
      return MAX_REQUESTS;
    }
  }

  getTimeUntilReset(action) {
    if (!this.isAvailable) return 0;
    try {
      const key = this.getKey(action);
      let timestamps = [];
      
      try {
        timestamps = JSON.parse(localStorage.getItem(key) || '[]');
      } catch {
        return 0;
      }

      if (!Array.isArray(timestamps) || timestamps.length === 0) return 0;

      const oldestTimestamp = Math.min(...timestamps);
      return Math.max(0, RATE_LIMIT_WINDOW - (Date.now() - oldestTimestamp));
    } catch {
      return 0;
    }
  }
}

// Only create the service when the module is imported
let rateLimitService = null;

export const getRateLimitService = () => {
  if (!rateLimitService) {
    rateLimitService = new RateLimitService();
  }
  return rateLimitService;
}; 