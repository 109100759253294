import React, { useMemo } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Box,
  Flex,
  Icon,
  Link,
  Text,
  VStack,
  HStack,
  Tag,
  TagLabel,
  Wrap,
  WrapItem,
  SimpleGrid,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Card,
  CardBody,
  CardHeader,
  Badge,
  useColorModeValue,
  Avatar,
  Divider,
  Progress,
  Button,
} from '@chakra-ui/react';
import { StarIcon, ExternalLinkIcon, PhoneIcon } from '@chakra-ui/icons';
import { 
  FaMapMarkerAlt, 
  FaGlobe, 
  FaChartLine, 
  FaSmile, 
  FaMeh, 
  FaFrown, 
  FaRegStar,
  FaRegLightbulb,
  FaChartPie,
  FaComments,
  FaThumbsUp,
  FaThumbsDown
} from 'react-icons/fa';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer, 
  Legend, 
  LineChart, 
  Line,
  AreaChart,
  Area,
  Cell,
  PieChart,
  Pie
} from 'recharts';

// Emoji mapping for business types
const typeEmojis = {
  restaurant: '🍽️',
  wine_bar: '🍷',
  vegan_restaurant: '🌱',
  vegetarian_restaurant: '🥗',
  bar: '🍸',
  food: '🍴',
  cafe: '☕',
  market: '🏪',
  deli: '🥪',
  asian_restaurant: '🥢',
  thai_restaurant: '🇹🇭',
  indian_restaurant: '🍛',
  mediterranean_restaurant: '🫒',
  american_restaurant: '🍔',
  fine_dining_restaurant: '✨',
  brunch_restaurant: '🍳',
  middle_eastern_restaurant: '🧆',
  chinese_restaurant: '🥡',
  meal_takeaway: '📦',
  food_store: '🏪',
  catering_service: '👨‍🍳',
  food_delivery: '🚚',
};

const BusinessReviewModal = ({ isOpen, onClose, business, yearData }) => {
  // Theme colors - moved before any conditionals to fix React hooks rule violation
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const subtitleColor = useColorModeValue('gray.600', 'gray.400');
  const statBg = useColorModeValue('gray.50', 'gray.700');
  
  // Colors for charts and visualizations
  const COLORS = {
    primary: '#0066ff',
    secondary: '#4299E1',
    success: '#48BB78',
    warning: '#ECC94B',
    danger: '#F56565',
    info: '#9F7AEA',
    charts: [
      '#2F80ED', // bright blue
      '#27AE60', // emerald green
      '#9B51E0', // purple
      '#F2994A', // orange
      '#EB5757', // bright red
      '#219EBC', // teal
    ]
  };

  // Process business data for the modal safely, handling both the business object and its properties
  const historicalData = business?.historicalData || {};
  const sentimentBreakdown = historicalData.sentimentBreakdown || {
    positive: 0,
    neutral: 0,
    negative: 0
  };
  
  // Prepare sentiment data for pie chart - moved before conditional return to fix hooks rule
  const sentimentPieData = useMemo(() => {
    return [
      { name: 'Positive 😊', value: sentimentBreakdown.positive, fill: '#48BB78' },
      { name: 'Neutral 😐', value: sentimentBreakdown.neutral, fill: '#ECC94B' },
      { name: 'Negative 😞', value: sentimentBreakdown.negative, fill: '#E53E3E' }
    ];
  }, [sentimentBreakdown]);

  // Safe early return after all hooks
  if (!business) return null;
  
  // Add debugging logs
  console.log('Modal Business Data:', business);
  console.log('Historical Data:', business.historicalData);
  console.log('Year Data:', yearData);
  
  const totalSentiment = sentimentBreakdown.positive + sentimentBreakdown.neutral + sentimentBreakdown.negative;
  const positivePercentage = totalSentiment > 0 
    ? ((sentimentBreakdown.positive / totalSentiment) * 100).toFixed(1)
    : 0;
  
  const neutralPercentage = totalSentiment > 0
    ? ((sentimentBreakdown.neutral / totalSentiment) * 100).toFixed(1)
    : 0;
    
  const negativePercentage = totalSentiment > 0
    ? ((sentimentBreakdown.negative / totalSentiment) * 100).toFixed(1)
    : 0;
  
  // Prepare monthly data
  const monthlyData = Object.entries(historicalData.monthlyReviewCounts || {})
    .map(([month, count]) => ({
      month: new Date(month).toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
      count,
      ...historicalData.monthlyRatings?.[month],
      originalMonth: month
    }))
    .sort((a, b) => new Date(a.month) - new Date(b.month));

  // Calculate three month growth
  const months = Object.keys(historicalData.monthlyReviewCounts || {}).sort();
  const lastThreeMonths = months.slice(-3);
  const threeMonthGrowth = lastThreeMonths.reduce((sum, month) => 
    sum + (historicalData.monthlyReviewCounts?.[month] || 0), 0
  );
  const monthlyGrowthRate = threeMonthGrowth / 3; // Average monthly growth rate

  // Calculate comparison to previous period
  const previousThreeMonths = months.slice(-6, -3);
  const previousPeriodGrowth = previousThreeMonths.reduce((sum, month) => 
    sum + (historicalData.monthlyReviewCounts?.[month] || 0), 0
  );
  const growthComparison = threeMonthGrowth - previousPeriodGrowth;
  const growthPercentage = previousPeriodGrowth > 0 
    ? ((threeMonthGrowth - previousPeriodGrowth) / previousPeriodGrowth * 100).toFixed(1)
    : 0;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay 
        bg="blackAlpha.600" 
        backdropFilter="blur(5px)"
      />
      <ModalContent 
        maxW={{ base: "95vw", lg: "90vw" }}
        maxH="90vh" 
        h="90vh"
        mx="auto" 
        my="5vh"
        overflow="hidden"
        borderRadius="xl"
        boxShadow="xl"
        bg={cardBg}
      >
        <Box h="full" display="flex" flexDirection="column">
          <ModalHeader 
            borderBottom="1px" 
            borderColor={borderColor} 
            flex="0 0 auto"
            px={6}
            py={4}
            bg={cardBg}
          >
            <VStack align="stretch" spacing={4}>
              <Flex justify="space-between" align="center" wrap={{ base: "wrap", md: "nowrap" }}>
                <Heading
                  as="h2"
                  size="lg"
                  color={textColor}
                  lineHeight="1.3"
                  noOfLines={1}
                >
                  {business.name}
                </Heading>
                <HStack spacing={3} alignItems="center">
                  <Badge colorScheme="blue" px={2} py={1} borderRadius="md">
                    {business.types?.[0].split('_').map(word => 
                      word.charAt(0).toUpperCase() + word.slice(1)
                    ).join(' ')}
                  </Badge>
                  <HStack spacing={1} bg={statBg} px={3} py={1} borderRadius="md">
                    <Icon as={FaRegStar} color="yellow.400" boxSize={4} />
                    <Text fontSize="lg" fontWeight="bold" color={textColor}>
                      {business.rating_avg?.toFixed(1)}
                    </Text>
                    <Text fontSize="sm" color={subtitleColor}>
                      ({business.rating_count?.toLocaleString() || 0})
                    </Text>
                  </HStack>
                </HStack>
              </Flex>

              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                <VStack align="start" spacing={3}>
                  {business.address && (
                    <HStack spacing={2} alignItems="center">
                      <Icon as={FaMapMarkerAlt} color="red.500" boxSize={4} />
                      <Link href={business.maps_uri} isExternal color="blue.500" fontWeight="medium">
                        {business.address} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </HStack>
                  )}
                  {business.phone && (
                    <HStack spacing={2} alignItems="center">
                      <Icon as={PhoneIcon} color="green.500" boxSize={4} />
                      <Link href={`tel:${business.phone}`} color="blue.500" fontWeight="medium">
                        {business.phone}
                      </Link>
                    </HStack>
                  )}
                  {business.website_uri && (
                    <HStack spacing={2} alignItems="center">
                      <Icon as={FaGlobe} color="blue.500" boxSize={4} />
                      <Link href={business.website_uri} isExternal color="blue.500" fontWeight="medium">
                        Visit Website <ExternalLinkIcon mx="2px" />
                      </Link>
                    </HStack>
                  )}
                </VStack>

                <Box>
                  <Wrap spacing={2}>
                    {business.types?.map((type) => (
                      <WrapItem key={type}>
                        <Tag
                          size="md" 
                          borderRadius="full" 
                          variant="subtle" 
                          colorScheme="blue" 
                          boxShadow="sm"
                        >
                          <TagLabel>
                            {typeEmojis[type] || '🏢'} {type.split('_').map(word => 
                              word.charAt(0).toUpperCase() + word.slice(1)
                            ).join(' ')}
                          </TagLabel>
                        </Tag>
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box>
              </SimpleGrid>
            </VStack>
          </ModalHeader>
          <ModalCloseButton size="lg" color={subtitleColor} />
          
          <Box 
            flex="1 1 auto" 
            overflow="auto" 
            p={6}
          >
            {/* Key Metrics */}
            <SimpleGrid columns={{ base: 1, sm: 2, lg: 4 }} spacing={4} mb={8}>
              <Card bg={cardBg} borderWidth="1px" borderColor={borderColor} borderRadius="lg" boxShadow="sm">
                <CardBody p={4}>
                  <HStack spacing={4} align="flex-start">
                    <Flex
                      w="40px"
                      h="40px"
                      bg="blue.50"
                      color="blue.500"
                      borderRadius="lg"
                      justify="center"
                      align="center"
                      flexShrink={0}
                    >
                      <Icon as={FaChartLine} boxSize={5} />
                    </Flex>
                    <VStack align="start" spacing={0} flex="1">
                      <Text fontSize="sm" color={subtitleColor}>Monthly Growth</Text>
                      <HStack spacing={2} align="baseline">
                        <Text fontSize="2xl" fontWeight="bold" lineHeight="1.2">
                          {monthlyGrowthRate.toFixed(1)}
                        </Text>
                        <Badge
                          colorScheme={growthComparison >= 0 ? 'green' : 'red'}
                          fontSize="xs"
                          borderRadius="full"
                          px={2}
                          py={0.5}
                        >
                          <HStack spacing={0.5}>
                            <Icon as={growthComparison >= 0 ? FaThumbsUp : FaThumbsDown} boxSize={2} />
                            <Text>{growthPercentage}%</Text>
                          </HStack>
                        </Badge>
                      </HStack>
                      <Text fontSize="xs" color={subtitleColor} mt={1}>
                        Reviews per month average
                      </Text>
                    </VStack>
                  </HStack>
                </CardBody>
              </Card>
              
              <Card bg={cardBg} borderWidth="1px" borderColor={borderColor} borderRadius="lg" boxShadow="sm">
                <CardBody p={4}>
                  <HStack spacing={4} align="flex-start">
                    <Flex
                      w="40px"
                      h="40px"
                      bg="green.50"
                      color="green.500"
                      borderRadius="lg"
                      justify="center"
                      align="center"
                      flexShrink={0}
                    >
                      <Icon as={FaSmile} boxSize={5} />
                    </Flex>
                    <VStack align="start" spacing={0} flex="1">
                      <Text fontSize="sm" color={subtitleColor}>Sentiment Score</Text>
                      <Text fontSize="2xl" fontWeight="bold" lineHeight="1.2">{positivePercentage}%</Text>
                      <Text fontSize="xs" color={subtitleColor} mt={1}>
                        Positive customer sentiment
                      </Text>
                    </VStack>
                  </HStack>
                </CardBody>
              </Card>
              
              <Card bg={cardBg} borderWidth="1px" borderColor={borderColor} borderRadius="lg" boxShadow="sm">
                <CardBody p={4}>
                  <HStack spacing={4} align="flex-start">
                    <Flex
                      w="40px"
                      h="40px"
                      bg="purple.50"
                      color="purple.500"
                      borderRadius="lg"
                      justify="center"
                      align="center"
                      flexShrink={0}
                    >
                      <Icon as={FaComments} boxSize={5} />
                    </Flex>
                    <VStack align="start" spacing={0} flex="1">
                      <Text fontSize="sm" color={subtitleColor}>Total Reviews</Text>
                      <Text fontSize="2xl" fontWeight="bold" lineHeight="1.2">
                        {totalSentiment.toLocaleString()}
                      </Text>
                      <Text fontSize="xs" color={subtitleColor} mt={1}>
                        Customer feedback volume
                      </Text>
                    </VStack>
                  </HStack>
                </CardBody>
              </Card>
              
              <Card bg={cardBg} borderWidth="1px" borderColor={borderColor} borderRadius="lg" boxShadow="sm">
                <CardBody p={4}>
                  <HStack spacing={4} align="flex-start">
                    <Flex
                      w="40px"
                      h="40px"
                      bg="yellow.50"
                      color="yellow.500"
                      borderRadius="lg"
                      justify="center"
                      align="center"
                      flexShrink={0}
                    >
                      <Icon as={FaRegLightbulb} boxSize={5} />
                    </Flex>
                    <VStack align="start" spacing={0} flex="1">
                      <Text fontSize="sm" color={subtitleColor}>Key Insights</Text>
                      <Text fontSize="md" fontWeight="medium" lineHeight="1.2" noOfLines={2}>
                        {(historicalData.commonKeywords || ["No keywords found"]).slice(0, 3).join(", ")}
                      </Text>
                      <Text fontSize="xs" color={subtitleColor} mt={1}>
                        Common feedback themes
                      </Text>
                    </VStack>
                  </HStack>
                </CardBody>
              </Card>
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} mb={8}>
              {/* Monthly Review Trend */}
              <Card bg={cardBg} borderWidth="1px" borderColor={borderColor} borderRadius="lg" overflow="hidden" boxShadow="sm">
                <CardHeader pb={0}>
                  <Flex justify="space-between" align="center">
                    <Heading size="md" color={textColor}>Review Activity Trend</Heading>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Box h="300px">
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={monthlyData}
                        margin={{ top: 10, right: 30, left: 0, bottom: 40 }}
                      >
                        <defs>
                          <linearGradient id="colorReviews" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS.primary} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS.primary} stopOpacity={0.1} />
                          </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#eee" />
                        <XAxis 
                          dataKey="month" 
                          axisLine={false}
                          tickLine={false}
                          angle={-45}
                          textAnchor="end"
                          height={60}
                          interval={0}
                          tick={{ fontSize: 12 }}
                        />
                        <YAxis 
                          axisLine={false}
                          tickLine={false}
                          tickCount={5}
                        />
                        <Tooltip
                          formatter={(value) => [`${value} reviews`, 'Volume']}
                          labelFormatter={(label) => `${label}`}
                          contentStyle={{
                            backgroundColor: cardBg,
                            borderRadius: '8px',
                            border: `1px solid ${borderColor}`,
                            boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
                          }}
                        />
                        <Area
                          type="monotone"
                          dataKey="count"
                          stroke={COLORS.primary}
                          strokeWidth={2}
                          fillOpacity={1}
                          fill="url(#colorReviews)"
                          activeDot={{ r: 6 }}
                          isAnimationActive={true}
                          animationDuration={1000}
                          animationBegin={0}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </Box>
                  <Text fontSize="sm" color={subtitleColor} mt={2} textAlign="center">
                    {growthComparison >= 0 ? "Growing" : "Declining"} review volume with {
                      Math.abs(growthPercentage)
                    }% {growthComparison >= 0 ? "increase" : "decrease"} compared to previous period
                  </Text>
                </CardBody>
              </Card>

              {/* Sentiment Distribution */}
              <Card bg={cardBg} borderWidth="1px" borderColor={borderColor} borderRadius="lg" overflow="hidden" boxShadow="sm">
                <CardHeader pb={0}>
                  <Flex justify="space-between" align="center">
                    <Heading size="md" color={textColor}>Customer Sentiment</Heading>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Flex direction={{ base: "column", md: "row" }} h="300px">
                    <Box w={{ base: "100%", md: "60%" }} h="100%">
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={sentimentPieData}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={100}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                            label={({ name, percent }) => `${name} (${(percent * 100).toFixed(1)}%)`}
                          >
                            {sentimentPieData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.fill} />
                            ))}
                          </Pie>
                          <Tooltip formatter={(value) => `${value} reviews`} />
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                    <VStack align="stretch" spacing={6} p={4} flex="1">
                      <Text fontWeight="medium" fontSize="md" color={textColor}>Sentiment Breakdown</Text>
                      <Box>
                        <HStack justify="space-between" mb={1}>
                          <Text fontSize="sm">Positive 😊</Text>
                          <Text fontSize="sm" fontWeight="bold" color="green.500">
                            {positivePercentage}%
                          </Text>
                        </HStack>
                        <Progress value={parseFloat(positivePercentage)} colorScheme="green" size="sm" mb={3} />
                        
                        <HStack justify="space-between" mb={1}>
                          <Text fontSize="sm">Neutral 😐</Text>
                          <Text fontSize="sm" fontWeight="bold" color="yellow.500">
                            {neutralPercentage}%
                          </Text>
                        </HStack>
                        <Progress value={parseFloat(neutralPercentage)} colorScheme="yellow" size="sm" mb={3} />
                        
                        <HStack justify="space-between" mb={1}>
                          <Text fontSize="sm">Negative 😞</Text>
                          <Text fontSize="sm" fontWeight="bold" color="red.500">
                            {negativePercentage}%
                          </Text>
                        </HStack>
                        <Progress value={parseFloat(negativePercentage)} colorScheme="red" size="sm" />
                      </Box>
                    </VStack>
                  </Flex>
                </CardBody>
              </Card>
            </SimpleGrid>

            {/* Monthly Ratings Breakdown */}
            <Card bg={cardBg} borderWidth="1px" borderColor={borderColor} borderRadius="lg" overflow="hidden" boxShadow="sm" mb={4}>
              <CardHeader pb={0}>
                <Heading size="md" color={textColor}>Monthly Sentiment Breakdown</Heading>
              </CardHeader>
              <CardBody>
                <Box h="300px">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={monthlyData} margin={{ top: 20, right: 30, left: 20, bottom: 40 }}>
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis 
                        dataKey="month" 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fontSize: 12 }}
                        axisLine={false}
                        tickLine={false}
                      />
                      <YAxis 
                        axisLine={false}
                        tickLine={false}
                        tickCount={5}
                      />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: cardBg,
                          borderRadius: '8px',
                          border: `1px solid ${borderColor}`,
                          boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
                        }}
                        formatter={(value, name) => [
                          `${value} reviews`, 
                          name.charAt(0).toUpperCase() + name.slice(1)
                        ]}
                      />
                      <Legend 
                        verticalAlign="top" 
                        height={36}
                        wrapperStyle={{
                          paddingTop: '10px'
                        }}
                      />
                      <Bar 
                        dataKey="positive" 
                        stackId="a" 
                        fill="#48BB78" 
                        name="Positive"
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={0}
                        radius={[4, 4, 0, 0]}
                      />
                      <Bar 
                        dataKey="neutral" 
                        stackId="a" 
                        fill="#ECC94B" 
                        name="Neutral"
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={0}
                      />
                      <Bar 
                        dataKey="negative" 
                        stackId="a" 
                        fill="#E53E3E" 
                        name="Negative"
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={0}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </CardBody>
            </Card>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default BusinessReviewModal; 