import axios from 'axios';
import { getAuth } from "firebase/auth";
import { logger } from '../utils/logger';
import { baseAxiosConfig } from '../utils/apiConfig';

const reviewsAxios = axios.create(baseAxiosConfig);

export async function getReviews(startDate = null, endDate = null) {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      logger.error('No authenticated user found');
      throw new Error('Authentication required');
    }

    const token = await user.getIdToken();
    
    const params = {
      user: user.email
    };

    // Add date parameters if provided
    if (startDate) {
      params.start_date = `${startDate}T00:00:00Z`;
    }
    if (endDate) {
      params.end_date = `${endDate}T23:59:59Z`;
    }
    
    const response = await reviewsAxios.get('/reviews', {
      params,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.data) {
      throw new Error('No data received from server');
    }

    return response.data;
  } catch (error) {
    logger.error("Error in getReviews:", error);
    if (error.response) {
      logger.error("Server response:", error.response.data);
      throw new Error(error.response.data.message || 'Server error');
    }
    throw error;
  }
}

export default getReviews;