import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { SectionLabel } from './DashboardWrapper';
import { 
  Box, 
  Heading, 
  SimpleGrid, 
  Stat, 
  StatLabel, 
  StatNumber, 
  StatHelpText, 
  Text, 
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel, 
  Spinner, 
  Flex,
  useColorModeValue,
  Icon,
  Badge,
  HStack,
  VStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Progress,
  Link,
  Avatar,
  AvatarGroup,
} from '@chakra-ui/react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  Cell,
  ReferenceLine,
  PieChart,
  Pie,
  AreaChart,
  Area,
} from 'recharts';
import { 
  FaChartLine, 
  FaArrowUp, 
  FaSearch, 
  FaStar, 
  FaUsers, 
  FaMapMarkerAlt, 
  FaPhone, 
  FaGlobeAmericas, 
  FaTrophy,
  FaAward,
  FaMedal,
  FaFireAlt,
} from 'react-icons/fa';
import RankingListComponent from '../RankingListComponent';
import DataTable from 'react-data-table-component';
import BusinessReviewModal from '../BusinessReviewModal';
import { getYearData } from '../../services/yearService';

const GrossGrowthDashboard = ({ reviewsData, dateRange }) => {
  // Set up color mode values
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const statBg = useColorModeValue('gray.50', 'gray.700');

  const [yearData, setYearData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Colors for charts - using consistent color palette with more distinct colors
  const colors = useMemo(() => [
    '#2F80ED', // bright blue
    '#27AE60', // emerald green
    '#EB5757', // bright red
    '#9B51E0', // purple
    '#F2994A', // orange
    '#219EBC', // teal
    '#F264A6', // pink
    '#BB6BD9', // violet
    '#2D9CDB', // light blue
    '#219653', // dark green
    '#F2C94C', // yellow
    '#6FCF97', // light green
    '#56CCF2', // sky blue
    '#F2994A', // peach
    '#9B51E0', // lavender
    '#EB5757', // coral
    '#2F80ED', // royal blue
    '#27AE60', // forest green
    '#BB6BD9', // orchid
    '#F264A6'  // rose
  ], []);
  const [businessColors, setBusinessColors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const yearDataResponse = await getYearData();
        setYearData(yearDataResponse);
        
        // Combine data using maps_uri as unique identifier
        const combined = reviewsData.map(currentData => {
          const historicalData = yearDataResponse.find(yd => 
            yd.maps_uri === currentData.maps_uri
          );
          
          return {
            ...currentData,
            historicalData: historicalData?.data || null
          };
        });
        
        setCombinedData(combined);
      } catch (error) {
        console.error("Error fetching year data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (reviewsData?.length > 0) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [reviewsData, dateRange]); // Add dateRange dependency to re-process when date changes

  useEffect(() => {
    // Assign colors to businesses
    if (reviewsData) {
      const newBusinessColors = {};
      reviewsData.forEach((business, index) => {
        newBusinessColors[business.name] = colors[index % colors.length];
      });
      setBusinessColors(newBusinessColors);
    }
  }, [reviewsData, colors]);

  const getColor = useCallback((businessName) => {
    return businessColors[businessName] || colors[0];
  }, [businessColors, colors]);

  // Calculate growth metrics for each business based on selected date range
  const businessGrowthData = useMemo(() => {
    if (!combinedData || combinedData.length === 0) {
      return [];
    }
    
    return combinedData.map(business => {
      const monthlyData = business.historicalData?.monthlyReviewCounts || {};
      
      // Filter months based on selected date range
      let months = Object.keys(monthlyData).sort();
      
      if (dateRange?.startDate && dateRange?.endDate) {
        months = months.filter(month => {
          const monthDate = new Date(month);
          const startDate = new Date(dateRange.startDate);
          const endDate = new Date(dateRange.endDate);
          return monthDate >= startDate && monthDate <= endDate;
        });
      }
      
      // Skip if not enough data
      if (months.length === 0) {
        return {
          ...business,
          threeMonthGrowth: 0,
          monthlyGrowth: [0, 0, 0],
          growthRate: 0,
          totalReviews: business.rating_count
        };
      }

      // Calculate growth based on all selected months
      const totalGrowth = months.reduce((sum, month) => sum + monthlyData[month], 0);
      
      // Get last 3 months of data or all available months if less than 3
      const lastThreeMonths = months.length >= 3 ? months.slice(-3) : months;
      const threeMonthGrowth = lastThreeMonths.reduce((sum, month) => sum + monthlyData[month], 0);
      const monthlyGrowth = lastThreeMonths.map(month => monthlyData[month]);
      
      // Calculate average monthly growth rate based on available months
      const growthRate = lastThreeMonths.length > 0 ? 
        threeMonthGrowth / lastThreeMonths.length : 0;
      
      return {
        ...business,
        threeMonthGrowth,
        monthlyGrowth,
        growthRate,
        totalGrowth,
        totalReviews: business.rating_count
      };
    });
  }, [combinedData, dateRange]);
  
  // Sort businesses by growth
  const sortedByGrowth = useMemo(() => {
    if (!businessGrowthData.length) return [];
    return [...businessGrowthData].sort((a, b) => b.threeMonthGrowth - a.threeMonthGrowth);
  }, [businessGrowthData]);

  const top5Growth = useMemo(() => {
    if (!sortedByGrowth.length) return [];
    return sortedByGrowth.slice(0, 5);
  }, [sortedByGrowth]);
  
  const bottom5Growth = useMemo(() => {
    if (!sortedByGrowth.length) return [];
    return sortedByGrowth.slice(-5).reverse();
  }, [sortedByGrowth]);

  // Calculate market-wide metrics
  const marketMetrics = useMemo(() => {
    if (!sortedByGrowth.length) {
      return {
        totalMarketGrowth: 0,
        averageGrowth: 0,
        medianGrowth: 0,
        avgMonthlyGrowth: 0
      };
    }
    
    const totalMarketGrowth = sortedByGrowth.reduce((sum, b) => sum + (b?.threeMonthGrowth || 0), 0);
    const averageGrowth = sortedByGrowth.length > 0 ? totalMarketGrowth / sortedByGrowth.length : 0;
    const medianGrowth = sortedByGrowth.length > 0 ? 
      sortedByGrowth[Math.floor(sortedByGrowth.length / 2)]?.threeMonthGrowth || 0 : 0;
    const avgMonthlyGrowth = averageGrowth / 3;

    return {
      totalMarketGrowth,
      averageGrowth,
      medianGrowth,
      avgMonthlyGrowth
    };
  }, [sortedByGrowth]);

  // Prepare data for bar chart
  const barChartData = useMemo(() => {
    if (!sortedByGrowth.length) return [];
    
    return sortedByGrowth.slice(0, 20).map(business => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      growth: business.threeMonthGrowth,
      fill: getColor(business.name)
    }));
  }, [sortedByGrowth, getColor]);

  // Prepare data for pie chart
  const pieChartData = useMemo(() => {
    if (!sortedByGrowth.length) return [];
    
    return sortedByGrowth.slice(0, 10)
      .filter(business => business && business.threeMonthGrowth !== undefined)
      .map((business, index) => ({
        name: business.name.split(' ').slice(0, 2).join(' '),
        value: business.threeMonthGrowth,
        fill: getColor(business.name)
      }));
  }, [sortedByGrowth, getColor]);

  // Prepare monthly trend data for top 5 businesses based on date range
  const monthlyTrendData = useMemo(() => {
    if (!top5Growth.length || !sortedByGrowth.length) return [];
    
    const months = Object.keys(top5Growth[0]?.historicalData?.monthlyReviewCounts || {}).sort();
    if (!months || months.length === 0) return [];
    
    // Filter months based on selected date range
    let filteredMonths = [...months];
    if (dateRange?.startDate && dateRange?.endDate) {
      filteredMonths = months.filter(month => {
        const monthDate = new Date(month);
        const startDate = new Date(dateRange.startDate);
        const endDate = new Date(dateRange.endDate);
        return monthDate >= startDate && monthDate <= endDate;
      });
    }
    
    // Use all available months or limit to the last 6 if there are more
    const displayMonths = filteredMonths.length > 6 ? 
      filteredMonths.slice(-6) : filteredMonths;
    
    return displayMonths.map(month => {
      const monthData = {
        month: month.split('-')[1], // Get just the month part
        year: month.split('-')[0], // Get the year part
        fullMonth: month // Keep the full date for sorting and display
      };
      
      // Calculate market average for this month
      const monthTotal = sortedByGrowth.reduce((sum, business) => {
        const monthlyData = business.historicalData?.monthlyReviewCounts || {};
        return sum + (monthlyData[month] || 0);
      }, 0);
      monthData['Market Average'] = monthTotal / sortedByGrowth.length;
      
      // Add data for top 5 businesses
      top5Growth.forEach(business => {
        if (business) {
          const monthlyData = business.historicalData?.monthlyReviewCounts || {};
          monthData[business.name.split(' ').slice(0, 2).join(' ')] = monthlyData[month] || 0;
        }
      });
      
      return monthData;
    }).sort((a, b) => new Date(a.fullMonth) - new Date(b.fullMonth)); // Ensure chronological order
  }, [top5Growth, sortedByGrowth, dateRange]);

  // Prepare data for area chart
  const areaChartData = useMemo(() => {
    if (!monthlyTrendData.length) return [];
    
    return monthlyTrendData.map(item => ({
      month: `${item.month}-${item.year.slice(2)}`,
      ...item
    }));
  }, [monthlyTrendData]);
  
  // Add early return for loading state
  if (isLoading || !reviewsData || reviewsData.length === 0) {
    return (
      <Flex justify="center" align="center" minH="300px" direction="column">
        <Spinner 
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text mt={4} fontSize="lg" color="gray.500">Loading growth data...</Text>
      </Flex>
    );
  }

  // Prepare data table columns
  const columns = [
    {
      name: 'Business Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: '3-Month Growth',
      selector: row => row.threeMonthGrowth,
      sortable: true,
      right: true,
      format: row => row.threeMonthGrowth.toLocaleString(),
      cell: row => (
        <HStack spacing={2}>
          <Text>{row.threeMonthGrowth.toLocaleString()}</Text>
          {row.threeMonthGrowth > marketMetrics.averageGrowth && (
            <Icon as={FaArrowUp} color="green.500" boxSize={3} />
          )}
        </HStack>
      ),
    },
    {
      name: 'Monthly Average',
      selector: row => row.growthRate,
      sortable: true,
      right: true,
      format: row => row.growthRate.toFixed(1),
    },
    {
      name: 'Total Reviews',
      selector: row => row.totalReviews,
      sortable: true,
      right: true,
      format: row => row.totalReviews.toLocaleString(),
    }
  ];

  const handleBusinessClick = (business) => {
    setSelectedBusiness(business);
    setIsModalOpen(true);
  };

  // Business Growth Card component
  const BusinessGrowthCard = ({ business }) => {
    const growthColor = business.threeMonthGrowth > marketMetrics.averageGrowth 
      ? "green.500" 
      : business.threeMonthGrowth > 0 
        ? "blue.500" 
        : "gray.500";
        
    // Icon selection based on growth performance
    // Not currently used but kept for future UI enhancement
      
    // Define hover background color at component level
    const hoverBg = useColorModeValue('gray.50', 'gray.700');

    // Process monthly data for mini chart
    const monthlyData = useMemo(() => {
      return Object.entries(business.historicalData?.monthlyReviewCounts || {})
        .map(([month, count]) => ({
          month: month,
          count: count
        }))
        .sort((a, b) => a.month.localeCompare(b.month))
        .slice(-6);  // Get last 6 months
    }, [business.historicalData]);

    return (
      <Box
        bg={cardBg}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="lg"
        overflow="hidden"
        p={4}
        cursor="pointer"
        onClick={() => handleBusinessClick(business)}
        transition="all 0.2s"
        _hover={{ 
          transform: 'translateY(-4px)',
          shadow: 'lg',
          borderColor: 'blue.400',
          bg: hoverBg
        }}
        height="100%"
      >
        <VStack align="stretch" spacing={4} height="100%">
          <Box flex="1">
            <Heading
              size="md"
              mb={2}
              lineHeight="1.4"
              minHeight="2.8em"
              overflow="hidden"
              css={{
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
              title={business.name}
            >
              {business.name}
            </Heading>
            <HStack spacing={2} mb={2} justify="space-between">
              <Badge colorScheme="blue">
                {business.primary_type?.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) || 'Business'}
              </Badge>
              <HStack spacing={1}>
                <Icon as={FaStar} color="yellow.400" boxSize={4} />
                <Text fontWeight="bold">{business.rating_avg?.toFixed(1) || '0.0'}</Text>
                <Text color="gray.500">({parseInt(business.rating_count || 0).toLocaleString()})</Text>
              </HStack>
            </HStack>
          </Box>

          {/* Growth Metrics */}
          <Box>
            <Text fontSize="sm" fontWeight="medium" mb={1}>Growth Metrics</Text>
            
            {/* Mini Area Chart */}
            <Box h="80px" mb={2}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={monthlyData}>
                  <defs>
                    <linearGradient id={`colorGrowth-${business.name.replace(/\s+/g, '')}`} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={growthColor} stopOpacity={0.8}/>
                      <stop offset="95%" stopColor={growthColor} stopOpacity={0.1}/>
                    </linearGradient>
                  </defs>
                  <Area 
                    type="monotone" 
                    dataKey="count" 
                    stroke={growthColor} 
                    fillOpacity={1}
                    fill={`url(#colorGrowth-${business.name.replace(/\s+/g, '')})`} 
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
            
            <SimpleGrid columns={2} spacing={2} mb={3}>
              <Stat bg={statBg} p={2} borderRadius="md">
                <StatLabel fontSize="xs">3-Month Growth</StatLabel>
                <StatNumber fontSize="lg" color={growthColor}>
                  {business.threeMonthGrowth.toLocaleString()}
                </StatNumber>
              </Stat>
              <Stat bg={statBg} p={2} borderRadius="md">
                <StatLabel fontSize="xs">Monthly Avg</StatLabel>
                <StatNumber fontSize="lg" color="blue.500">
                  {business.growthRate.toFixed(1)}
                </StatNumber>
              </Stat>
            </SimpleGrid>
          </Box>

          {/* Basic Info */}
          <VStack align="start" spacing={1} mt="auto" fontSize="sm">
            <HStack spacing={2}>
              <Icon as={FaMapMarkerAlt} color="gray.500" boxSize={4} />
              <Text noOfLines={1}>{business.address || 'No address available'}</Text>
            </HStack>
            <HStack spacing={2}>
              <Icon as={FaUsers} color="gray.500" boxSize={4} />
              <Text>{business.rating_count} total reviews</Text>
            </HStack>
          </VStack>
        </VStack>
      </Box>
    );
  };

  // Stat Card component
  const StatCard = ({ icon, title, value, description, colorScheme = "blue" }) => (
    <Box
      bg={cardBg}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      p={5}
      shadow="md"
      transition="transform 0.3s"
      _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
    >
      <VStack spacing={3} align="flex-start">
        <Icon 
          as={icon} 
          boxSize={6} 
          color={`${colorScheme}.500`} 
        />
        <Text color="gray.500" fontSize="md">{title}</Text>
        <Heading size="xl" color={`${colorScheme}.600`}>
          {value}
        </Heading>
        <Text color="gray.600" fontSize="sm">
          {description}
        </Text>
      </VStack>
    </Box>
  );

  return (
    <Box>

      {/* Market Metrics */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} mb={8}>
        <StatCard
          icon={FaChartLine}
          title="Total Market Growth"
          value={marketMetrics.totalMarketGrowth.toLocaleString()}
          description={dateRange?.startDate && dateRange?.endDate ? 
            "New reviews in selected period" : 
            "New reviews in 3 months"}
          colorScheme="purple"
        />
        <StatCard
          icon={FaUsers}
          title="Average Growth"
          value={marketMetrics.averageGrowth.toFixed(0)}
          description="Reviews per business"
          colorScheme="blue"
        />
        <StatCard
          icon={FaFireAlt}
          title="Monthly Average"
          value={marketMetrics.avgMonthlyGrowth.toFixed(1)}
          description="New reviews per month"
          colorScheme="orange"
        />
      </SimpleGrid>

      {/* Section Label */}
      <SectionLabel description="Track absolute growth metrics like total review increases and engagement trends over time.">Growth Trend Analysis</SectionLabel>

      {/* Top 3 Podium - Only show on desktop and tablet */}
      <Box 
        display={{ base: "none", md: "block" }}
        bg={cardBg}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="lg"
        p={6}
        mb={8}
        shadow="md"
      >
        <Heading size="md" mb={6} color="blue.600">Growth Champions</Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
          {/* Silver - Second Place */}
          <Box 
            bg={statBg} p={5} borderRadius="lg" boxShadow="sm" textAlign="center" 
            position="relative" top="20px"
            cursor="pointer"
            onClick={() => top5Growth[1] && handleBusinessClick(top5Growth[1])}
            transition="all 0.2s"
            _hover={{ transform: 'translateY(-5px)', shadow: 'md' }}
          >
            <Icon as={FaMedal} color="gray.400" boxSize={8} mb={2} />
            <AvatarGroup size="md" max={1} mb={3} justifyContent="center">
              <Avatar name={top5Growth[1]?.name} src="" bg="gray.300" />
            </AvatarGroup>
            <Heading size="md" mb={2} noOfLines={1}>{top5Growth[1]?.name}</Heading>
            <Text fontWeight="bold" fontSize="2xl" color="gray.600">
              +{top5Growth[1]?.threeMonthGrowth.toLocaleString()}
            </Text>
            <Text fontSize="sm" color="gray.500">new reviews</Text>
            <Progress 
              value={(top5Growth[1]?.threeMonthGrowth / (top5Growth[0]?.threeMonthGrowth || 1)) * 100} 
              colorScheme="gray" 
              size="sm" 
              borderRadius="full" 
              mt={3}
            />
          </Box>

          {/* Gold - First Place */}
          <Box 
            bg={statBg} p={6} borderRadius="lg" boxShadow="lg" textAlign="center"
            transform="scale(1.05)" zIndex={1}
            cursor="pointer"
            onClick={() => top5Growth[0] && handleBusinessClick(top5Growth[0])}
            transition="all 0.2s"
            _hover={{ transform: 'scale(1.08)', shadow: 'lg' }}
          >
            <Icon as={FaTrophy} color="yellow.400" boxSize={10} mb={2} />
            <AvatarGroup size="lg" max={1} mb={3} justifyContent="center">
              <Avatar name={top5Growth[0]?.name} src="" bg="yellow.100" />
            </AvatarGroup>
            <Heading size="md" mb={2} noOfLines={1}>{top5Growth[0]?.name}</Heading>
            <Text fontWeight="bold" fontSize="3xl" color="blue.600">
              +{top5Growth[0]?.threeMonthGrowth.toLocaleString()}
            </Text>
            <Text fontSize="sm" color="gray.500">new reviews</Text>
            <Progress 
              value={100} 
              colorScheme="yellow" 
              size="sm" 
              borderRadius="full" 
              mt={3}
            />
          </Box>

          {/* Bronze - Third Place */}
          <Box 
            bg={statBg} p={5} borderRadius="lg" boxShadow="sm" textAlign="center"
            position="relative" top="20px"
            cursor="pointer"
            onClick={() => top5Growth[2] && handleBusinessClick(top5Growth[2])}
            transition="all 0.2s"
            _hover={{ transform: 'translateY(-5px)', shadow: 'md' }}
          >
            <Icon as={FaAward} color="orange.300" boxSize={8} mb={2} />
            <AvatarGroup size="md" max={1} mb={3} justifyContent="center">
              <Avatar name={top5Growth[2]?.name} src="" bg="orange.100" />
            </AvatarGroup>
            <Heading size="md" mb={2} noOfLines={1}>{top5Growth[2]?.name}</Heading>
            <Text fontWeight="bold" fontSize="2xl" color="gray.600">
              +{top5Growth[2]?.threeMonthGrowth.toLocaleString()}
            </Text>
            <Text fontSize="sm" color="gray.500">new reviews</Text>
            <Progress 
              value={(top5Growth[2]?.threeMonthGrowth / (top5Growth[0]?.threeMonthGrowth || 1)) * 100} 
              colorScheme="orange" 
              size="sm" 
              borderRadius="full" 
              mt={3}
            />
          </Box>
        </SimpleGrid>
      </Box>

      {/* Main Content Area with Tabs */}
      <Box 
        bg={cardBg} 
        borderRadius="lg" 
        shadow="md" 
        borderWidth="1px"
        borderColor={borderColor}
        mb={8} 
        overflow="hidden"
      >
        <Tabs>
          <TabList px={4} pt={4}>
            <Tab fontWeight="medium">Overview</Tab>
            <Tab fontWeight="medium">Businesses</Tab>
            <Tab fontWeight="medium">Trends</Tab>
            <Tab fontWeight="medium">Rankings</Tab>
            <Tab fontWeight="medium">Details</Tab>
          </TabList>

          <TabPanels>
            {/* Overview Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                {/* Growth Distribution Bar Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4} color="gray.700">Growth Distribution</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart 
                      data={barChartData}
                      margin={{ top: 5, right: 30, left: 20, bottom: 60 }}
                      animationDuration={1000}
                      animationBegin={0}
                      animationEasing="ease-out"
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
                      <XAxis 
                        dataKey="name" 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fill: '#4A5568', fontSize: 12 }}
                      />
                      <YAxis tick={{ fill: '#4A5568', fontSize: 12 }} />
                      <RechartsTooltip 
                        formatter={(value) => value.toLocaleString()}
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            return (
                              <Box 
                                bg="white" 
                                p={3} 
                                boxShadow="lg" 
                                border="1px" 
                                borderColor="gray.200" 
                                borderRadius="md"
                              >
                                <Text fontWeight="bold">{payload[0].payload.name}</Text>
                                <Text>
                                  {payload[0].value.toLocaleString()} new reviews
                                </Text>
                                <Text color="orange.500">
                                  Market Average: {marketMetrics.averageGrowth.toLocaleString()}
                                </Text>
                              </Box>
                            );
                          }
                          return null;
                        }}
                      />
                      <Bar 
                        dataKey="growth" 
                        fill={colors[0]}
                        cursor="pointer"
                        onClick={(data) => {
                          const business = sortedByGrowth.find(b => 
                            b.name.split(' ').slice(0, 2).join(' ') === data.name
                          );
                          if (business) handleBusinessClick(business);
                        }}
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={300}
                        animationEasing="ease-out"
                      >
                        {barChartData.map((entry, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={index < 5 ? entry.fill : '#CBD5E0'} 
                          />
                        ))}
                      </Bar>
                      <ReferenceLine 
                        y={marketMetrics.averageGrowth} 
                        stroke="#ED8936" 
                        strokeDasharray="5 5"
                        strokeWidth={2}
                        label={{ 
                          value: 'Market Average', 
                          position: 'right',
                          fill: '#ED8936'
                        }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>

                {/* Growth Leaders Pie Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4} color="gray.700">Market Distribution</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={120}
                        paddingAngle={2}
                        dataKey="value"
                        label={({ name, value, percent }) => 
                          `${name} (${(percent * 100).toFixed(1)}%)`
                        }
                        labelLine={{ stroke: '#4A5568', strokeWidth: 1 }}
                        isAnimationActive={true}
                        animationBegin={300}
                        animationDuration={800}
                        animationEasing="ease-out"
                        cursor="pointer"
                        onClick={(data) => {
                          const business = sortedByGrowth.find(b => 
                            b.name.split(' ').slice(0, 2).join(' ') === data.name
                          );
                          if (business) handleBusinessClick(business);
                        }}
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                      </Pie>
                      <RechartsTooltip 
                        formatter={(value) => value.toLocaleString()}
                        contentStyle={{
                          backgroundColor: 'white',
                          border: '1px solid #E2E8F0',
                          borderRadius: '4px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Businesses Panel */}
            <TabPanel>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
                spacing={6}
                alignItems="stretch"
              >
                {sortedByGrowth.map(business => (
                  <BusinessGrowthCard 
                    key={business.maps_uri} 
                    business={business} 
                  />
                ))}
              </SimpleGrid>
            </TabPanel>

            {/* Trends Panel */}
            <TabPanel>
              <Box height="500px">
                <Heading size="md" mb={4} color="gray.700">Monthly Growth Trends</Heading>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={areaChartData}>
                    <defs>
                      {top5Growth.map((business, index) => (
                        business && (
                          <linearGradient 
                            key={business.name} 
                            id={`color${index}`} 
                            x1="0" 
                            y1="0" 
                            x2="0" 
                            y2="1"
                          >
                            <stop 
                              offset="5%" 
                              stopColor={getColor(business.name)} 
                              stopOpacity={0.8}
                            />
                            <stop 
                              offset="95%" 
                              stopColor={getColor(business.name)} 
                              stopOpacity={0.1}
                            />
                          </linearGradient>
                        )
                      ))}
                      <linearGradient id="colorMarket" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#ED8936" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#ED8936" stopOpacity={0.1}/>
                      </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
                    <XAxis 
                      dataKey="month" 
                      tick={{ fill: '#4A5568', fontSize: 12 }}
                    />
                    <YAxis tick={{ fill: '#4A5568', fontSize: 12 }} />
                    <RechartsTooltip 
                      formatter={(value) => value.toLocaleString()}
                      labelFormatter={(value) => `Month: ${value}`}
                      contentStyle={{
                        backgroundColor: 'white',
                        border: '1px solid #E2E8F0',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                      }}
                    />
                    <Legend />
                    <Area
                      type="monotone"
                      dataKey="Market Average"
                      stroke="#ED8936"
                      strokeWidth={2}
                      fillOpacity={1}
                      fill="url(#colorMarket)"
                      strokeDasharray="5 5"
                    />
                    {top5Growth.map((business, index) => (
                      business && (
                        <Area
                          key={business.name}
                          type="monotone"
                          dataKey={business.name.split(' ').slice(0, 2).join(' ')}
                          stroke={getColor(business.name)}
                          strokeWidth={2}
                          fillOpacity={1}
                          fill={`url(#color${index})`}
                        />
                      )
                    ))}
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            </TabPanel>

            {/* Rankings Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                <Box>
                  <RankingListComponent
                    title="Top Growth Rankings"
                    data={top5Growth}
                    valueKey="threeMonthGrowth"
                    unit=" reviews"
                    side="top"
                  />
                </Box>
                <Box>
                  <RankingListComponent
                    title="Lowest Growth Rankings"
                    data={bottom5Growth}
                    valueKey="threeMonthGrowth"
                    unit=" reviews"
                    side="bottom"
                  />
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Details Panel */}
            <TabPanel>
              <DataTable
                columns={columns}
                data={businessGrowthData}
                pagination
                paginationPerPage={10}
                sortable
                striped
                responsive
                highlightOnHover
                pointerOnHover
                onRowClicked={handleBusinessClick}
                defaultSortFieldId={2}
                defaultSortAsc={false}
                theme="default"
                customStyles={{
                  rows: {
                    style: {
                      minHeight: '52px',
                      cursor: 'pointer',
                    },
                  },
                  headCells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      fontWeight: 'bold',
                    },
                  },
                  cells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                    },
                  },
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {/* Add the Modal */}
      <BusinessReviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        business={selectedBusiness}
        yearData={yearData}
      />
    </Box>
  );
};

export default GrossGrowthDashboard; 