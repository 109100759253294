import axios from 'axios';
import { logger } from '../utils/logger';
import { baseAxiosConfig } from '../utils/apiConfig';

const landingAxios = axios.create(baseAxiosConfig);

export async function submitLandingForm(email, niche) {
  // Basic input validation
  if (!email || !email.includes('@')) {
    throw new Error('Invalid email format');
  }
  
  if (!niche || niche.length > 100) { // Prevent extremely long inputs
    throw new Error('Please enter your business type and location (e.g., "Local Restaurant - East Village", "Auto Repair Shop - Nashville", or "HVAC Service - Atlanta")');
  }

  try {
    const response = await landingAxios.post('/landing', {
      email: email.trim().toLowerCase(), // Sanitize email
      niche: niche.trim() // Sanitize niche
    });
    return response.data;
  } catch (error) {
    logger.error('Landing submission error:', error);
    // Generic error message for users
    throw new Error('Unable to submit form. Please try again later.');
  }
} 