import React, { useMemo, useState } from 'react';
import { SectionLabel } from './DashboardWrapper';
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Heading,
  Text,
  Flex,
  Badge,
  Icon,
  useColorModeValue,
  HStack,
  VStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Progress,
  Link,
  Avatar,
  AvatarGroup,
  Divider,
  Button,
  Tooltip,
  Spinner,
  Grid,
} from '@chakra-ui/react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  CartesianGrid,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  ScatterChart,
  Scatter,
  ReferenceLine,
  AreaChart,
  Area,
} from 'recharts';
import DataTable from 'react-data-table-component';
import { 
  FaGlobe, 
  FaChartLine, 
  FaUsers, 
  FaSearch, 
  FaStar, 
  FaMapMarkerAlt, 
  FaShareAlt, 
  FaFacebook, 
  FaInstagram, 
  FaTwitter, 
  FaLink, 
  FaTrophy, 
  FaSort, 
  FaFilter,
  FaExternalLinkAlt,
  FaArrowUp,
  FaFireAlt,
} from 'react-icons/fa';
import { InfoOutlineIcon, ExternalLinkIcon } from '@chakra-ui/icons';

const COLORS = [
  '#2F80ED', // bright blue
  '#27AE60', // emerald green
  '#EB5757', // bright red
  '#9B51E0', // purple
  '#F2994A', // orange
  '#219EBC', // teal
  '#F264A6', // pink
  '#BB6BD9', // violet
  '#2D9CDB', // light blue
  '#219653', // dark green
  '#F2C94C', // yellow
  '#6FCF97', // light green
  '#56CCF2', // sky blue
  '#F2994A', // peach
  '#9B51E0', // lavender
  '#EB5757', // coral
  '#2F80ED', // royal blue
  '#27AE60', // forest green
  '#BB6BD9', // orchid
  '#F264A6'  // rose
];

const WebTrafficDashboard = ({ reviewsData }) => {
  // Set up color mode values
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const statBg = useColorModeValue('gray.50', 'gray.700');
  const searchBg = useColorModeValue('white', 'gray.700');
  const searchBorder = useColorModeValue('gray.200', 'gray.600');
  
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  // Calculate metrics
  const businessesWithTraffic = useMemo(() => {
    return reviewsData.filter(item => 
      item.traffic_data?.metrics?.estimated_monthly_visitors > 0 && 
      item.name !== "Honky Tonk Highway"
    ).sort((a, b) => 
      (b.traffic_data?.metrics?.estimated_monthly_visitors || 0) - 
      (a.traffic_data?.metrics?.estimated_monthly_visitors || 0)
    );
  }, [reviewsData]);

  // Filter businesses
  const filteredBusinesses = useMemo(() => {
    if (!businessesWithTraffic.length) return [];
    return businessesWithTraffic;
  }, [businessesWithTraffic]);

  // Sort businesses by traffic
  const sortedBusinesses = useMemo(() => {
    if (!filteredBusinesses.length) return [];
    
    return [...filteredBusinesses].sort((a, b) => {
      return (b.traffic_data?.metrics?.estimated_monthly_visitors || 0) - 
             (a.traffic_data?.metrics?.estimated_monthly_visitors || 0);
    });
  }, [filteredBusinesses]);

  const totalMonthlyVisitors = useMemo(() => {
    return businessesWithTraffic.reduce((sum, item) => 
      sum + (item.traffic_data?.metrics?.estimated_monthly_visitors || 0), 0
    );
  }, [businessesWithTraffic]);

  const avgMonthlyVisitors = useMemo(() => {
    return totalMonthlyVisitors / businessesWithTraffic.length;
  }, [totalMonthlyVisitors, businessesWithTraffic]);

  const medianMonthlyVisitors = useMemo(() => {
    const sorted = [...businessesWithTraffic]
      .map(item => item.traffic_data?.metrics?.estimated_monthly_visitors || 0)
      .sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    return sorted.length % 2 === 0 
      ? (sorted[mid - 1] + sorted[mid]) / 2 
      : sorted[mid];
  }, [businessesWithTraffic]);

  const top5Traffic = useMemo(() => sortedBusinesses.slice(0, 5), [sortedBusinesses]);
  const bottom5Traffic = useMemo(() => 
    [...sortedBusinesses]
      .sort((a, b) => (a.traffic_data?.metrics?.estimated_monthly_visitors || 0) - 
                       (b.traffic_data?.metrics?.estimated_monthly_visitors || 0))
      .slice(0, 5), 
    [sortedBusinesses]
  );

  // Table columns configuration
  const columns = [
    {
      name: 'Business Name',
      selector: row => row.name,
      sortable: true,
      grow: 2,
      cell: row => (
        <HStack spacing={2}>
          <Text>{row.name}</Text>
          {row.traffic_data?.metrics?.estimated_monthly_visitors > avgMonthlyVisitors && (
            <Icon as={FaArrowUp} color="green.500" boxSize={3} />
          )}
        </HStack>
      ),
    },
    {
      name: 'Monthly Visitors',
      selector: row => row.traffic_data?.metrics?.estimated_monthly_visitors || 0,
      sortable: true,
      right: true,
      format: row => (row.traffic_data?.metrics?.estimated_monthly_visitors || 0).toLocaleString(),
    },
    {
      name: 'Social Media',
      selector: row => {
        const social = row.traffic_data?.metrics?.social || {};
        return Object.values(social).filter(Boolean).length;
      },
      sortable: true,
      right: true,
      cell: row => {
        const social = row.traffic_data?.metrics?.social || {};
        const count = Object.values(social).filter(Boolean).length;
        return (
          <Badge colorScheme={count > 1 ? "green" : count === 1 ? "yellow" : "red"}>
            {count}/3
          </Badge>
        );
      },
    },
    {
      name: 'Status',
      selector: row => row.traffic_data?.metrics?.page_info?.status_code,
      sortable: true,
      right: true,
      cell: row => {
        const status = row.traffic_data?.metrics?.page_info?.status_code;
        return (
          <Badge colorScheme={status === 200 ? "green" : "red"}>
            {status || 'N/A'}
          </Badge>
        );
      },
    },
    {
      name: 'Reviews',
      selector: row => row.rating_count || 0,
      sortable: true,
      right: true,
      format: row => (row.rating_count || 0).toLocaleString(),
    }
  ];

  // Prepare traffic distribution data
  const trafficDistributionData = useMemo(() => {
    return sortedBusinesses.slice(0, 20).map(business => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      visitors: business.traffic_data?.metrics?.estimated_monthly_visitors || 0,
      percentage: ((business.traffic_data?.metrics?.estimated_monthly_visitors || 0) / totalMonthlyVisitors * 100).toFixed(1),
      fill: COLORS[sortedBusinesses.indexOf(business) % COLORS.length]
    }));
  }, [sortedBusinesses, totalMonthlyVisitors]);

  // Prepare pie chart data
  const pieChartData = useMemo(() => {
    return sortedBusinesses.slice(0, 10).map((business, index) => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      value: business.traffic_data?.metrics?.estimated_monthly_visitors || 0,
      fill: COLORS[index % COLORS.length]
    }));
  }, [sortedBusinesses]);

  // Prepare XY plot data comparing traffic and growth
  const xyPlotData = useMemo(() => {
    return sortedBusinesses.map(business => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      fullName: business.name,
      x: business.rating_count || 0,
      y: business.traffic_data?.metrics?.estimated_monthly_visitors || 0,
      fill: COLORS[sortedBusinesses.indexOf(business) % COLORS.length]
    }));
  }, [sortedBusinesses]);

  // Stat Card component
  const StatCard = ({ icon, title, value, description, colorScheme = "blue" }) => (
    <Box
      bg={cardBg}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      p={5}
      shadow="md"
      transition="transform 0.3s"
      _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
    >
      <VStack spacing={3} align="flex-start">
        <Icon 
          as={icon} 
          boxSize={6} 
          color={`${colorScheme}.500`} 
        />
        <Text color="gray.500" fontSize="md">{title}</Text>
        <Heading size="xl" color={`${colorScheme}.600`}>
          {value}
        </Heading>
        <Text color="gray.600" fontSize="sm">
          {description}
        </Text>
      </VStack>
    </Box>
  );

  // Business Traffic Card component
  const BusinessTrafficCard = ({ business }) => {
    const visitors = business.traffic_data?.metrics?.estimated_monthly_visitors || 0;
    const comparisonToAvg = (visitors / avgMonthlyVisitors * 100);
    const trafficColor = visitors > avgMonthlyVisitors 
      ? "green.500" 
      : visitors > avgMonthlyVisitors / 2 
        ? "blue.500" 
        : "gray.500";

    const social = business.traffic_data?.metrics?.social || {};
    const socialCount = Object.values(social).filter(Boolean).length;
    
    // Define hover background color using useColorModeValue at component level
    const hoverBg = useColorModeValue('gray.50', 'gray.700');

    // Determine badge color based on social count
    const socialBadgeColor = socialCount === 0 
      ? "red" 
      : socialCount === 1 
        ? "yellow" 
        : socialCount === 2 
          ? "green" 
          : "blue";

    return (
      <Box
        bg={cardBg}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="lg"
        overflow="hidden"
        p={4}
        cursor="pointer"
        transition="all 0.2s"
        _hover={{ 
          transform: 'translateY(-4px)',
          shadow: 'lg',
          borderColor: 'blue.400',
          bg: hoverBg
        }}
        height="100%"
      >
        <VStack align="stretch" spacing={4} height="100%">
          <Box flex="1">
            <Heading
              size="md"
              mb={2}
              lineHeight="1.4"
              minHeight="2.8em"
              overflow="hidden"
              css={{
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
              title={business.name}
            >
              {business.name}
            </Heading>
            <HStack spacing={2} mb={2} justify="space-between">
              <Badge colorScheme="blue">
                {business.primary_type?.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) || 'Business'}
              </Badge>
              <Badge colorScheme={socialBadgeColor}>
                {socialCount}/3 Social
              </Badge>
            </HStack>
          </Box>

          {/* Traffic Metrics */}
          <Box>
            <Text fontSize="sm" fontWeight="medium" mb={1}>Monthly Web Traffic</Text>
            
            {/* Traffic Bar */}
            <Box mb={3}>
              <Flex justify="space-between" mb={1}>
                <Text fontSize="sm">Site Visitors</Text>
                <Text fontSize="sm" fontWeight="bold">{visitors.toLocaleString()}</Text>
              </Flex>
              <Progress 
                value={comparisonToAvg > 100 ? 100 : comparisonToAvg} 
                colorScheme={trafficColor === "green.500" ? "green" : trafficColor === "blue.500" ? "blue" : "gray"} 
                size="sm" 
                borderRadius="full"
              />
              <Text fontSize="xs" color="gray.500" mt={1}>
                {comparisonToAvg.toFixed(0)}% of market average
              </Text>
            </Box>
            
            {/* Social Media Icons */}
            <HStack mt={3} spacing={4} justify="center">
              <Icon 
                as={FaFacebook} 
                boxSize={6} 
                color={social.facebook ? "blue.500" : "gray.300"} 
                title={social.facebook ? "Facebook Page Available" : "No Facebook Page"}
              />
              <Icon 
                as={FaInstagram} 
                boxSize={6} 
                color={social.instagram ? "purple.500" : "gray.300"} 
                title={social.instagram ? "Instagram Account Available" : "No Instagram Account"}
              />
              <Icon 
                as={FaTwitter} 
                boxSize={6} 
                color={social.twitter ? "blue.400" : "gray.300"} 
                title={social.twitter ? "Twitter Account Available" : "No Twitter Account"}
              />
            </HStack>
          </Box>

          {/* Basic Info */}
          <VStack align="start" spacing={1} mt="auto" fontSize="sm">
            <HStack spacing={2}>
              <Icon as={FaGlobe} color="gray.500" boxSize={4} />
              <Text>
                {business.traffic_data?.metrics?.page_info?.status_code === 200 ? 
                  "Website Active" : "Website Issue"}
              </Text>
            </HStack>
            <HStack spacing={2}>
              <Icon as={FaStar} color="gray.500" boxSize={4} />
              <Text>{business.rating_count} reviews</Text>
            </HStack>
          </VStack>
        </VStack>
      </Box>
    );
  };

  const handleRowClick = (row) => {
    setSelectedBusiness(row);
    // Could open a modal or drawer to show details
    window.open(row.website_uri || row.maps_uri, '_blank');
  };

  return (
    <Box>
      
      {/* Market Overview Stats */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} mb={8}>
        <StatCard
          icon={FaGlobe}
          title="Total Monthly Visitors"
          value={totalMonthlyVisitors.toLocaleString()}
          description={`Across ${businessesWithTraffic.length} businesses`}
          colorScheme="blue"
        />
        <StatCard
          icon={FaChartLine}
          title="Average Monthly Visitors"
          value={avgMonthlyVisitors.toFixed(0)}
          description="Per business"
          colorScheme="green"
        />
        <StatCard
          icon={FaFireAlt}
          title="Median Monthly Visitors"
          value={medianMonthlyVisitors.toLocaleString()}
          description="Market middle point"
          colorScheme="purple"
        />
      </SimpleGrid>

      {/* Section Label */}
      <SectionLabel description="Measure online visibility through web traffic trends and digital engagement metrics across platforms.">Traffic Source and Engagement Metrics</SectionLabel>

      {/* Top Traffic Leaders Showcase */}
      <Box 
        bg={cardBg} 
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="lg"
        p={6}
        mb={8}
        shadow="md"
      >
        <SimpleGrid columns={{ base: 1, md: 2, lg: 5 }} spacing={4}>
          {top5Traffic.map((business, index) => (
            <Box 
              key={business.maps_uri}
              bg={statBg} 
              p={4} 
              borderRadius="lg" 
              textAlign="center"
              boxShadow="sm"
              transform={index === 0 ? "scale(1.05)" : "none"}
              zIndex={index === 0 ? 1 : "auto"}
              transition="all 0.2s"
              _hover={{ transform: 'translateY(-5px)', shadow: 'md' }}
            >
              <Icon 
                as={index === 0 ? FaTrophy : FaGlobe} 
                color={index === 0 ? "yellow.400" : "blue.400"} 
                boxSize={index === 0 ? 8 : 6} 
                mb={2} 
              />
              <Text 
                fontSize="sm" 
                fontWeight="medium" 
                color="gray.500" 
                mb={1}
              >
                {index === 0 ? "Champion" : `#${index + 1}`}
              </Text>
              <Heading 
                size="sm" 
                mb={2} 
                noOfLines={2}
                height="40px"
              >
                {business.name}
              </Heading>
              <Text 
                fontWeight="bold" 
                fontSize={index === 0 ? "2xl" : "xl"} 
                color={index === 0 ? "blue.500" : "gray.700"}
              >
                {(business.traffic_data?.metrics?.estimated_monthly_visitors || 0).toLocaleString()}
              </Text>
              <Text fontSize="xs" color="gray.500">monthly visitors</Text>
              <HStack mt={3} spacing={4} justify="center">
                {business.traffic_data?.metrics?.social?.facebook && (
                  <Icon as={FaFacebook} boxSize={4} color="blue.500" />
                )}
                {business.traffic_data?.metrics?.social?.instagram && (
                  <Icon as={FaInstagram} boxSize={4} color="purple.500" />
                )}
                {business.traffic_data?.metrics?.social?.twitter && (
                  <Icon as={FaTwitter} boxSize={4} color="blue.400" />
                )}
              </HStack>
            </Box>
          ))}
        </SimpleGrid>
      </Box>

      {/* Main Content Area with Tabs */}
      <Box 
        bg={cardBg} 
        borderRadius="lg" 
        shadow="md" 
        borderWidth="1px"
        borderColor={borderColor}
        mb={8} 
        overflow="hidden"
      >
        <Tabs>
          <TabList px={4} pt={4}>
            <Tab fontWeight="medium">Overview</Tab>
            <Tab fontWeight="medium">Businesses</Tab>
            <Tab fontWeight="medium">Trends</Tab>
            <Tab fontWeight="medium">Details</Tab>
          </TabList>

          <TabPanels>
            {/* Overview Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                {/* Traffic Distribution Chart */}
                <Box height="400px">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart 
                      data={trafficDistributionData}
                      margin={{ top: 5, right: 30, left: 20, bottom: 60 }}
                      animationDuration={1000}
                      animationBegin={0}
                      animationEasing="ease-out"
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
                      <XAxis 
                        dataKey="name" 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fill: '#4A5568', fontSize: 12 }}
                      />
                      <YAxis tick={{ fill: '#4A5568', fontSize: 12 }} />
                      <RechartsTooltip 
                        formatter={(value) => value.toLocaleString()}
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            return (
                              <Box 
                                bg="white" 
                                p={3} 
                                boxShadow="lg" 
                                border="1px" 
                                borderColor="gray.200" 
                                borderRadius="md"
                              >
                                <Text fontWeight="bold">{payload[0].payload.name}</Text>
                                <Text>
                                  {payload[0].value.toLocaleString()} visitors
                                </Text>
                                <Text color="orange.500">
                                  Market Average: {avgMonthlyVisitors.toLocaleString()}
                                </Text>
                              </Box>
                            );
                          }
                          return null;
                        }}
                      />
                      <Bar 
                        dataKey="visitors" 
                        cursor="pointer"
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={300}
                        animationEasing="ease-out"
                      >
                        {trafficDistributionData.map((entry, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={entry.fill} 
                          />
                        ))}
                      </Bar>
                      <ReferenceLine 
                        y={avgMonthlyVisitors} 
                        stroke="#ED8936" 
                        strokeDasharray="5 5"
                        strokeWidth={2}
                        label={{ 
                          value: 'Market Average', 
                          position: 'right',
                          fill: '#ED8936'
                        }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>

                {/* Traffic Leaders Pie Chart */}
                <Box height="400px">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={120}
                        paddingAngle={2}
                        dataKey="value"
                        label={({ name, value, percent }) => 
                          `${name} (${(percent * 100).toFixed(1)}%)`
                        }
                        labelLine={{ stroke: '#4A5568', strokeWidth: 1 }}
                        isAnimationActive={true}
                        animationBegin={300}
                        animationDuration={800}
                        animationEasing="ease-out"
                        cursor="pointer"
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                      </Pie>
                      <RechartsTooltip 
                        formatter={(value) => `${value.toLocaleString()} visitors`}
                        contentStyle={{
                          backgroundColor: 'white',
                          border: '1px solid #E2E8F0',
                          borderRadius: '4px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Businesses Panel */}
            <TabPanel>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
                spacing={6}
                alignItems="stretch"
              >
                {sortedBusinesses.map(business => (
                  <BusinessTrafficCard 
                    key={business.maps_uri} 
                    business={business} 
                  />
                ))}
              </SimpleGrid>
            </TabPanel>

            {/* Trends Panel */}
            <TabPanel>
              <Box>
                <Text color="gray.600" mb={6}>
                  Explore the relationship between review count and monthly web traffic
                </Text>
                <Box height="600px">
                  <ResponsiveContainer width="100%" height="100%">
                    <ScatterChart
                      margin={{ top: 20, right: 40, bottom: 60, left: 60 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
                      <XAxis 
                        type="number" 
                        dataKey="x" 
                        name="Total Reviews" 
                        unit=" reviews"
                        label={{ 
                          value: 'Total Reviews', 
                          position: 'bottom', 
                          offset: 40,
                          fill: '#4A5568'
                        }}
                        tick={{ fill: '#4A5568', fontSize: 12 }}
                      />
                      <YAxis 
                        type="number" 
                        dataKey="y" 
                        name="Monthly Visitors" 
                        unit=" visitors"
                        label={{ 
                          value: 'Monthly Visitors', 
                          angle: -90, 
                          position: 'left', 
                          offset: 40,
                          fill: '#4A5568'
                        }}
                        tick={{ fill: '#4A5568', fontSize: 12 }}
                      />
                      <RechartsTooltip 
                        cursor={{ strokeDasharray: '3 3' }}
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            const data = payload[0].payload;
                            return (
                              <Box 
                                bg="white" 
                                p={3} 
                                boxShadow="lg" 
                                border="1px" 
                                borderColor="gray.200" 
                                borderRadius="md"
                              >
                                <Text fontWeight="bold">{data.fullName}</Text>
                                <Text>Reviews: {data.x.toLocaleString()}</Text>
                                <Text>Monthly Visitors: {data.y.toLocaleString()}</Text>
                              </Box>
                            );
                          }
                          return null;
                        }}
                      />
                      <Scatter 
                        name="Businesses" 
                        data={xyPlotData} 
                        cursor="pointer"
                      >
                        {xyPlotData.map((entry, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={entry.fill} 
                            fillOpacity={0.8}
                            stroke={entry.fill}
                            strokeWidth={1}
                          />
                        ))}
                      </Scatter>
                      <ReferenceLine 
                        y={avgMonthlyVisitors} 
                        stroke="#ED8936" 
                        strokeDasharray="5 5"
                        strokeWidth={1}
                        label={{ 
                          value: 'Avg Visitors', 
                          position: 'right',
                          fill: '#ED8936',
                          fontSize: 11
                        }}
                      />
                    </ScatterChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </TabPanel>

            {/* Details Panel */}
            <TabPanel>
              <Box>
                <DataTable
                  columns={columns}
                  data={sortedBusinesses}
                  pagination
                  paginationPerPage={10}
                  sortable
                  striped
                  responsive
                  highlightOnHover
                  pointerOnHover
                  onRowClicked={handleRowClick}
                  defaultSortFieldId={1}
                  defaultSortAsc={false}
                  theme="default"
                  customStyles={{
                    rows: {
                      style: {
                        minHeight: '52px',
                        cursor: 'pointer',
                      },
                    },
                    headCells: {
                      style: {
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        fontWeight: 'bold',
                      },
                    },
                    cells: {
                      style: {
                        paddingLeft: '16px',
                        paddingRight: '16px',
                      },
                    },
                  }}
                />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default WebTrafficDashboard; 