import React, { useState, useMemo, useEffect } from 'react';
import { 
  Box, 
  Heading, 
  Text, 
  VStack, 
  HStack, 
  Badge, 
  Icon, 
  SimpleGrid, 
  Stat, 
  StatLabel, 
  StatNumber, 
  StatHelpText, 
  Divider, 
  Portal, 
  Tag, 
  TagLabel, 
  TagCloseButton, 
  Wrap, 
  WrapItem, 
  List, 
  UnorderedList, 
  ListItem, 
  Button,
  Flex,
  useColorModeValue,
  Avatar,
  AvatarGroup,
  Tooltip,
  Progress,
  Input,
  InputGroup,
  InputLeftElement,
  Grid,
  Stack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Select,
  Link,
  Spinner,
} from '@chakra-ui/react';
import { SectionLabel } from './DashboardWrapper';
import { 
  FaStar, 
  FaUsers, 
  FaGlobe, 
  FaFacebook, 
  FaInstagram, 
  FaTwitter, 
  FaCheck, 
  FaTimes, 
  FaMapMarkerAlt, 
  FaPhone, 
  FaGlobeAmericas, 
  FaChevronDown, 
  FaSearch,
  FaChartLine,
  FaComments,
  FaThumbsUp,
  FaHome,
  FaFilter,
  FaLightbulb,
  FaTrophy,
  FaInfoCircle
} from 'react-icons/fa';
import { logger } from '../../utils/logger';
import { LineChart, Line, ResponsiveContainer, Tooltip as RechartsTooltip, XAxis, YAxis } from 'recharts';
import { getYearData } from '../../services/yearService';
import { useNavigate } from 'react-router-dom';

const calculateThreeMonthGrowth = (monthlyReviewCounts = {}, months = []) => {
  if (months.length < 3) return 0;
  
  const lastThreeMonths = months.slice(-3);
  return lastThreeMonths.reduce((sum, month) => sum + (monthlyReviewCounts[month] || 0), 0);
};

const BusinessInsightsDashboard = ({ reviewsData }) => {
  // Move all useColorModeValue hooks to the top level
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const statBg = useColorModeValue('gray.50', 'gray.700');
  const searchBg = useColorModeValue('white', 'gray.700');
  const searchBorder = useColorModeValue('gray.200', 'gray.600');
  
  // React hooks for state
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [yearData, setYearData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [sortBy, setSortBy] = useState('growth');

  const navigate = useNavigate();

  // Fetch year data
  useEffect(() => {
    const fetchYearData = async () => {
      try {
        const data = await getYearData();
        setYearData(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching year data:', error);
        setIsLoading(false);
      }
    };

    fetchYearData();
  }, []);

  // Now the businessesWithYearData useMemo can use the function
  const businessesWithYearData = useMemo(() => {
    if (!reviewsData || !yearData.length) return null;

    const merged = reviewsData.map(business => {
      const yearInfo = yearData.find(yd => yd.maps_uri === business.maps_uri);
      return {
        ...business,
        historicalData: yearInfo?.data || {}
      };
    });

    return merged.sort((a, b) => {
      const growthA = calculateThreeMonthGrowth(
        a.historicalData?.monthlyReviewCounts,
        Object.keys(a.historicalData?.monthlyReviewCounts || {}).sort()
      );
      const growthB = calculateThreeMonthGrowth(
        b.historicalData?.monthlyReviewCounts,
        Object.keys(b.historicalData?.monthlyReviewCounts || {}).sort()
      );
      return growthB - growthA;
    });
  }, [reviewsData, yearData]);

  // Add an effect to handle data readiness
  useEffect(() => {
    if (businessesWithYearData && !isDataReady) {
      setIsDataReady(true);
    }
  }, [businessesWithYearData]);

  // Get all businesses
  const filteredBusinesses = useMemo(() => {
    if (!businessesWithYearData) return [];
    return businessesWithYearData;
  }, [businessesWithYearData]);

  const calculateSentimentScore = (sentimentData) => {
    if (!sentimentData) return 0;
    const total = sentimentData.positive + sentimentData.neutral + sentimentData.negative;
    return total > 0 ? (sentimentData.positive / total * 100) : 0;
  };

  // Update the sortedBusinesses useMemo to sort by growth
  const sortedBusinesses = useMemo(() => {
    if (!businessesWithYearData) return [];
    
    // Sort by growth
    return [...filteredBusinesses].sort((a, b) => {
      const growthA = calculateThreeMonthGrowth(
        a.historicalData?.monthlyReviewCounts,
        Object.keys(a.historicalData?.monthlyReviewCounts || {}).sort()
      );
      const growthB = calculateThreeMonthGrowth(
        b.historicalData?.monthlyReviewCounts,
        Object.keys(b.historicalData?.monthlyReviewCounts || {}).sort()
      );
      return growthB - growthA;
    });
  }, [businessesWithYearData, filteredBusinesses]);

  // Add an effect to handle the initial load
  useEffect(() => {
    if (sortedBusinesses.length > 0 && isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [sortedBusinesses, isInitialLoad]);

  // Get unique business types for filter dropdown
  const businessTypes = useMemo(() => {
    if (!reviewsData) return [];
    
    const types = new Set();
    reviewsData.forEach(business => {
      if (business.primary_type) {
        types.add(business.primary_type);
      }
    });
    
    return ['all', ...Array.from(types)];
  }, [reviewsData]);

  // Filter businesses based on search and type filter, then sort
  const filteredCardBusinesses = useMemo(() => {
    if (!sortedBusinesses.length) return [];
    
    // First filter businesses
    const filtered = sortedBusinesses.filter(business => {
      // Apply search filter
      const matchesSearch = searchQuery === '' || 
        business.name.toLowerCase().includes(searchQuery.toLowerCase());
      
      // Apply type filter
      const matchesType = selectedType === 'all' || 
        business.primary_type === selectedType;
      
      return matchesSearch && matchesType;
    });
    
    // Then sort based on selected criteria
    return [...filtered].sort((a, b) => {
      switch (sortBy) {
        case 'rating_count':
          return b.rating_count - a.rating_count;
        case 'rating_avg':
          return b.rating_avg - a.rating_avg;
        case 'growth':
        default:
          const growthA = calculateThreeMonthGrowth(
            a.historicalData?.monthlyReviewCounts,
            Object.keys(a.historicalData?.monthlyReviewCounts || {}).sort()
          );
          const growthB = calculateThreeMonthGrowth(
            b.historicalData?.monthlyReviewCounts,
            Object.keys(b.historicalData?.monthlyReviewCounts || {}).sort()
          );
          return growthB - growthA;
      }
    });
  }, [sortedBusinesses, searchQuery, selectedType, sortBy]);
  
  // Update the insights calculation
  const insights = useMemo(() => {
    if (!sortedBusinesses || !sortedBusinesses.length) return null;

    // Find top rated business
    const topRated = [...sortedBusinesses]
      .filter(b => b && typeof b.rating_avg === 'number')
      .sort((a, b) => b.rating_avg - a.rating_avg)[0];

    // Find most reviewed business
    const mostReviewed = [...sortedBusinesses]
      .filter(b => b && typeof b.rating_count === 'number')
      .sort((a, b) => b.rating_count - a.rating_count)[0];

    // Find highest growth business
    const highestGrowth = [...sortedBusinesses]
      .filter(b => b && b.historicalData?.monthlyReviewCounts)
      .sort((a, b) => {
        const aMonths = Object.keys(a.historicalData?.monthlyReviewCounts || {}).sort();
        const bMonths = Object.keys(b.historicalData?.monthlyReviewCounts || {}).sort();
        
        const aGrowth = calculateThreeMonthGrowth(a.historicalData?.monthlyReviewCounts, aMonths);
        const bGrowth = calculateThreeMonthGrowth(b.historicalData?.monthlyReviewCounts, bMonths);
        
        return bGrowth - aGrowth;
      })[0];

    // Find highest sentiment business
    const highestSentiment = [...sortedBusinesses]
      .filter(b => b && b.historicalData?.sentimentBreakdown)
      .sort((a, b) => {
        const aSentiment = calculateSentimentScore(a.historicalData?.sentimentBreakdown);
        const bSentiment = calculateSentimentScore(b.historicalData?.sentimentBreakdown);
        return bSentiment - aSentiment;
      })[0];

    if (!topRated || !mostReviewed || !highestGrowth || !highestSentiment) return null;

    return {
      topRated: {
        name: topRated.name,
        value: topRated.rating_avg.toFixed(1)
      },
      mostReviewed: {
        name: mostReviewed.name,
        value: mostReviewed.rating_count.toLocaleString()
      },
      highestGrowth: {
        name: highestGrowth.name,
        value: calculateThreeMonthGrowth(
          highestGrowth.historicalData?.monthlyReviewCounts,
          Object.keys(highestGrowth.historicalData?.monthlyReviewCounts || {}).sort()
        ).toFixed(0)
      },
      highestSentiment: {
        name: highestSentiment.name,
        value: (calculateSentimentScore(highestSentiment.historicalData?.sentimentBreakdown) * 100).toFixed(0)
      }
    };
  }, [sortedBusinesses]);

  if (!reviewsData || reviewsData.length === 0) {
    return (
      <Box p={4}>
        <Text>No business data available</Text>
      </Box>
    );
  }

  const handleBusinessToggle = (business) => {
    setSelectedBusiness(business);
    setIsDrawerOpen(true);
  };

  // Remove unused selection functions

  const SectionHeading = ({ children }) => (
    <HStack spacing={2} mb={4} mt={6}>
      <Divider flex={1} borderColor="gray.300" />
      <Text color="gray.600" fontSize="lg" fontWeight="bold" whiteSpace="nowrap">
        {children}
      </Text>
      <Divider flex={1} borderColor="gray.300" />
    </HStack>
  );

  const MetricItem = ({ icon, label, value, color = "gray.700" }) => (
    <HStack spacing={3}>
      <Icon as={icon} color={color} />
      <Text color="gray.600">{label}:</Text>
      <Text fontWeight="semibold" color={color}>{value}</Text>
    </HStack>
  );

  const SocialMediaStatus = ({ platform, isActive }) => (
    <HStack spacing={2}>
      <Icon 
        as={platform === 'Instagram' ? FaInstagram : platform === 'Facebook' ? FaFacebook : FaTwitter} 
        color={isActive ? (platform === 'Instagram' ? "purple.500" : platform === 'Facebook' ? "blue.500" : "blue.400") : "gray.300"} 
      />
      <Text color={isActive ? "green.500" : "red.500"} fontSize="sm">
        {platform}: {isActive ? "Active" : "Not Found"}
      </Text>
    </HStack>
  );

  const renderMarkdownLine = (line, index, lines) => {
    // Handle headings (##)
    if (line.startsWith('##')) {
      return (
        <Heading 
          key={index} 
          as="h2" 
          size="xl" 
          color="blue.700" 
          borderBottom="2px solid" 
          borderColor="blue.100" 
          pb={2} 
          mt={8} 
          mb={6}
        >
          {line.replace('##', '').trim()}
        </Heading>
      );
    }

    // Handle bullet points with bold sections
    if (line.startsWith('* **') && line.includes(':**')) {
      const [beforeColon, ...afterColon] = line.split(':**');
      const boldText = beforeColon.replace('* **', '').replace('**', '');
      const remainingText = afterColon.join(':**').trim();
      
      return (
        <UnorderedList key={index} ml={8} mb={2}>
          <ListItem color="gray.700" lineHeight="tall">
            <Text as="span" fontWeight="bold" color="blue.600">
              {boldText}:
            </Text>
            {' ' + remainingText}
          </ListItem>
        </UnorderedList>
      );
    }

    // Handle regular bullet points
    if (line.startsWith('*') && !line.includes('**')) {
      // Check if this is the first bullet point
      const isFirstBullet = index === 0 || !lines[index - 1]?.startsWith('*');
      
      // If it's the first bullet point, start a new UnorderedList
      if (isFirstBullet) {
        // Find all consecutive bullet points that don't contain **
        let bulletPoints = [];
        for (let i = index; i < lines.length; i++) {
          const currentLine = lines[i];
          if (!currentLine?.startsWith('*') || currentLine.includes('**')) {
            break;
          }
          bulletPoints.push(currentLine.replace(/^\*\s*/, ''));
        }
        
        if (bulletPoints.length > 0) {
          return (
            <UnorderedList key={index} ml={8} mb={4} spacing={2}>
              {bulletPoints.map((point, i) => (
                <ListItem key={`${index}-${i}`} color="gray.700" lineHeight="tall">
                  {point}
                </ListItem>
              ))}
            </UnorderedList>
          );
        }
      }
      // Skip rendering for subsequent bullet points as they're handled by the UnorderedList above
      return null;
    }

    // Handle regular text
    if (line.trim()) {
      return (
        <Text 
          key={index} 
          mb={4} 
          color="gray.700" 
          lineHeight="tall"
        >
          {line}
        </Text>
      );
    }

    return null;
  };

  const BusinessCard = ({ business, isSelected, onToggle, cardBg, borderColor, statBg }) => {
    // Add logging
    console.log('Business Data:', {
      name: business.name,
      historicalData: business.historicalData,
      monthlyReviewCounts: business.historicalData?.monthlyReviewCounts,
      monthlyRatings: business.historicalData?.monthlyRatings,
      sentimentBreakdown: business.historicalData?.sentimentBreakdown
    });

    // Get hover background color at component level
    const hoverBg = useColorModeValue('gray.50', 'gray.700');
    
    // Extract competitive advantages and quick wins from insights
    const insightSections = useMemo(() => {
      if (!business.insight) return { competitiveAdvantages: [], quickWins: [] };
      
      const lines = business.insight.split('\n');
      const sections = {
        competitiveAdvantages: [],
        quickWins: []
      };
      
      let currentSection = null;
      
      for (const line of lines) {
        if (line.includes('Competitive Advantages')) {
          currentSection = 'competitiveAdvantages';
          continue;
        } else if (line.includes('Quick Wins')) {
          currentSection = 'quickWins';
          continue;
        }
        
        if (currentSection && line.trim().startsWith('*')) {
          // Process bolded text - look for patterns like "**Bold Text:**"
          const content = line.trim().replace(/^\*\s*/, '');
          
          // Check for any bold text pattern in the form "**Text:**"
          const boldRegex = /\*\*([^*]+)\*\*:/;
          const boldMatch = content.match(boldRegex);
          
          if (boldMatch) {
            // Split the content at the bold text
            const parts = content.split(boldRegex);
            const beforeBold = parts[0] || '';
            const boldText = boldMatch[1];
            const afterBold = parts[2] || '';
            
            sections[currentSection].push({
              hasBold: true,
              beforeBold: beforeBold,
              boldText: boldText,
              afterBold: afterBold
            });
          } else {
            // Check for any general bold text pattern "**Text**"
            const parts = [];
            let lastIndex = 0;
            let boldTexts = [];
            
            // Find all bold text patterns
            const generalBoldRegex = /\*\*([^*]+)\*\*/g;
            let match;
            let hasBold = false;
            
            while ((match = generalBoldRegex.exec(content)) !== null) {
              hasBold = true;
              // Add text before the bold part
              if (match.index > lastIndex) {
                parts.push({
                  type: 'text',
                  content: content.substring(lastIndex, match.index)
                });
              }
              
              // Add the bold part
              parts.push({
                type: 'bold',
                content: match[1]
              });
              
              lastIndex = match.index + match[0].length;
            }
            
            // Add any remaining text after the last bold part
            if (lastIndex < content.length) {
              parts.push({
                type: 'text',
                content: content.substring(lastIndex)
              });
            }
            
            if (hasBold) {
              sections[currentSection].push({
                hasBold: true,
                parts: parts
              });
            } else {
              // No bold text found
              sections[currentSection].push({
                hasBold: false,
                text: content
              });
            }
          }
        }
      }
      
      return sections;
    }, [business.insight]);

    // Process monthly data
    const monthlyData = useMemo(() => {
      if (!business.historicalData?.monthlyReviewCounts) return [];
      
      const data = Object.entries(business.historicalData.monthlyReviewCounts)
        .map(([month, count]) => ({
          date: new Date(month).getTime(),  // Convert YYYY-MM to timestamp
          count: count,
          originalMonth: month // Keep original month string for debugging
        }))
        .sort((a, b) => a.date - b.date)  // Sort chronologically
        .slice(-12);  // Get last 12 months

      console.log('Processed Monthly Data:', data);
      return data;
    }, [business]);

    // Calculate growth metrics
    const metrics = useMemo(() => {
      if (!monthlyData || monthlyData.length < 2) {
        return {
          growth: 0,
          trend: 'neutral'
        };
      }

      const totalReviews = calculateThreeMonthGrowth(
        business.historicalData?.monthlyReviewCounts,
        Object.keys(business.historicalData?.monthlyReviewCounts || {}).sort()
      );

      return {
        growth: totalReviews,
        trend: totalReviews > 0 ? 'increase' : 'neutral'
      };
    }, [monthlyData, business.historicalData]);

    return (
      <Tooltip 
        hasArrow
        isDisabled={insightSections.competitiveAdvantages.length === 0 && insightSections.quickWins.length === 0}
        label={
          <VStack align="stretch" p={4} maxW="400px" spacing={4}>
            {insightSections.competitiveAdvantages.length > 0 && (
              <Box>
                <HStack mb={2} spacing={2}>
                  <Icon as={FaTrophy} color="yellow.400" />
                  <Heading size="sm" color="blue.600">Competitive Advantages</Heading>
                </HStack>
                <UnorderedList spacing={1} pl={2}>
                  {insightSections.competitiveAdvantages.map((advantage, i) => (
                    <ListItem key={i}>
                      {advantage.hasBold ? (
                        advantage.parts ? (
                          <>
                            {advantage.parts.map((part, j) => (
                              part.type === 'bold' ? 
                                <Text as="span" fontWeight="bold" key={j}>{part.content}</Text> : 
                                <Text as="span" key={j}>{part.content}</Text>
                            ))}
                          </>
                        ) : (
                          <>
                            {advantage.beforeBold}
                            <Text as="span" fontWeight="bold">{advantage.boldText}</Text>:
                            {advantage.afterBold}
                          </>
                        )
                      ) : (
                        advantage.text
                      )}
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            )}
            
            {insightSections.quickWins.length > 0 && (
              <Box>
                <HStack mb={2} spacing={2}>
                  <Icon as={FaLightbulb} color="yellow.400" />
                  <Heading size="sm" color="blue.600">Quick Wins</Heading>
                </HStack>
                <UnorderedList spacing={1} pl={2}>
                  {insightSections.quickWins.map((win, i) => (
                    <ListItem key={i}>
                      {win.hasBold ? (
                        win.parts ? (
                          <>
                            {win.parts.map((part, j) => (
                              part.type === 'bold' ? 
                                <Text as="span" fontWeight="bold" key={j}>{part.content}</Text> : 
                                <Text as="span" key={j}>{part.content}</Text>
                            ))}
                          </>
                        ) : (
                          <>
                            {win.beforeBold}
                            <Text as="span" fontWeight="bold">{win.boldText}</Text>:
                            {win.afterBold}
                          </>
                        )
                      ) : (
                        win.text
                      )}
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            )}
          </VStack>
        }
        placement="right"
        bg={cardBg}
        color="gray.700"
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="md"
        boxShadow="xl"
        openDelay={100}
        gutter={20}
      >
      <Box
        key={business.maps_uri}
        bg={cardBg}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="lg"
        overflow="hidden"
        p={4}
        cursor="pointer"
        onClick={() => onToggle(business)}
        transition="all 0.2s"
        _hover={{ 
          transform: 'translateY(-4px)',
          shadow: 'lg',
          borderColor: 'blue.400',
          bg: hoverBg
        }}
        role="button"
        aria-label={`View details for ${business.name}`}
        position="relative"
        height="100%"
        minW="300px"
        w="100%"
      >
        {(insightSections.competitiveAdvantages.length > 0 || insightSections.quickWins.length > 0) && (
          <Box 
            position="absolute" 
            top={2} 
            right={2} 
            p={1}
            borderRadius="full"
            bg={insightSections.competitiveAdvantages.length > 0 ? "yellow.100" : "blue.100"}
            boxSize="22px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            title={`${insightSections.competitiveAdvantages.length > 0 ? 'Competitive Advantages' : ''} ${insightSections.quickWins.length > 0 ? 'Quick Wins' : ''}`}
          >
            <Icon 
              as={insightSections.competitiveAdvantages.length > 0 ? FaTrophy : FaLightbulb} 
              color={insightSections.competitiveAdvantages.length > 0 ? "yellow.600" : "blue.600"} 
              boxSize={3} 
            />
          </Box>
        )}
        <VStack align="stretch" spacing={4} height="100%">
          <Box flex="1">
            <Heading
              size="md"
              mb={2}
              lineHeight="1.4"
              minHeight="2.8em"  // Allows for 2 lines of text
              overflow="hidden"
              css={{
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
              title={business.name}  // Shows full title on hover
            >
              {business.name}
            </Heading>
            <HStack spacing={2} mb={2} justify="space-between" align="center">
              <Badge colorScheme="blue">
                {business.primary_type.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              </Badge>
              <HStack spacing={2} alignItems="flex-start">
                <Icon as={FaStar} color="yellow.400" boxSize={4} mt={1} />
                <Text fontWeight="bold">{business.rating_avg}</Text>
                <Text color="gray.500">({parseInt(business.rating_count).toLocaleString()})</Text>
              </HStack>
            </HStack>
          </Box>

          {/* Contact Info */}
          <VStack align="start" spacing={2} fontSize="sm">
            <HStack spacing={2} alignItems="flex-start">
              <Icon as={FaMapMarkerAlt} color="gray.500" boxSize={4} mt={1} />
              <Link 
                href={business.maps_uri} 
                isExternal 
                color="blue.500"
                onClick={(e) => e.stopPropagation()}
                noOfLines={1}
              >
                {business.address}
              </Link>
            </HStack>
            {business.phone && (
              <HStack spacing={2} alignItems="flex-start">
                <Icon as={FaPhone} color="gray.500" boxSize={4} mt={1} />
                <Text color="gray.600">{business.phone}</Text>
              </HStack>
            )}
            {business.website_uri && (
              <HStack spacing={2} alignItems="flex-start">
                <Icon as={FaGlobeAmericas} color="gray.500" boxSize={4} mt={1} />
                <Link
                  href={business.website_uri}
                  isExternal
                  color="blue.500"
                  onClick={(e) => e.stopPropagation()}
                >
                  Website
                </Link>
              </HStack>
            )}
          </VStack>

          {/* Chart */}
          <Box height="100px" mt={2}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={monthlyData}>
                <Line 
                  type="monotone" 
                  dataKey="count" 
                  stroke="#805AD5" 
                  strokeWidth={2}
                  dot={false} 
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>

          {/* Stats Section */}
          <HStack justify="space-between" mt={2}>
            <Stat bg={statBg} p={2} borderRadius="md" flex={1}>
              <StatLabel fontSize="xs">3-Month Reviews</StatLabel>
              <StatNumber fontSize="lg" color={metrics.trend === 'increase' ? 'green.500' : 'gray.500'}>
                {metrics.growth}
              </StatNumber>
            </Stat>
            
            {business.historicalData?.sentimentBreakdown && (
              <Stat bg={statBg} p={2} borderRadius="md" flex={1}>
                <StatLabel fontSize="xs">Sentiment</StatLabel>
                <StatNumber fontSize="lg" color="blue.500">
                  {((business.historicalData.sentimentBreakdown.positive / 
                    (business.historicalData.sentimentBreakdown.positive + 
                     business.historicalData.sentimentBreakdown.neutral + 
                     business.historicalData.sentimentBreakdown.negative)) * 100).toFixed(0)}%
                </StatNumber>
              </Stat>
            )}
          </HStack>
        </VStack>
      </Box>
      </Tooltip>
    );
  };

  const MetricCard = ({ icon, label, value, change }) => {
    const bgColor = useColorModeValue('gray.50', 'gray.700');
    const isPositive = change?.startsWith('+');

    return (
      <Box p={4} bg={bgColor} borderRadius="lg">
        <VStack spacing={1} align="flex-start">
          <Icon as={icon} color="blue.500" />
          <Text fontSize="sm" color="gray.500">{label}</Text>
          <HStack spacing={2} align="baseline">
            <Text fontSize="xl" fontWeight="bold">{value}</Text>
            {change && (
              <Text 
                fontSize="sm" 
                color={isPositive ? "green.500" : "red.500"}
              >
                {change}
              </Text>
            )}
          </HStack>
        </VStack>
      </Box>
    );
  };

  const PerformanceBar = ({ label, value, colorScheme }) => (
    <Box w="100%">
      <Flex justify="space-between" mb={1}>
        <Text fontSize="sm">{label}</Text>
        <Text fontSize="sm" fontWeight="bold">{value}%</Text>
      </Flex>
      <Progress 
        value={value} 
        colorScheme={colorScheme} 
        borderRadius="full" 
        size="sm"
      />
    </Box>
  );


  return (
    <Box>
      <VStack spacing={6} align="stretch">
        {/* Search and Filter Section - Centered */}
        <Box mb={8}>
          <Flex 
            spacing={4} 
            align="center" 
            w="full" 
            maxW="900px" 
            mx="auto" 
            gap={4}
          >
            <InputGroup size="lg" flex="1">
              <InputLeftElement pointerEvents="none" h="full">
                <Icon as={FaSearch} color="gray.400" boxSize={5} />
              </InputLeftElement>
              <Input
                placeholder="Search businesses by name or type..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                bg={searchBg}
                borderColor={searchBorder}
                fontSize="lg"
                h="56px"
                _hover={{ borderColor: 'blue.400' }}
                _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)' }}
              />
            </InputGroup>

            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              bg={searchBg}
              borderColor={searchBorder}
              size="lg"
              h="56px"
              fontSize="lg"
              width="auto"
              minW="180px"
              _hover={{ borderColor: 'blue.400' }}
              _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)' }}
            >
              <option value="growth">Hottest 🔥</option>
              <option value="rating_count">Most Popular 👥</option>
              <option value="rating_avg">Top Rated ⭐</option>
            </Select>
          </Flex>
        </Box>
        
        {/* Section Label */}
        <SectionLabel description="Comprehensive overview of key business metrics with actionable insights for strategic decision-making.">Business Intelligence and Metrics</SectionLabel>

        {/* Business Cards Grid */}
        {!isDataReady ? (
          <Flex justify="center" align="center" minH="200px">
            <Spinner 
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        ) : (
          <Box maxW="100%" mx="auto">
            <SimpleGrid 
              columns={{ 
                base: 1, 
                md: filteredCardBusinesses.length === 1 ? 1 : 2, 
                lg: filteredCardBusinesses.length === 1 ? 1 : (filteredCardBusinesses.length === 2 ? 2 : 3), 
                xl: filteredCardBusinesses.length < 4 ? filteredCardBusinesses.length : 4 
              }} 
              spacing={6} 
              maxW="100%" 
              alignItems="stretch"
              sx={{
                '& > *': {
                  transition: 'all 0.3s ease-in-out',
                  opacity: 1,
                  transform: 'scale(1)',
                },
                '@keyframes fadeInScale': {
                  '0%': {
                    opacity: 0,
                    transform: 'scale(0.95)',
                  },
                  '100%': {
                    opacity: 1,
                    transform: 'scale(1)',
                  },
                },
                '& > *:not(:first-of-type)': {
                  animation: 'fadeInScale 0.3s ease-in-out',
                }
              }}
            >
              {filteredCardBusinesses.length ? (
                filteredCardBusinesses.map((business, index) => (
                  <Box 
                    key={business.maps_uri}
                    ml={filteredCardBusinesses.length === 1 ? 0 : 'auto'}
                    mr={filteredCardBusinesses.length === 1 ? 'auto' : 'auto'}
                  >
                    <BusinessCard
                      business={business}
                      isSelected={selectedBusiness?.maps_uri === business.maps_uri}
                      onToggle={handleBusinessToggle}
                      cardBg={cardBg}
                      borderColor={borderColor}
                      statBg={statBg}
                    />
                  </Box>
                ))
              ) : (
                <Flex justify="center" width="100%" p={8} gridColumn="1 / -1">
                  <Text color="gray.500">No businesses match your search criteria.</Text>
                </Flex>
              )}
            </SimpleGrid>
          </Box>
        )}
      </VStack>

      {/* Add the Drawer */}
      <Drawer
        isOpen={isDrawerOpen}
        placement="right"
        onClose={() => setIsDrawerOpen(false)}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {selectedBusiness?.name}
          </DrawerHeader>

          <DrawerBody>
            {selectedBusiness && (
              <VStack align="stretch" spacing={6}>
                {/* Business Overview */}
                <Box>
                  <HStack spacing={4} mb={4} justify="space-between" align="center">
                    <Badge colorScheme="blue" px={2} py={1} borderRadius="full">
                      {selectedBusiness.primary_type.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                    </Badge>
                    <HStack spacing={2} alignItems="flex-start">
                      <Icon as={FaStar} color="yellow.400" boxSize={5} mt={1} />
                      <Text fontWeight="bold">{selectedBusiness.rating_avg}</Text>
                      <Text color="gray.500">({selectedBusiness.rating_count} reviews)</Text>
                    </HStack>
                  </HStack>

                  {/* Contact Information */}
                  <VStack align="stretch" spacing={2} mb={6}>
                    <HStack spacing={2} alignItems="flex-start">
                      <Icon as={FaMapMarkerAlt} color="gray.500" boxSize={4} mt={1} />
                      <Link 
                        href={selectedBusiness.maps_uri} 
                        isExternal 
                        color="blue.500"
                      >
                        {selectedBusiness.address}
                      </Link>
                    </HStack>
                    {selectedBusiness.phone && (
                      <HStack spacing={2} alignItems="flex-start">
                        <Icon as={FaPhone} color="gray.500" boxSize={4} mt={1} />
                        <Text>{selectedBusiness.phone}</Text>
                      </HStack>
                    )}
                    {selectedBusiness.website_uri && (
                      <HStack spacing={2} alignItems="flex-start">
                        <Icon as={FaGlobeAmericas} color="gray.500" boxSize={4} mt={1} />
                        <Link 
                          href={selectedBusiness.website_uri} 
                          isExternal 
                          color="blue.500"
                        >
                          Website
                        </Link>
                      </HStack>
                    )}
                  </VStack>

                  {/* Business Insights */}
                  <Box>
                    <Heading size="md" mb={4}>Business Insights</Heading>
                    
                    {/* Extract sections for direct access */}
                    {selectedBusiness.insight && (
                      <VStack align="stretch" spacing={6} mb={6}>
                        {/* Competitive Advantages Section */}
                        {selectedBusiness.insight.includes('Competitive Advantages') && (
                          <Box 
                            p={4} 
                            borderWidth="1px" 
                            borderColor="yellow.200" 
                            borderRadius="md" 
                            bg="yellow.50"
                          >
                            <HStack mb={3} spacing={2}>
                              <Icon as={FaTrophy} color="yellow.500" boxSize={5} />
                              <Heading size="sm" color="yellow.700">Competitive Advantages</Heading>
                            </HStack>
                            <UnorderedList spacing={2} pl={2}>
                              {selectedBusiness.insight
                                .split('\n')
                                .filter(line => 
                                  line.trim().startsWith('*') && 
                                  selectedBusiness.insight.indexOf(line) > 
                                    selectedBusiness.insight.indexOf('Competitive Advantages') &&
                                  (selectedBusiness.insight.indexOf('Quick Wins') === -1 || 
                                    selectedBusiness.insight.indexOf(line) < 
                                    selectedBusiness.insight.indexOf('Quick Wins'))
                                )
                                .map((line, i) => {
                                  const content = line.trim().replace(/^\*\s*/, '');
                                  
                                  // Check for the "**Text:**" pattern
                                  const boldRegex = /\*\*([^*]+)\*\*:/;
                                  const boldMatch = content.match(boldRegex);
                                  
                                  if (boldMatch) {
                                    // Split the content at the bold text
                                    const parts = content.split(boldRegex);
                                    const beforeBold = parts[0] || '';
                                    const boldText = boldMatch[1];
                                    const afterBold = parts[2] || '';
                                    
                                    return (
                                      <ListItem key={i} color="gray.700">
                                        {beforeBold}
                                        <Text as="span" fontWeight="bold">{boldText}</Text>:
                                        {afterBold}
                                      </ListItem>
                                    );
                                  } else {
                                    // Check for any general bold text patterns "**Text**"
                                    const generalBoldRegex = /\*\*([^*]+)\*\*/g;
                                    let match;
                                    let lastIndex = 0;
                                    let parts = [];
                                    let hasBold = false;
                                    
                                    while ((match = generalBoldRegex.exec(content)) !== null) {
                                      hasBold = true;
                                      // Add text before the bold part
                                      if (match.index > lastIndex) {
                                        parts.push({
                                          type: 'text',
                                          content: content.substring(lastIndex, match.index)
                                        });
                                      }
                                      
                                      // Add the bold part
                                      parts.push({
                                        type: 'bold',
                                        content: match[1]
                                      });
                                      
                                      lastIndex = match.index + match[0].length;
                                    }
                                    
                                    // Add any remaining text after the last bold part
                                    if (lastIndex < content.length) {
                                      parts.push({
                                        type: 'text',
                                        content: content.substring(lastIndex)
                                      });
                                    }
                                    
                                    if (hasBold) {
                                      return (
                                        <ListItem key={i} color="gray.700">
                                          {parts.map((part, j) => (
                                            part.type === 'bold' ? 
                                              <Text as="span" fontWeight="bold" key={j}>{part.content}</Text> : 
                                              <Text as="span" key={j}>{part.content}</Text>
                                          ))}
                                        </ListItem>
                                      );
                                    } else {
                                      // No bold text
                                      return (
                                        <ListItem key={i} color="gray.700">{content}</ListItem>
                                      );
                                    }
                                  }
                                })
                              }
                            </UnorderedList>
                          </Box>
                        )}
                        
                        {/* Quick Wins Section */}
                        {selectedBusiness.insight.includes('Quick Wins') && (
                          <Box 
                            p={4} 
                            borderWidth="1px" 
                            borderColor="blue.200" 
                            borderRadius="md" 
                            bg="blue.50"
                          >
                            <HStack mb={3} spacing={2}>
                              <Icon as={FaLightbulb} color="blue.500" boxSize={5} />
                              <Heading size="sm" color="blue.700">Quick Wins</Heading>
                            </HStack>
                            <UnorderedList spacing={2} pl={2}>
                              {selectedBusiness.insight
                                .split('\n')
                                .filter(line => 
                                  line.trim().startsWith('*') && 
                                  selectedBusiness.insight.indexOf(line) > 
                                    selectedBusiness.insight.indexOf('Quick Wins')
                                )
                                .map((line, i) => {
                                  const content = line.trim().replace(/^\*\s*/, '');
                                  
                                  // Check for the "**Text:**" pattern
                                  const boldRegex = /\*\*([^*]+)\*\*:/;
                                  const boldMatch = content.match(boldRegex);
                                  
                                  if (boldMatch) {
                                    // Split the content at the bold text
                                    const parts = content.split(boldRegex);
                                    const beforeBold = parts[0] || '';
                                    const boldText = boldMatch[1];
                                    const afterBold = parts[2] || '';
                                    
                                    return (
                                      <ListItem key={i} color="gray.700">
                                        {beforeBold}
                                        <Text as="span" fontWeight="bold">{boldText}</Text>:
                                        {afterBold}
                                      </ListItem>
                                    );
                                  } else {
                                    // Check for any general bold text patterns "**Text**"
                                    const generalBoldRegex = /\*\*([^*]+)\*\*/g;
                                    let match;
                                    let lastIndex = 0;
                                    let parts = [];
                                    let hasBold = false;
                                    
                                    while ((match = generalBoldRegex.exec(content)) !== null) {
                                      hasBold = true;
                                      // Add text before the bold part
                                      if (match.index > lastIndex) {
                                        parts.push({
                                          type: 'text',
                                          content: content.substring(lastIndex, match.index)
                                        });
                                      }
                                      
                                      // Add the bold part
                                      parts.push({
                                        type: 'bold',
                                        content: match[1]
                                      });
                                      
                                      lastIndex = match.index + match[0].length;
                                    }
                                    
                                    // Add any remaining text after the last bold part
                                    if (lastIndex < content.length) {
                                      parts.push({
                                        type: 'text',
                                        content: content.substring(lastIndex)
                                      });
                                    }
                                    
                                    if (hasBold) {
                                      return (
                                        <ListItem key={i} color="gray.700">
                                          {parts.map((part, j) => (
                                            part.type === 'bold' ? 
                                              <Text as="span" fontWeight="bold" key={j}>{part.content}</Text> : 
                                              <Text as="span" key={j}>{part.content}</Text>
                                          ))}
                                        </ListItem>
                                      );
                                    } else {
                                      // No bold text
                                      return (
                                        <ListItem key={i} color="gray.700">{content}</ListItem>
                                      );
                                    }
                                  }
                                })
                              }
                            </UnorderedList>
                          </Box>
                        )}
                      </VStack>
                    )}
                    
                    {/* Full Insights */}
                    <Box>
                      {selectedBusiness.insight ? 
                        selectedBusiness.insight.split('\n').map((line, index, lines) => 
                          renderMarkdownLine(line, index, lines)
                        )
                        : 'No insights available'
                      }
                    </Box>
                  </Box>
                </Box>
              </VStack>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default BusinessInsightsDashboard; 