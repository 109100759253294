import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Badge,
  Icon,
  Divider,
  Flex,
  SimpleGrid,
  Progress,
  Tooltip,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  FormControl,
  FormLabel,
  Button,
  Textarea,
  Input,
  Select,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import {
  FaRoad,
  FaCheckCircle,
  FaClock,
  FaExclamationTriangle,
  FaLightbulb,
  FaBullseye,
  FaRocket,
  FaCode,
  FaTools,
  FaChartLine,
  FaGlobe,
  FaBrain,
  FaDatabase,
  FaMobileAlt,
  FaUsers,
  FaChartBar,
  FaSearch,
  FaComment,
  FaEnvelope,
  FaPaperPlane,
  FaStar,
  FaThumbsUp,
  FaQuestionCircle,
  FaFeather,
} from 'react-icons/fa';

// Define roadmap phases with different statuses
const phases = [
  {
    id: 'current',
    name: 'Current Development',
    description: 'Features actively being built right now',
    color: 'blue',
  },
  {
    id: 'planned',
    name: 'Upcoming Features',
    description: 'Next in the pipeline for near future',
    color: 'purple',
  },
  {
    id: 'future',
    name: 'Future Vision',
    description: 'Long-term roadmap items',
    color: 'teal',
  },
];

// Define feature list with detailed information
const roadmapFeatures = [
  {
    title: 'Enhanced Sentiment Analysis',
    description: 'Improving accuracy of sentiment detection with advanced ML models',
    phase: 'current',
    progress: 70,
    eta: 'July 30, 2024',
    icon: FaBrain,
    subFeatures: [
      'Fine-tuning sentiment classification models',
      'Better handling of neutral statements',
      'Contextual understanding improvements'
    ]
  },
  {
    title: 'Real-time Analytics Dashboard',
    description: 'Live updates and real-time data processing for business insights',
    phase: 'current',
    progress: 90,
    eta: 'July 28, 2024',
    icon: FaChartLine,
    subFeatures: [
      'Live data streaming implementation',
      'Real-time charts and visualizations',
      'Performance optimizations for speed'
    ]
  },
  {
    title: 'Advanced Search & Filtering',
    description: 'Powerful search capabilities with complex filters and sorting options',
    phase: 'current',
    progress: 50,
    eta: 'August 5, 2024',
    icon: FaSearch,
    subFeatures: [
      'Advanced filter combinations',
      'Quick search shortcuts',
      'Search history and favorites'
    ]
  },
  {
    title: 'Customizable Reports',
    description: 'User-defined report templates with custom metrics and visualizations',
    phase: 'planned',
    progress: 20,
    eta: 'August 15, 2024',
    icon: FaTools,
    subFeatures: [
      'Report template builder',
      'Scheduled report generation',
      'Export to multiple formats (PDF, Excel, CSV)'
    ]
  },
  {
    title: 'Competitor Analysis Tools',
    description: 'Compare metrics against competitor benchmarks and industry standards',
    phase: 'planned',
    progress: 10,
    eta: 'August 25, 2024',
    icon: FaChartBar,
    subFeatures: [
      'Competitor performance tracking',
      'Industry benchmark comparisons',
      'Relative strength analysis'
    ]
  },
  {
    title: 'Mobile App Experience',
    description: 'Native mobile applications for iOS and Android platforms',
    phase: 'planned',
    progress: 5,
    eta: 'September 2024',
    icon: FaMobileAlt,
    subFeatures: [
      'Native iOS application',
      'Native Android application',
      'Push notifications for important insights'
    ]
  },
  {
    title: 'AI-Powered Recommendations',
    description: 'Actionable business recommendations based on data analysis',
    phase: 'future',
    progress: 0,
    eta: 'Q4 2024',
    icon: FaLightbulb,
    subFeatures: [
      'Automated opportunity detection',
      'Risk prediction algorithms',
      'Business strategy suggestions'
    ]
  },
  {
    title: 'Predictive Analytics',
    description: 'Future trend predictions using historical data and machine learning',
    phase: 'future',
    progress: 0,
    eta: 'Q4 2024',
    icon: FaRocket,
    subFeatures: [
      'Time-series forecasting models',
      'Predictive customer behavior analysis',
      'Market trend predictions'
    ]
  },
  {
    title: 'Integration Ecosystem',
    description: 'Seamless connections with external platforms and services',
    phase: 'future',
    progress: 0,
    eta: 'Q1 2025',
    icon: FaGlobe,
    subFeatures: [
      'API ecosystem development',
      'Third-party integration marketplace',
      'Custom webhook support'
    ]
  },
];

// Feature card component
const FeatureCard = ({ feature }) => {
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const subtitleColor = useColorModeValue('gray.600', 'gray.400');
  
  // Determine badge color based on progress
  let badgeColor;
  if (feature.progress === 100) badgeColor = 'green';
  else if (feature.progress >= 70) badgeColor = 'blue';
  else if (feature.progress >= 30) badgeColor = 'yellow';
  else badgeColor = 'purple';
  
  return (
    <Card 
      bg={cardBg} 
      borderWidth="1px" 
      borderRadius="lg" 
      borderColor={borderColor}
      boxShadow="sm"
      overflow="hidden"
      transition="all 0.2s"
      _hover={{ transform: 'translateY(-4px)', shadow: 'md' }}
    >
      <CardBody>
        <HStack spacing={4} mb={4} align="flex-start">
          <Flex
            w="40px"
            h="40px"
            bg={`${badgeColor}.50`}
            color={`${badgeColor}.500`}
            borderRadius="lg"
            justify="center"
            align="center"
            flexShrink={0}
          >
            <Icon as={feature.icon} boxSize={5} />
          </Flex>
          <VStack align="start" spacing={1} flex="1">
            <HStack>
              <Heading size="md" fontWeight="bold">{feature.title}</Heading>
              <Badge colorScheme={badgeColor} px={2} borderRadius="full">
                {feature.progress === 100 ? 'Complete' : `${feature.progress}%`}
              </Badge>
            </HStack>
            <Text color={subtitleColor} fontSize="sm">{feature.description}</Text>
          </VStack>
        </HStack>
        
        <Box mb={3}>
          <Progress
            value={feature.progress}
            size="sm"
            colorScheme={badgeColor}
            borderRadius="full"
            hasStripe={feature.progress < 100}
            isAnimated={feature.progress < 100}
          />
        </Box>
        
        <VStack align="start" spacing={3} mt={4}>
          <HStack>
            <Icon as={FaClock} color="gray.500" />
            <Text fontSize="sm" color={subtitleColor}>
              <Text as="span" fontWeight="bold">Target:</Text> {feature.eta}
            </Text>
          </HStack>
          
          <List spacing={1} width="100%">
            {feature.subFeatures.map((subFeature, index) => (
              <ListItem key={index} fontSize="sm">
                <ListIcon 
                  as={FaCheckCircle} 
                  color={feature.progress > (index + 1) * (100 / (feature.subFeatures.length + 1)) ? 'green.500' : 'gray.300'} 
                />
                {subFeature}
              </ListItem>
            ))}
          </List>
        </VStack>
      </CardBody>
    </Card>
  );
};

// Phase section component
const PhaseSection = ({ phase, features }) => {
  return (
    <Box mb={12}>
      <HStack mb={6} spacing={3}>
        <Badge colorScheme={phase.color} px={3} py={1} borderRadius="full" fontSize="md">
          {phase.name}
        </Badge>
        <Text color="gray.600" fontSize="md">{phase.description}</Text>
      </HStack>
      
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {features
          .filter(feature => feature.phase === phase.id)
          .map(feature => (
            <FeatureCard key={feature.title} feature={feature} />
          ))}
      </SimpleGrid>
    </Box>
  );
};

// Feedback form component
const FeedbackForm = () => {
  const [feedbackType, setFeedbackType] = useState('feature_request');
  const [priority, setPriority] = useState('medium');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const toast = useToast();
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const subtitleColor = useColorModeValue('gray.600', 'gray.400');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate inputs
    if (!name || !email || !message) {
      toast({
        title: 'Missing information',
        description: 'Please fill in all required fields',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast({
        title: 'Invalid email',
        description: 'Please enter a valid email address',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    // Submit form
    setIsSubmitting(true);
    
    // Create email feedback data
    const feedbackData = {
      feedbackType,
      priority,
      name,
      email,
      subject: subject || `[${feedbackType.replace('_', ' ').toUpperCase()}] Feedback from ${name}`,
      message
    };
    
    // In a real implementation, this would send data to team@localytics.co
    // Here we'll simulate the API call with a delay
    setTimeout(() => {
      console.log('Sending feedback to team@localytics.co:', feedbackData);
      
      setIsSubmitting(false);
      setIsSubmitted(true);
      
      toast({
        title: 'Feedback submitted',
        description: 'Thank you for your feedback! Your message has been sent to team@localytics.co',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      
      // Reset form after successful submission
      setTimeout(() => {
        setFeedbackType('feature_request');
        setPriority('medium');
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
        setIsSubmitted(false);
      }, 5000);
    }, 1500);
  };
  
  if (isSubmitted) {
    return (
      <Card bg={cardBg} borderWidth="1px" borderColor={borderColor} borderRadius="lg" boxShadow="md" p={2} w="full">
        <CardBody>
          <Alert
            status="success"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            py={6}
            borderRadius="md"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Feedback Submitted!
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              Thank you for your feedback! Your message has been sent to team@localytics.co. 
              Your input helps us shape our roadmap and improve the platform for everyone.
            </AlertDescription>
          </Alert>
        </CardBody>
      </Card>
    );
  }
  
  return (
    <Card bg={cardBg} borderWidth="1px" borderColor={borderColor} borderRadius="lg" boxShadow="md" w="full">
      <CardHeader pb={0}>
        <HStack spacing={3}>
          <Icon as={FaComment} color="blue.500" boxSize={5} />
          <Heading size="md">Share Your Feedback</Heading>
        </HStack>
        <Text color={subtitleColor} fontSize="sm" mt={2}>
          Help us shape the future of Localytics by sharing your ideas and feedback
        </Text>
      </CardHeader>
      
      <CardBody pt={4}>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="start">
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} width="full">
              <FormControl isRequired>
                <FormLabel fontSize="sm">Name</FormLabel>
                <Input 
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your name"
                  size="md"
                />
              </FormControl>
              
              <FormControl isRequired>
                <FormLabel fontSize="sm">Email</FormLabel>
                <Input 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email address"
                  type="email"
                  size="md"
                />
              </FormControl>
            </SimpleGrid>
            
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} width="full">
              <FormControl>
                <FormLabel fontSize="sm">Feedback Type</FormLabel>
                <Select 
                  value={feedbackType}
                  onChange={(e) => setFeedbackType(e.target.value)}
                  size="md"
                >
                  <option value="feature_request">Feature Request</option>
                  <option value="improvement">Improvement Suggestion</option>
                  <option value="bug_report">Bug Report</option>
                  <option value="general">General Feedback</option>
                </Select>
              </FormControl>
              
              <FormControl>
                <FormLabel fontSize="sm">Priority</FormLabel>
                <Select 
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                  size="md"
                >
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                  <option value="critical">Critical</option>
                </Select>
              </FormControl>
            </SimpleGrid>
            
            <FormControl>
              <FormLabel fontSize="sm">Subject</FormLabel>
              <Input 
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Brief summary of your feedback"
                size="md"
              />
            </FormControl>
            
            <FormControl isRequired>
              <FormLabel fontSize="sm">Message</FormLabel>
              <Textarea 
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Describe your feedback, suggestion, or issue in detail..."
                size="md"
                rows={5}
              />
            </FormControl>
            
            <Button
              type="submit"
              colorScheme="blue"
              rightIcon={<Icon as={FaPaperPlane} />}
              isLoading={isSubmitting}
              loadingText="Submitting"
              size="md"
              width={{ base: "full", md: "auto" }}
              mt={2}
            >
              Submit Feedback
            </Button>
          </VStack>
        </form>
      </CardBody>
    </Card>
  );
};

// Main roadmap component
const DeveloperRoadmap = () => {
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const subtitleColor = useColorModeValue('gray.600', 'gray.400');
  const sectionBg = useColorModeValue('gray.50', 'gray.700');
  
  return (
    <Container maxW="1200px" py={8}>
      <VStack spacing={4} align="start" mb={12}>
        <HStack spacing={3}>
          <Icon as={FaRoad} color="blue.500" boxSize={6} />
          <Heading as="h1" size="xl" color={textColor}>
            Product Roadmap
          </Heading>
        </HStack>
        <Text color={subtitleColor} fontSize="lg">
          Our development plan and upcoming features for the Localytics Platform
        </Text>
        
        <Box 
          bg={useColorModeValue('yellow.50', 'yellow.900')} 
          p={4} 
          borderRadius="md" 
          borderLeft="4px solid" 
          borderColor="yellow.500"
          mt={2}
          w="full"
        >
          <HStack spacing={3} align="flex-start">
            <Icon as={FaLightbulb} color="yellow.500" boxSize={5} mt={1} />
            <VStack align="start" spacing={2}>
              <Text fontWeight="medium">
                Our roadmap is currently under construction. We're working on putting together a comprehensive 
                development timeline based on customer feedback and business priorities.
              </Text>
              <Text fontSize="sm">
                In the meantime, please share your ideas and feature requests in the feedback section below. 
                Your input is invaluable as we shape the future of the Localytics Platform.
              </Text>
            </VStack>
          </HStack>
        </Box>
      </VStack>
      
      {/* Construction notice instead of phases */}
      <Box 
        p={12} 
        borderRadius="lg"
        borderWidth="1px"
        borderColor={useColorModeValue('gray.200', 'gray.700')}
        borderStyle="dashed"
        bg={useColorModeValue('gray.50', 'gray.700')}
        textAlign="center"
        mb={16}
      >
        <Icon as={FaRoad} color="blue.300" boxSize={16} mb={6} />
        <Heading size="lg" mb={4} color={textColor}>
          Roadmap Under Construction
        </Heading>
        <Text color={subtitleColor} fontSize="lg" maxW="600px" mx="auto">
          We're building our product roadmap to give you visibility into our upcoming features and improvements.
          Check back soon to see what's coming next!
        </Text>
      </Box>
      
      {/* Feedback Section */}
      <Box my={16} p={8} bg={sectionBg} borderRadius="xl">
        <VStack spacing={8} align="stretch">
          <Flex 
            direction={{ base: "column", md: "row" }} 
            justify="space-between" 
            align={{ base: "start", md: "center" }}
            gap={4}
          >
            <VStack align="start" spacing={1}>
              <Heading as="h2" size="lg">
                Share Your Feedback
              </Heading>
              <Text color={subtitleColor}>
                Your ideas help shape our roadmap! Feedback will be sent to <Text as="span" fontWeight="bold">team@localytics.co</Text>
              </Text>
            </VStack>
            <HStack spacing={1}>
              <Icon as={FaStar} color="yellow.400" />
              <Icon as={FaStar} color="yellow.400" />
              <Icon as={FaStar} color="yellow.400" />
              <Icon as={FaStar} color="yellow.400" />
              <Icon as={FaStar} color="yellow.400" />
            </HStack>
          </Flex>
          
          <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={8} w="full">
            <VStack 
              align="start" 
              bg={useColorModeValue('white', 'gray.800')}
              p={6}
              borderRadius="lg"
              borderWidth="1px"
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              boxShadow="sm"
              flex="1"
              h="100%"
            >
              <Flex
                w="40px"
                h="40px"
                bg="green.50"
                color="green.500"
                borderRadius="lg"
                justify="center"
                align="center"
              >
                <Icon as={FaFeather} boxSize={5} />
              </Flex>
              <Heading size="md" mt={4}>Feature Requests</Heading>
              <Text color={subtitleColor}>
                Suggest new features or capabilities you'd like to see added to the platform.
              </Text>
            </VStack>
            
            <VStack 
              align="start" 
              bg={useColorModeValue('white', 'gray.800')}
              p={6}
              borderRadius="lg"
              borderWidth="1px"
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              boxShadow="sm"
              flex="1"
              h="100%"
            >
              <Flex
                w="40px"
                h="40px"
                bg="blue.50"
                color="blue.500"
                borderRadius="lg"
                justify="center"
                align="center"
              >
                <Icon as={FaThumbsUp} boxSize={5} />
              </Flex>
              <Heading size="md" mt={4}>Improvement Ideas</Heading>
              <Text color={subtitleColor}>
                Share your thoughts on how we can enhance existing functionality.
              </Text>
            </VStack>
            
            <VStack 
              align="start" 
              bg={useColorModeValue('white', 'gray.800')}
              p={6}
              borderRadius="lg"
              borderWidth="1px"
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              boxShadow="sm"
              flex="1"
              h="100%"
            >
              <Flex
                w="40px"
                h="40px"
                bg="purple.50"
                color="purple.500"
                borderRadius="lg"
                justify="center"
                align="center"
              >
                <Icon as={FaQuestionCircle} boxSize={5} />
              </Flex>
              <Heading size="md" mt={4}>Questions & Support</Heading>
              <Text color={subtitleColor}>
                Need help or have questions about specific functionality? We're here to help.
              </Text>
            </VStack>
          </SimpleGrid>
          
          <Box mt={8} w="full">
            <FeedbackForm />
          </Box>
        </VStack>
      </Box>
    </Container>
  );
};

export default DeveloperRoadmap;