// Navbar.js
import React, { useState } from 'react';
import { 
  Box, 
  VStack, 
  Icon, 
  Tooltip, 
  Text, 
  Flex,
  Image,
  Divider,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Portal,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { 
  FaRegCalendarAlt, 
  FaTools, 
  FaBalanceScale, 
  FaChartLine, 
  FaChartPie, 
  FaChartArea,
  FaSmile,
  FaGlobe,
  FaLightbulb,
  FaRoad,
  FaCog,
} from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import UserSettingsModal from './UserSettingsModal';

// Dashboard menu items with new names and descriptions
const DASHBOARDS = [
  { 
    icon: FaTools, 
    label: 'Business Insights', 
    path: '/dashboards/business-insights', 
    description: 'Complete overview of business metrics' 
  },
  { 
    icon: FaChartArea, 
    label: 'Review Performance', 
    path: '/dashboards/total-reviews', 
    description: 'Analyze review volume and ratings' 
  },
  { 
    icon: FaChartLine, 
    label: 'Growth Acceleration', 
    path: '/dashboards/gross-growth', 
    description: 'Track review volume growth over time' 
  },
  { 
    icon: FaChartPie, 
    label: 'Comparative Metrics', 
    path: '/dashboards/relative-growth', 
    description: 'Compare relative growth between businesses' 
  },
  { 
    icon: FaSmile, 
    label: 'Sentiment Analysis', 
    path: '/dashboards/sentiment', 
    description: 'Customer opinion and feedback analysis' 
  },
  { 
    icon: FaGlobe, 
    label: 'Digital Traffic', 
    path: '/dashboards/web-traffic', 
    description: 'Web traffic and online visibility' 
  },
];

// Tools menu items
const TOOLS = [
  { 
    icon: FaRegCalendarAlt, 
    label: 'Year in Review', 
    path: '/year-in-review', 
    description: 'Annual performance summary' 
  },
  { 
    icon: FaBalanceScale, 
    label: 'Compare', 
    path: '/compare-year', 
    description: 'Side-by-side business comparison' 
  },
];

function Navbar() {
  const [isExpanded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');
  const activeColor = 'blue.500';
  const inactiveColor = useColorModeValue('gray.600', 'gray.400');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isActivePath = (path) => location.pathname === path;
  const isActiveSection = (paths) => paths.some(path => location.pathname.includes(path));

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  const NavItem = ({ icon, label, path, subItems, onClick, isSettings }) => {
    const isActive = path ? isActivePath(path) : 
                     subItems ? isActiveSection(subItems.map(item => item.path)) : false;

    // Special handling for settings button
    if (isSettings) {
      return (
        <>
          <Tooltip 
            label={label} 
            placement="right" 
            isDisabled={isExpanded}
            hasArrow
          >
            <Button
              onClick={onOpen}
              w="full"
              h="auto"
              p={3}
              variant="ghost"
              borderRadius="md"
              cursor="pointer"
              color={inactiveColor}
              _hover={{ bg: hoverBgColor, color: activeColor }}
              _active={{ bg: hoverBgColor }}
              transition="all 0.2s"
              leftIcon={<Icon as={icon} boxSize={5} />}
              justifyContent={isExpanded ? "flex-start" : "center"}
            >
              {isExpanded && label}
            </Button>
          </Tooltip>
          
          {/* Settings Modal */}
          <UserSettingsModal isOpen={isOpen} onClose={onClose} />
        </>
      );
    }

    if (subItems) {
      return (
        <Menu placement="right-start" isLazy offset={[15, 0]}>
          {({ isOpen }) => (
            <>
              <Tooltip 
                label={label} 
                placement="right" 
                isDisabled={isExpanded || isOpen}
                hasArrow
              >
                <MenuButton
                  as={Button}
                  w="full"
                  h="auto"
                  p={3}
                  variant="ghost"
                  borderRadius="md"
                  cursor="pointer"
                  color={isActive ? activeColor : inactiveColor}
                  bg={isActive ? hoverBgColor : 'transparent'}
                  _hover={{ bg: hoverBgColor }}
                  _active={{ bg: hoverBgColor }}
                  transition="all 0.2s"
                >
                  <Flex align="center" justify={isExpanded ? "flex-start" : "center"} w="full">
                    <Icon as={icon} boxSize={5} />
                    {isExpanded && <Text ml={3}>{label}</Text>}
                  </Flex>
                </MenuButton>
              </Tooltip>
              <Portal>
                <MenuList 
                  ml={2} 
                  minW="220px"
                  bg={bgColor}
                  borderColor={borderColor}
                  boxShadow="lg"
                  zIndex={1100}
                  py={2}
                >
                  {subItems.map((item) => (
                    <MenuItem
                      key={item.path}
                      onClick={() => handleMenuItemClick(item.path)}
                      color={isActivePath(item.path) ? activeColor : inactiveColor}
                      bg={isActivePath(item.path) ? hoverBgColor : 'transparent'}
                      _hover={{ bg: hoverBgColor }}
                      py={3}
                      role="group"
                    >
                      <Flex width="100%" align="flex-start">
                        <Icon as={item.icon} boxSize={4} mt="3px" mr={3} />
                        <Flex direction="column">
                          <Text fontWeight={isActivePath(item.path) ? "semibold" : "normal"}>
                            {item.label}
                          </Text>
                          <Text 
                            fontSize="xs" 
                            color="gray.500" 
                            mt={1}
                            _groupHover={{ color: isActivePath(item.path) ? activeColor : "gray.600" }}
                          >
                            {item.description}
                          </Text>
                        </Flex>
                      </Flex>
                    </MenuItem>
                  ))}
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
      );
    }

    return (
      <Tooltip 
        label={label} 
        placement="right" 
        isDisabled={isExpanded}
        hasArrow
      >
        <Button
          onClick={() => handleMenuItemClick(path)}
          w="full"
          h="auto"
          p={3}
          variant="ghost"
          borderRadius="md"
          cursor="pointer"
          color={isActive ? activeColor : inactiveColor}
          bg={isActive ? hoverBgColor : 'transparent'}
          _hover={{ bg: hoverBgColor }}
          _active={{ bg: hoverBgColor }}
          transition="all 0.2s"
          leftIcon={<Icon as={icon} boxSize={5} />}
          justifyContent={isExpanded ? "flex-start" : "center"}
        >
          {isExpanded && label}
        </Button>
      </Tooltip>
    );
  };

  return (
    <Box
      position="fixed"
      left={0}
      top={0}
      bottom={0}
      w="72px"
      bg={bgColor}
      borderRight="1px"
      borderColor={borderColor}
      transition="all 0.2s"
      zIndex={1000}
      py={4}
      overflowX="hidden"
      overflowY="auto"
      boxShadow="sm"
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: borderColor,
          borderRadius: '24px',
        },
      }}
    >
      <VStack spacing={4} align="stretch" h="full">
        {/* Logo and Title */}
        <Box 
          as="button"
          onClick={() => navigate('/home')}
          px={4}
          py={2}
          _hover={{ 
            bg: useColorModeValue('gray.100', 'gray.700'),
            transform: 'scale(1.05)'
          }}
          borderRadius="md"
          transition="all 0.2s"
        >
          <Image 
            src="/output.png" 
            alt="Logo" 
            boxSize="40px"
            mx="auto"
          />
        </Box>

        {/* Navigation Items */}
        <VStack spacing={1} align="stretch" px={2}>
          <NavItem
            icon={FaLightbulb}
            label="Dashboards"
            subItems={DASHBOARDS}
          />
          
          <NavItem
            icon={FaTools}
            label="Tools"
            subItems={TOOLS}
          />

          <Divider my={2} borderColor={borderColor} />

          <NavItem
            icon={FaRoad}
            label="Roadmap"
            path="/roadmap"
          />
        </VStack>
        
        {/* Spacer to push settings to bottom */}
        <Spacer />
        
        {/* Settings Button at bottom */}
        <Box px={2} mb={4}>
          <NavItem
            icon={FaCog}
            label="Settings"
            onClick={() => {
              // This is handled by the custom NavItem component
            }}
            isSettings={true}
          />
        </Box>
      </VStack>
    </Box>
  );
}

export default Navbar;