import React from 'react';
import { 
  Box, 
  VStack, 
  HStack, 
  Text, 
  Badge, 
  Progress, 
  Card, 
  CardHeader, 
  CardBody, 
  Icon, 
  Heading, 
  Flex,
  useColorModeValue
} from '@chakra-ui/react';
import { FaTrophy, FaArrowUp, FaArrowDown, FaChartBar } from 'react-icons/fa';

const RankingListComponent = ({ side, title, data, valueKey, unit }) => {
  const maxValue = Math.max(...data.map(item => item[valueKey]));
  
  // Theme colors
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const subtitleColor = useColorModeValue('gray.600', 'gray.400');
  
  // Colors for ranking positions
  const rankColors = [
    'yellow.400', // 1st place
    'gray.400',   // 2nd place
    'orange.300', // 3rd place
    'blue.400',   // others
  ];
  
  const isTopRanking = side === 'top';
  const mainColor = isTopRanking ? 'blue' : 'red';
  const icon = isTopRanking ? FaArrowUp : FaArrowDown;
  
  return (
    <Card 
      bg={cardBg} 
      borderWidth="1px" 
      borderColor={borderColor} 
      borderRadius="lg" 
      boxShadow="sm"
      overflow="hidden"
      h="100%"
    >
      <CardHeader pb={2}>
        <HStack spacing={2}>
          <Icon 
            as={isTopRanking ? FaTrophy : FaChartBar} 
            color={isTopRanking ? 'yellow.400' : 'red.400'} 
            boxSize={5} 
          />
          <Heading size="md">{title}</Heading>
        </HStack>
      </CardHeader>
      
      <CardBody pt={2}>
        <VStack spacing={4} align="stretch">
          {data.map((item, index) => {
            const value = item[valueKey];
            const percentage = (value / maxValue) * 100;
            const rankNumber = isTopRanking ? index + 1 : data.length - index;
            const rankColor = rankNumber <= 3 ? rankColors[rankNumber - 1] : rankColors[3];
            
            return (
              <Box 
                key={item.name} 
                position="relative" 
                borderWidth={index < data.length - 1 ? "0 0 1px 0" : "0"}
                borderColor={borderColor}
                pb={index < data.length - 1 ? 4 : 0}
                transition="transform 0.2s"
                _hover={{ transform: 'translateX(4px)' }}
              >
                <HStack spacing={3} mb={3} align="center">
                  <Flex
                    w="28px"
                    h="28px"
                    bg={rankNumber <= 3 ? `${rankColor}` : 'blue.50'}
                    color={rankNumber <= 3 ? 'white' : 'blue.500'}
                    borderRadius="full"
                    justify="center"
                    align="center"
                    fontWeight="bold"
                    fontSize="sm"
                  >
                    {rankNumber}
                  </Flex>
                  
                  <Text 
                    fontSize="md" 
                    fontWeight="medium" 
                    noOfLines={1} 
                    flex={1}
                  >
                    {item.name}
                  </Text>
                  
                  <Badge 
                    colorScheme={mainColor} 
                    px={2} 
                    py={1} 
                    borderRadius="full"
                    display="flex"
                    alignItems="center"
                  >
                    <Icon as={icon} boxSize={3} mr={1} />
                    <Text>
                      {typeof value === 'number' ? value.toLocaleString() : value}{unit}
                    </Text>
                  </Badge>
                </HStack>
                
                <Box pl="36px" pr="2px">
                  <Progress 
                    value={percentage} 
                    size="sm" 
                    colorScheme={mainColor}
                    borderRadius="full"
                    bg={`${mainColor}.50`}
                    hasStripe={rankNumber <= 3}
                    isAnimated={rankNumber <= 3}
                  />
                  {item.description && (
                    <Text fontSize="xs" color={subtitleColor} mt={1}>
                      {item.description}
                    </Text>
                  )}
                </Box>
              </Box>
            );
          })}
        </VStack>
      </CardBody>
    </Card>
  );
};

export default RankingListComponent;