const isDevelopment = process.env.NODE_ENV === 'development';

// List of warning messages to filter out
const FILTERED_WARNINGS = [
  'The width(0) and height(0) of chart should be greater than 0',
  // Add other warning patterns here if needed
];

// Original console methods
const originalConsole = {
  log: console.log,
  error: console.error,
  warn: console.warn
};

// Override console.warn to filter out specific warnings
const filterWarnings = (warning) => {
  if (typeof warning === 'string') {
    return !FILTERED_WARNINGS.some(filter => warning.includes(filter));
  }
  return true;
};

// Override console methods in production
if (!isDevelopment) {
  console.warn = (...args) => {
    if (filterWarnings(args[0])) {
      originalConsole.warn(...args);
    }
  };
}

export const logger = {
  log: (...args) => {
    if (isDevelopment) {
      originalConsole.log(...args);
    }
  },
  error: (...args) => {
    if (isDevelopment) {
      originalConsole.error(...args);
    }
  },
  warn: (...args) => {
    if (isDevelopment || filterWarnings(args[0])) {
      originalConsole.warn(...args);
    }
  }
}; 