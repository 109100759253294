import React, { useState, useEffect } from 'react';
import { Box, keyframes, useColorModeValue } from '@chakra-ui/react';

// Keyframe definitions for different animation types
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideUp = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const slideLeft = keyframes`
  from { opacity: 0; transform: translateX(20px); }
  to { opacity: 1; transform: translateX(0); }
`;

const scale = keyframes`
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
`;

/**
 * AnimatedBox - A wrapper component that adds animations to any content
 * 
 * @param {string} animation - Type of animation: 'fadeIn', 'slideUp', 'slideLeft', 'scale', 'pulse'
 * @param {number} delay - Delay before animation starts (in seconds)
 * @param {number} duration - Duration of animation (in seconds)
 * @param {boolean} repeat - Whether the animation should repeat
 * @param {boolean} animateOnHover - Whether to animate on hover instead of on mount
 * @param {boolean} disableAnimation - Disable animation (useful for conditionally turning off animations)
 * @param {Object} props - Any other props to pass to the Box component
 */
const AnimatedBox = ({ 
  children, 
  animation = 'fadeIn', 
  delay = 0,
  duration = 0.5, 
  repeat = false,
  animateOnHover = false,
  disableAnimation = false,
  ...props 
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  
  // Choose the animation keyframes based on the animation prop
  let animationKeyframes;
  switch (animation) {
    case 'slideUp':
      animationKeyframes = slideUp;
      break;
    case 'slideLeft':
      animationKeyframes = slideLeft;
      break;
    case 'scale':
      animationKeyframes = scale;
      break;
    case 'pulse':
      animationKeyframes = pulse;
      break;
    case 'fadeIn':
    default:
      animationKeyframes = fadeIn;
  }
  
  // Define the animation
  const animationStyle = `${animationKeyframes} ${duration}s ${repeat ? 'infinite' : ''} ease-in-out`;
  
  // Effect to trigger animation after delay
  useEffect(() => {
    if (disableAnimation || animateOnHover) return;
    
    const timer = setTimeout(() => {
      setIsVisible(true);
      setHasAnimated(true);
    }, delay * 1000);
    
    return () => clearTimeout(timer);
  }, [delay, disableAnimation, animateOnHover]);
  
  // For animations that should only happen on hover
  const hoverStyles = animateOnHover ? {
    transition: `all ${duration}s ease-in-out`,
    _hover: {
      animation: animationStyle,
    }
  } : {};
  
  // Only apply animation if not disabled
  const animationProps = disableAnimation ? {} : {
    animation: isVisible ? animationStyle : 'none',
    opacity: (!animateOnHover && !hasAnimated) ? 0 : 1,
    ...hoverStyles
  };

  return (
    <Box 
      {...props}
      {...animationProps}
    >
      {children}
    </Box>
  );
};

export default AnimatedBox;