import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box, 
  Flex, 
  Spinner, 
  useColorModeValue, 
  Text, 
  Button, 
  HStack, 
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  IconButton,
  Divider
} from '@chakra-ui/react';
import AnimatedBox from '../AnimatedBox';
import TotalReviewsDashboard from './TotalReviewsDashboard';
import GrossGrowthDashboard from './GrossGrowthDashboard';
import RelativeGrowthDashboard from './RelativeGrowthDashboard';
import SentimentDashboard from './SentimentDashboard';
import WebTrafficDashboard from './WebTrafficDashboard';
import BusinessInsightsDashboard from './BusinessInsightsDashboard';
import { getReviews } from '../../services/reviewService';
import { 
  FaHome, 
  FaCalendarAlt, 
  FaChevronDown, 
  FaTools, 
  FaChartArea, 
  FaChartLine, 
  FaChartPie, 
  FaSmile, 
  FaGlobe 
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { logger } from '../../utils/logger';

// Map dashboard types to names, icons, descriptions, and section labels
const DASHBOARD_INFO = {
  'business-insights': { 
    name: 'Business Insights Dashboard', 
    icon: FaTools,
    description: 'Comprehensive overview of key business metrics with actionable insights for strategic decision-making.',
    sectionLabel: 'Business Intelligence and Metrics'
  },
  'total-reviews': { 
    name: 'Review Performance Tracker', 
    icon: FaChartArea,
    description: 'Monitor review volume and distribution across your competitors to benchmark your market position.',
    sectionLabel: 'Detailed Performance Analytics'
  },
  'gross-growth': { 
    name: 'Growth Acceleration Monitor', 
    icon: FaChartLine,
    description: 'Track absolute growth metrics like total review increases and engagement trends over time.',
    sectionLabel: 'Growth Trend Analysis'
  },
  'relative-growth': { 
    name: 'Comparative Growth Metrics', 
    icon: FaChartPie,
    description: 'Compare your growth rates against competitors with relative performance indicators and market share analysis.',
    sectionLabel: 'Competitive Benchmarking Data'
  },
  'sentiment': { 
    name: 'Customer Sentiment Analysis', 
    icon: FaSmile,
    description: 'Analyze positive and negative sentiment patterns in customer reviews to identify improvement opportunities.',
    sectionLabel: 'Sentiment Breakdown and Patterns'
  },
  'web-traffic': { 
    name: 'Digital Traffic Analytics', 
    icon: FaGlobe,
    description: 'Measure online visibility through web traffic trends and digital engagement metrics across platforms.',
    sectionLabel: 'Traffic Source and Engagement Metrics'
  }
};

// Section Label component to be used across dashboards
export const SectionLabel = ({ children, description, ...props }) => {
  const dividerColor = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const descriptionColor = useColorModeValue("gray.500", "gray.400");
  
  return (
    <AnimatedBox 
      animation="fadeIn"
      delay={0.2}
      duration={0.7}
      py={4} 
      my={4} 
      width="100%" 
      {...props}
    >
      <HStack spacing={4} mb={description ? 2 : 0}>
        <Divider flex={1} borderColor={dividerColor} />
        <AnimatedBox
          animation="pulse"
          delay={0.4}
          duration={0.7}
        >
          <Text 
            fontSize="lg" 
            fontWeight="500" 
            color={textColor}
            whiteSpace="nowrap"
            px={2}
          >
            {children}
          </Text>
        </AnimatedBox>
        <Divider flex={1} borderColor={dividerColor} />
      </HStack>
      
      {description && (
        <AnimatedBox
          animation="slideUp"
          delay={0.5}
          duration={0.5}
        >
          <Text 
            fontSize="md" 
            color={descriptionColor} 
            textAlign="center"
            px={4}
          >
            {description}
          </Text>
        </AnimatedBox>
      )}
    </AnimatedBox>
  );
};

const DashboardWrapper = ({ type }) => {
  const [reviewsData, setReviewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const [selectedTimeframe, setSelectedTimeframe] = useState('lastyear');
  
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const menuBg = useColorModeValue('white', 'gray.700');
  const menuBorder = useColorModeValue('gray.200', 'gray.600');
  const selectBg = useColorModeValue('white', 'gray.700');
  const selectBorder = useColorModeValue('gray.200', 'gray.600');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        // Fall back to the original endpoint to ensure we get data
        const data = await getReviews();
        
        if (!data) {
          throw new Error('No data received from the server');
        }
        
        // Apply date filtering on the client side
        const filteredData = data.map(business => {
          // Create a deep copy to avoid mutating the original
          const filteredBusiness = JSON.parse(JSON.stringify(business));
          
          // Ensure the rating_count property is present
          if (typeof filteredBusiness.rating_count === 'undefined') {
            filteredBusiness.rating_count = business.rating_count || 0;
          }
          
          // Only filter if we have historical data and a date range
          if (dateRange.startDate && dateRange.endDate && 
              business.data && business.data.monthlyReviewCounts) {
            
            // Filter monthly counts
            const filteredMonthlyReviewCounts = {};
            const filteredMonthlyRatings = {};
            
            Object.entries(business.data.monthlyReviewCounts).forEach(([month, count]) => {
              const monthDate = moment(month);
              if (monthDate.isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]')) {
                filteredMonthlyReviewCounts[month] = count;
                if (business.data.monthlyRatings && business.data.monthlyRatings[month]) {
                  filteredMonthlyRatings[month] = business.data.monthlyRatings[month];
                }
              }
            });
            
            // Update the filtered business data
            filteredBusiness.data.monthlyReviewCounts = filteredMonthlyReviewCounts;
            filteredBusiness.data.monthlyRatings = filteredMonthlyRatings;
            
            // Recalculate totals based on filtered data
            const totalPositive = Object.values(filteredMonthlyRatings)
              .reduce((sum, rating) => sum + (rating.positive || 0), 0);
            const totalNeutral = Object.values(filteredMonthlyRatings)
              .reduce((sum, rating) => sum + (rating.neutral || 0), 0);
            const totalNegative = Object.values(filteredMonthlyRatings)
              .reduce((sum, rating) => sum + (rating.negative || 0), 0);
            
            filteredBusiness.data.totalReviews = totalPositive + totalNeutral + totalNegative;
            
            // Update sentiment breakdown
            filteredBusiness.data.sentimentBreakdown = {
              positive: totalPositive,
              neutral: totalNeutral,
              negative: totalNegative
            };
          }
          
          return filteredBusiness;
        }).filter(business => business !== null);
        
        // Add check to ensure all businesses have a rating_count property
        const validData = filteredData.map(business => {
          if (!business) return null;
          
          // If rating_count is undefined, set a default value of 0
          if (typeof business.rating_count === 'undefined') {
            business.rating_count = 0;
          }
          
          return business;
        }).filter(business => business !== null);
        
        setReviewsData(validData);
        logger.log(`Filtered data for date range: ${dateRange.startDate} to ${dateRange.endDate}`);
      } catch (error) {
        logger.error("Error fetching dashboard data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dateRange.startDate, dateRange.endDate]);

  const handleTimeframeChange = (event) => {
    const value = event.target.value;
    setSelectedTimeframe(value);
    
    const endDate = moment().format('YYYY-MM-DD');
    let startDate;
    
    switch (value) {
      case 'last30days':
        startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        break;
      case 'last90days':
        startDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
        break;
      case 'last6months':
        startDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
        break;
      case 'lastyear':
        startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
        break;
      case 'yeartodate':
        startDate = moment().startOf('year').format('YYYY-MM-DD');
        break;
      default:
        startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    }
    
    setDateRange({ startDate, endDate });
  };

  const filteredReviewsData = useMemo(() => {
    // Simply return the data as-is since we've already filtered it in the useEffect
    return reviewsData;
  }, [reviewsData]);

  // Navigation bar with dashboard options
  const DashboardNav = () => {
    const types = Object.keys(DASHBOARD_INFO);
    
    return (
      <AnimatedBox animation="fadeIn" duration={0.5}>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<Icon as={FaChevronDown} />}
            leftIcon={<Icon as={DASHBOARD_INFO[type]?.icon || FaTools} />}
            bg={menuBg}
            border="1px"
            borderColor={menuBorder}
            _hover={{ 
              bg: useColorModeValue('gray.50', 'gray.700'),
              transform: "translateY(-2px)",
              shadow: "md"
            }}
            _active={{ bg: useColorModeValue('gray.100', 'gray.600') }}
            size="md"
            transition="all 0.2s"
          >
            <AnimatedBox animation="pulse" delay={0.2} duration={0.5}>
              {DASHBOARD_INFO[type]?.name || 'Dashboard'}
            </AnimatedBox>
          </MenuButton>
          <MenuList bg={menuBg} borderColor={menuBorder}>
            {types.map((dashType, index) => (
              <AnimatedBox
                key={dashType}
                as={MenuItem}
                animation="slideLeft"
                delay={0.03 * index}
                duration={0.3}
                icon={<Icon as={DASHBOARD_INFO[dashType].icon} />}
                onClick={() => navigate(`/dashboards/${dashType}`)}
                bg={dashType === type ? 'gray.50' : 'transparent'}
                fontWeight={dashType === type ? 'bold' : 'normal'}
                transition="all 0.2s"
                _hover={{ bg: dashType === type ? 'gray.100' : 'gray.50' }}
              >
                {DASHBOARD_INFO[dashType].name}
              </AnimatedBox>
            ))}
          </MenuList>
        </Menu>
      </AnimatedBox>
    );
  };

  const renderDashboard = () => {
    const dashboardProps = {
      reviewsData: filteredReviewsData,
      dateRange,
      sectionLabel: DASHBOARD_INFO[type]?.sectionLabel
    };

    if (error) {
      return <Text color="red.500">Error loading data: {error}</Text>;
    }

    switch (type) {
      case 'total-reviews':
        return <TotalReviewsDashboard {...dashboardProps} />;
      case 'gross-growth':
        return <GrossGrowthDashboard {...dashboardProps} />;
      case 'relative-growth':
        return <RelativeGrowthDashboard {...dashboardProps} />;
      case 'sentiment':
        return <SentimentDashboard {...dashboardProps} />;
      case 'web-traffic':
        return <WebTrafficDashboard {...dashboardProps} />;
      case 'business-insights':
        return <BusinessInsightsDashboard {...dashboardProps} />;
      default:
        return <Text>Invalid dashboard type</Text>;
    }
  };

  return (
    <Box
      position="relative"
      bg={cardBg}
      borderRadius="lg"
      boxShadow="sm"
      p={6}
      mx="auto"
      maxW="1400px"
      minH="calc(100vh - 2rem)"
      borderColor={borderColor}
      borderWidth="1px"
    >
      {/* Navigation Bar and Title - Always show but customize for different dashboards */}
      <Box mb={6}>
        <Flex 
          justifyContent="space-between" 
          alignItems="center" 
          mb={3}
          flexDirection={{ base: "column", sm: "row" }}
          gap={4}
        >
          <HStack spacing={4}>
            <IconButton
              icon={<Icon as={FaHome} />}
              onClick={() => navigate('/')}
              size="md"
              aria-label="Home"
              color={useColorModeValue("gray.600", "gray.400")}
              bg="transparent"
              _hover={{ bg: useColorModeValue("gray.100", "gray.700") }}
            />
            <DashboardNav />
          </HStack>
          
          {/* Date selector for all dashboards except Business Insights and Review Performance Tracker */}
          {type !== 'business-insights' && type !== 'total-reviews' && (
            <HStack spacing={2}>
              <Icon as={FaCalendarAlt} color="gray.500" />
              <Select
                value={selectedTimeframe}
                onChange={handleTimeframeChange}
                width={{ base: "full", sm: "200px" }}
                size="md"
                bg={selectBg}
                borderColor={selectBorder}
                _hover={{ borderColor: 'blue.400' }}
                _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)' }}
              >
                <option value="last30days">Last 30 Days</option>
                <option value="last90days">Last 90 Days</option>
                <option value="last6months">Last 6 Months</option>
                <option value="lastyear">Last Year</option>
                <option value="yeartodate">Year to Date</option>
              </Select>
            </HStack>
          )}
        </Flex>
        
        {/* We're removing the top description since it's now in the section labels */}
      </Box>

      {isLoading ? (
        <Flex 
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          justify="center" 
          align="center"
          bg={`${cardBg}dd`}
          borderRadius="lg"
        >
          <AnimatedBox
            animation="fadeIn"
            duration={0.8}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Spinner 
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
            <AnimatedBox 
              animation="pulse" 
              delay={0.3}
              duration={1.5}
              repeat={true}
            >
              <Text mt={4} fontSize="lg" color="gray.500">Loading dashboard data...</Text>
            </AnimatedBox>
          </AnimatedBox>
        </Flex>
      ) : (
        <AnimatedBox
          animation="fadeIn"
          duration={0.6}
          width="100%"
        >
          {renderDashboard()}
        </AnimatedBox>
      )}
    </Box>
  );
};

export default DashboardWrapper;